// define('ui-controls-schemaEdit', [
// 	'modals', 'references',
// 	'ui-controls-editValue','behaviors-dynamicClass'
// ], function(
// 	modals, refs,
// 	EditValue, BehaviorDynamicClass
// ) { });

import modals from '_libs/modals';
import refs from 'references';
import EditValue from 'ui/controls/editValue';
import BehaviorDynamicClass from 'behaviors/dynamicClass';

import simplify from '_helpers/simplify';

import './properties-control-item.less';

import { getValueByPath } from 'utils';

import { BbeModel, BbeCollection } from 'core/bbe';

import { unFlatObject } from 'utils';

import { MnView, NextCollectionView } from 'vendors';
import { moment } from 'vendors';
import { isNumber } from 'underscore';

function isValue(value) {

	if (value == null || value === '') {
		return false;
	}

	if (typeof value == 'number') {
		return !isNaN(value);
	}

	if (Array.isArray(value)) {
		return value.length > 0;
	}

	// if (typeof value.valueOf == 'function') {
	// 	return isValue(value.valueOf());
	// }

	return true;
	// let notEmpty = value != null && value !== '';		
	// let isArray = Array.isArray(value);
	// let notEmptyArray = isArray ? value.length > 0 : true;
	// return notEmpty && notEmptyArray;
}

function displayEnum(enumHash, value) {
	if (typeof enumHash === 'function') {
		enumHash = enumHash();
	}
	if (Array.isArray(value)) {
		return value.map(v => refs.enum(enumHash, v)).join(', ');
	}
	return refs.enum(enumHash, value);
}

function ifEmpty(value, ifEmpty) {
	return isValue(value) ? value : ifEmpty;
}

const PropertyModel = BbeModel.extend({
	valueAttr: '_value',
	constructor: function() {
		BbeModel.apply(this, arguments);
		this.on('change', () => this.trigger('view:refresh', this));
		// this.on('view:refresh', () => console.error(this.id, 'REFRESH'));
		this._initClearOnChange();
		this._initRefreshOnChange();
	},
	_initCollectionListener(props, callback, mapper) {
		
		if (!props) { return; }
		if (!Array.isArray(props)) {
			props = [props];
		}
		if (!mapper) {
			mapper = prop => 'change:' + prop;
		}
		const event = props.map(mapper).join(' ');
		this.listenTo(this.collection, event, callback);
	},
	_initClearOnChange() {
		let props = this.get('clearOnChange');
		this._initCollectionListener(props, () => {
			console.warn(this.id, 'clearOnChange', props);
			this.clearValues();
		});
	},
	_initRefreshOnChange() {
		let props = this.get('refreshOnChange');
		this._initCollectionListener(props, () => {
			console.warn(this.id, 'refreshOnChange', props);
			this.trigger('view:refresh', this);
		});
	},
	isMultiple() {
		return this.get('multiple') == true;
	},
	clearValues() { 
		this._set(null);
	},
	hasValue() { 
		let isEmpty = this.get('isEmpty');
		let value = this.get(this.valueAttr);
		if (isEmpty) {
			const empt = isEmpty(value);
			// console.error('# empty', empt);
			return !empt;
		}

		if (this.get('valueType') === 'enum' && value === 'none') {
			return false;
		}
		let res = isValue(value);
		return res;
	},
	_castStringValue(value) {
		let type = this.get('valueType');
		if (type == 'number') {
			let num = parseFloat(value, 10);
			return isNaN(num) ? void 0 : num;
		}
		return value;
	},
	_castStringValues(values) {
		if (Array.isArray(values)) {
			return values.map(value => this._castStringValue(value));
		} else {
			return this._castStringValue(values);
		}
	},
	_getFlatten() {
		let flatten = this.get('flattenValues');
		if (this.get('valueType') == 'enum' && flatten == null) {
			flatten = true;
		}
		return flatten;
	},
	_normalizeValueOut(value) {
		value = this._castStringValues(value);
		let flatten = this._getFlatten();
		if (this.isMultiple() && Array.isArray(value) && flatten != null) {
			value = value.join(flatten === true ? ', ' : flatten);
		}			
		return value;
	},
	getDisplayValue() {

		let empty = this.get('emptyText');
		if (!this.hasValue()) {

			return empty;
		}
		let display = this.get('display');
		if (display) {
			let val = display.call(this, this.getValue({ raw: true }), this);
			return ifEmpty(val, empty);
		}
		if (this.get('valueType') == 'enum') {
			return ifEmpty(displayEnum(this.get('sourceValues'), this.getValue({ raw: true })), empty);
		}
		let value = this.getValue();
		if (_.isDate(value)) {
			return moment(value).format("DD.MM.YYYY");
		}

		return ifEmpty(value, empty);
	},
	getValue({ toJson, raw } = {}) { 
		let value = this.get(this.valueAttr); 
		if (!raw) {
			value = this._normalizeValueOut(value);
			if (toJson && value && typeof value.toJSON == 'function') {
				return value.toJSON();
			}
		}
		return value;
	},
	_normalizeValueIn(value) {
		if (value === void 0 || value === '') {
			return void 0;
		} else if (value === null) {
			return value;
		}
		let multi = this.isMultiple();
		let isArray = Array.isArray(value);
		if (!multi && !isArray) return value;
		if (!multi && isArray) return value[0];
		if (multi && !isArray) return [value];
		return value;
	},
	setValue(value) {
		value = this._normalizeValueIn(value);
		this._set(value);
	},
	_set(value) {
		this.set(this.valueAttr, value);
		this.collection.trigger('change:' + this.id, this);
	},

	applyValues(values) { 
		this.setValue(values);
	},
	resetValuesTo(values) {
		this.setValue(values);
	},
	toJSON() {
		return {
			key: this.get('property'),
			value: this.getValue({ toJson: true })
		}
	},
	getAllValues () {
		return Object.assign({}, this.collection.valuesHash, this.collection.toJSON());
	},
	getAvailableForEditError() {
		const available = this.get('editAvailableError');
		if (available) {
			const all = this.getAllValues();
			console.warn('#all', all);
			return available(all);
		}
		return;
	}
});

const PropertiesCollection = BbeCollection.extend({
	model: PropertyModel,
	toJSON() {
		let flat = this.reduce((memo, model) => {
			let { key, value } = model.toJSON();
			memo[key] = value;
			return memo;
		}, {});
		return unFlatObject(flat);
		//return flat;
	}
});

const PropertyView = MnView.extend({
	className: 'properties-control-item',
	tagName: 'li',
	template: _.template(`
		<small><%= caption %></small>
		<span><%= label %></span>
		<% if (description) {%><b><%= description %></b><% } %>
		<button class="close">×</button>			
	`),
	behaviors: [BehaviorDynamicClass],
	dynamicClass: function() {
		let res = [];
		
		if (this.model.hasValue()) {
			res.push('valued');
			//console.log('valued', this.model);
		}
		if (this.model.get('validate') || this.model.get('required')) {
			res.push('need-validation');
		}
		if (this.model.get('required')) {
			res.push('required');
		}
		return res.join(' ');
	},
	modelEvents: {
		'view:refresh':'render',
	},
	triggers: {
		'click span': 'clicked',
		'click button.close': 'reset',
	},
	onReset: function() {
		this.model.clearValues();
	},
	onClicked: function (view, event) {
		var m = this.model;
		const cantEdit = m.getAvailableForEditError();
		if (cantEdit) {
			modals.error(cantEdit);
			return;
		}

		console.log('#here', this, m);

		var dependency = m.get('dependency');
		var viewFilter = m.get('viewFilter');
		if (dependency && dependency.filterSourceValues) {
			var fcfg = dependency.filterSourceValues;
			var dependOn = m.collection.get(_.result(fcfg, 'filters'));
			var dependKeys = dependOn && dependOn.getValue({ asArray: true }) || [];
			if (!(dependKeys instanceof Array)) dependKeys = [dependKeys];
			var allowed = _(dependKeys).chain().map(function (dk) {
								return fcfg.allowed[dk] || []
							}).flatten().value();

			var func = function(view) {
				return allowed.indexOf(view.model.get('value')) >= 0;
			};
			viewFilter = func;
		}

		const valuesHash = this.getOption('valuesHash');

		const getAllValues = () => m.getAllValues(); //m.collection.toJSON();
		let controlOptions = m.get('controlOptions');
		if (typeof controlOptions === 'function') {
			const original = controlOptions;
			controlOptions = function() {
				return original(getAllValues(), valuesHash);
			};
		}
		var opts = {
			header: m.get('caption'),
			multiple: m.get('multiple') === true,
			modelType: m.get('modelType'),
			valueType: m.get('valueType'),
			initialValue: m.getValue(),
			sourceValues: m.get('sourceValues'),
			excludeValues: m.get('excludeValues'),
			controlType: m.get('controlType'),
			controlOptions,
			viewFilter: viewFilter,
			suggestions: m.get('suggestions'),
			noComparator: m.get('noComparator'),
			valuesHash: this.getOption('valuesHash'),

			getAllValues,
			onChange: function (values) {
				m.applyValues(values);
			},
			
		}
		_.extend(opts, m.get('extOpts'));

		EditValue
			.modal(opts, { modalCssCfg: 'shortAutoHeight'})
			.done(function (type, value, initialValue) {
				console.log('== //apply values if you dont need instant changes ==', type, value, initialValue);
				//apply values if you dont need instant changes
			}).fail(function (type, newValue, initialValue) {
				if (type === 'cancel') {
					m.resetValuesTo(initialValue);
				}
				else if (type === 'reset') {
					m.clearValues();
				}
			});
	},
	templateContext: function () {

		var caption = this.model.get('caption');
		var label = this.model.getDisplayValue();
		var description = this.model.get('description');

		return {
			caption: caption,
			label: label,
			description
		}
	}
});

const PropertiesView = NextCollectionView.extend({
	className: 'ui-list',
	tagName: 'ul',
	childView: PropertyView,
	childViewOptions() {
		return {
			valuesHash: this.getOption('valuesHash')
		}
	},
	initialize() {
		if (!this.collection) {
			let props = this.getOption('properties');
			this.collection = new PropertiesCollection(props);
			this.collection.valuesHash = this.getOption('valuesHash');
		}
		// this.listenTo()
		// this.collection.on('all', (e) => console.warn('#col#', e));
	},
	collectionEvents: {
		change() {
			this.trigger('change', this.collection.toJSON());
		}
	}
});

function validateProperty(prop, json) {
	let errors = [];
	let value = getValueByPath(json, prop.property);
	if (prop.required && !isValue(value)) {
		errors.push('поле необходимо заполнить');
	}
	if (prop.validate) {
		let res = prop.validate(value, json, prop);
		if (res !== true && res != null && isValue(res)) {
			if (Array.isArray(res)) {
				errors.push(...res)
			} else {
				errors.push(res);
			}
		}
		// if (isValue(res)) {
		// }
	}
	if (errors.length) {
		return errors;
	}
}

function validateProperties(props, json) {
	let errors = props.reduce((all, prop) => {
		let propErrors =  validateProperty(prop, json);
		
		if (isValue(propErrors)) {
			all.push({ property: prop.property, errors: propErrors })
		}
		return all;
	}, []);
	if (errors.length) {
		return errors;
	}
}

const EditView = MnView.extend({
	constructor: function(options) {
		MnView.apply(this, arguments);
		this.mergeOptions(options, ['validateModel']);
		this.editStatus = 'pending';
		this.beforeApply = options.beforeApply;
		if (!this.beforeApply) {
			this.beforeApply = () => Promise.resolve();
		}
		this.on('destroy', () => {
			if (this.editStatus === 'pending') {
				this.editStatus = 'rejected';
			}
			this.trigger('edit:stop', this.editStatus);
		});
		this.once('done', () => {
			this.editStatus = 'resolved';
			if (this.getOption('destroyOnDone')) {
				this.destroy();
			}
		});
	},
	baseClassName: 'editschema',
	// className: 'editschema-in-modal',
	template: _.template(`<% if (withHeader) {%><header><%= header %></header><% } %>
	<div class="editschema-content-container"></div>
	<% if (withApplyButton) { %>
	<footer>
	<% if (withApplyButton) { %><button class="apply"><%= applyText %></button><% } %>
	</footer>
	<% } %>
	`),
	ui: {
		apply: 'button.apply'
	},
	regions: {
		content: '> div.editschema-content-container',
	},
	getProperties() {
		if (!this._properties) {
			this._properties = this.getOption('properties');
		}
		return this._properties;
	},
	onRender() {
		let view = new PropertiesView({ properties: this.getProperties(), valuesHash: this.getOption('valuesHash') });
		this.showChildView('content', view);
		let values = view.collection.toJSON();
		this.validate(values);
	},
	templateContext() {
		return {
			withApplyButton: this.getOption('noApplyButton') !== true,
			withHeader: this.getOption('noHeader') !== true,
			header: this.getOption('header'),
			applyText: this.getOption('applyText') || 'сохранить'
		}
	},
	onValid() {
		this.ui.apply.prop('disabled', false);
		console.log('##> VAlid');
	},
	onInvalid(errors) {
		this.ui.apply.prop('disabled', true);
		console.log('##==> INVA LID', errors);
	},
	_validate(json = {}) {
		let props = this.getProperties();
		const propertiesErrors = validateProperties(props, json);
		if (propertiesErrors) { return propertiesErrors; }
		if (typeof this.validateModel === 'function') {
			return this.validateModel(json);
		}
	},
	validate(json) {
		let errors = this._validate(json);
		if (!errors) {
			this.value = json;
			this.triggerMethod('valid');
		} else {
			this.triggerMethod('invalid', errors);
		}
	},
	childViewEvents: {
		'change':'validate'
	},
	events: {
		'click .apply:not(:disabled)'(e) {
			let promise = this.beforeApply(this.value);
			console.log('apply clicked', promise)
				//this.triggerMethod('before:resolve', this.value);
			if (promise && promise.then) {
				promise.then(
					() => {
						this.triggerMethod('done', this.value);
					}, 
					(exc) => {
						console.error(exc);
					}
				);
			} else {
				this.triggerMethod('done', this.value);
			}
		}
	},
	// resolveAsync() {
	// 	if (!this.options.beforeResolve) {
	// 		return Promise.resolve();
	// 	}
	// 	let posted = this.value;
	// 	return this.options.beforeResolve.then(received => ({ received, posted }), xhr => xhr);
	// }
});

function normalizeProp(prop, property) {
	if (isNumber(property)) {
		property = prop.property;
	}
	const prepared = {
		id: property,
		...prop,
		property
	}
	if (prepared.valueType === 'enum' && !prepared.sourceValues) {
		const enumName = prepared.valueSubType || prepared.enumType;
		prepared.sourceValues = refs.enum(enumName);
	}
	return prepared;
}

function prepareHash(props) {
	if (props == null) return [];
	if (Array.isArray(props)) return props.map(normalizeProp);
	if (typeof props === 'object') {
		return _.map(props, normalizeProp);
		// (prop, property) => {
		// 	const hash = {
		// 		id: property,
		// 		...prop,
		// 		property
		// 	}
		// 	return hash;
		// })
	}
	throw new Error('Not implemented case');
}

function ensureValuesHash(options) {
	if (!options || options.valuesHash || !options.valuesModel) return;
	options.valuesHash = simplify(options.valuesModel.attributes);
	//console.log('ENSURE VH:', options.valuesHash, options.valuesModel.attributes);
}

function preparePropertiesValues(props, model, hash)
{
	//if (!model && !hash) return props;

	return props.map(prop => {
		if ('_value' in prop)
			return prop;
		
		let _value;
		if (typeof prop.getEditValue === 'function') {
			_value = prop.getEditValue(model);
		} else if (model || hash) {
			_value = getValueByPath(model || hash, prop.property); 
		}

		return {
			...prop,
			_value
		}
	});

}


function buildEditView(properties, options = {}, specOptions) {

	ensureValuesHash(options);
	
	properties = prepareHash(properties);
	properties = preparePropertiesValues(properties, options.valuesModel, options.valuesHash);

	let view = new EditView({
		properties,
		...options,
		...specOptions
	});
	return view;
}

export default {
	EditView,
	inLine(properties, options) {
		options = Object.assign({ className: 'editschema-in-line'}, options);
		return buildEditView(properties, options);
	},
	inModal(properties, options) {
		options = Object.assign({ className: 'editschema-in-modal'}, options);
		let view = buildEditView(properties, options, { destroyOnDone: true });
		let modal = modals.modal(view);
		return { modal, view };
	}
}
