﻿//define('realties-offers-filters', ['references', 'geo', 'realties-models', 'store', 'bus-svc'], function (refs, geo, realtiesNs, store, appBus) {});

import refs from 'references';
import geo from 'vXX/geo';
import realtiesNs from 'svc/realties/models';
import store from '_libs/store';
// import appBus from 'bus/svc';
import { _, Collection as BbCollection } from 'vendors';
// import { getBuildingName } from '../../../m/realties/buildings/buildings';
import { getRealtyName } from 'mods/realties/realties/realties';

var ns = {};

function getFiltersForStore(filters, allowed) {
	allowed = allowed || ['mt', 'mo', 'sq', 'price', 'status', 'aprcnt'];
	//console.log('getFiltersForStore', filters, allowed);
	return _(filters || []).chain()
		.filter(function (f) { return allowed == 'all' || allowed.indexOf(f.id) > -1; })
		.map(function (m) {
			var values;
			if (_.isArray(m.values)) {
				values = _(m.values).map(function (val) {
					if (!_.isObject(val))
						return val;
					else {
						if ('from' in val || 'to' in val)
							return val
						else
							return val.value;
					}
				});
			} else
				values = m.values;
			//console.log('	==>', m.id, values);
			if (_.isArray(values) && values.length == 0)
				values = undefined;

			return { id: m.id, values: values };
		})
		.value();
}

function mergeFiltersWithValues(filters, values) {
	_(values).each(function (v) {
		var found = _(filters).findWhere({ id: v.id });
		if (!found) return;
		found.values = v.values;
	});
}


function addFilterSingle(res, id, value) {
	if(value != null)
		res[id] = [value];
}
function addFilterRange(res, id, from, to) {		
	if (from == null && to == null) return;

	from && (parseFloat(from));
	to && (parseFloat(to));

	if (isNaN(from) && isNaN(to)) return;


	if (from == null || isNaN(from)) {
		from = to * 0.7;
		to = to * 1.3;
	}
	else if (to == null || isNaN(to)) {
		to = from * 1.3;
		from = from * 0.7;
	} else {
		to = to * 1.3;
		from = from * 0.7;
	}
		
	addFilterSingle(res, id, { from: from, to: to });
}

function applyDealValues(fltrs, deal) {
	//console.log('APPLY DEAL VALUES', !deal, !deal.get('subject'));
	if (!deal || !deal.get('subject')) return;
	var sbj = deal.get('subject') || {};

	var _f = _(fltrs);

	var res = {};


	//var market = sbj.market;

	addFilterSingle(res, 'mt', sbj.market);
	addFilterSingle(res, 'mo', sbj.operation);
	addFilterRange(res, 'sq', sbj.squareFrom, sbj.squareTo);
	//if (sbj.realtyId)
	//	addFilterSingle(res, 'bld', sbj.realtyId);

	//console.log(' deal filter setup', res);

	_(res).each(function (values, id) {
		var f = _f.findWhere({ id: id });
		if (f)
			f.values = values;

		//console.log('>>f', id, !!f, f, values);
	});


}


var filters = function (opts) {
	opts || (opts = {});
	var base = (function () {
		return [
			{
				id: 'text',
				group: 'base',
				order: -100,
				caption: 'текст',
				values: [],
				emptyText: 'не указан',
				modelType: 'single',
				valueType: 'string',
				// sourceValues: refs.enum('realtyMarkets'),
				// flattenValues: true,
				multiple: false,
				pinned: true
			},
			{
				id: 'mt',
				group: 'base',
				order: 0,
				caption: 'рынок',
				values: ['offices'],
				emptyText: 'не выбран',
				modelType: 'single',
				valueType: 'string',
				sourceValues: refs.enum('realtyMarkets'),
				flattenValues: true,
				multiple: true,
				pinned: true
			},
			{
				id: 'mo',
				group: 'base',
				order: 1,
				caption: 'операция',
				values: ['rent'],
				emptyText: 'не выбрана',
				modelType: 'single',
				valueType: 'string',
				sourceValues: refs.enum('realtyOperations'),
				multiple: false,
				pinned: true
			},
			{
				id: 'price',
				group: 'base',
				order: 3,
				caption: 'стоимость метра',
				emptyText: 'не задана',
				valueType: 'number',
				modelType: 'range',
				pinned: true
			},
			{
				id: 'fprice',
				group: 'base',
				order: 3,
				caption: 'полная стоимость (в месяц для аренды)',
				emptyText: 'не задана',
				valueType: 'number',
				modelType: 'range',
				pinned: true
			},			
			{
				id: 'sq',
				group: 'base',
				order: 2,
				caption: 'площадь',
				emptyText: 'не задана',
				valueType: 'number',
				modelType: 'range',
				pinned: true
			},

		];
	})();

	var buildings = (function () {
		var arr = [
			{
				id: 'bld',
				group: 'building',
				order: 0,
				caption: 'здание',
				emptyText: 'не задано',
				valueType: 'string',
				modelType: 'single',
				controlType: 'selectRest',
				sourceValues: realtiesNs.BuildingsRest.create(),
				display: function () {
					let value = this.get('values');
					if (value instanceof BbCollection) {
						value = value.models;
					}
					if (Array.isArray(value)) {
						value = value[0];
					}
					const ifEmpty = this.get('emptyText');
					if (!value) return ifEmpty;
					if (value.attributes) {
						value = value.attributes
					}
					if (value.label) {
						return value.label;
					}
					let name = getRealtyName(value.id)
					return name || value.id;
					//appBus.request('building:name', value.id) || value.id;
					// if (!_.isArray(vals)) {
					// 	if (vals == null)
					// 		vals = [];
					// 	else
					// 		vals = [vals];
					// }
					// var id = vals[0];
					// console.log('[DISPL]', id);
					// if (id && id.id) {
					// 	id = id.id;
					// }
					// return appBus.request('building:name', id) || this.get('emptyText');
				}
				//pinned: true,
			},
			{
				id: 'cls',
				group: 'building',
				order: 1,
				caption: 'класс',
				multiple:true,
				emptyText: 'не установлен',					
				modelType: 'single',
				valueType: 'string',
				sourceValues: refs.enum('realtyClasses'),
			},
			{
				id: 'taxid',
				group: 'building',
				order: 3,
				caption: 'номер налоговой',
				emptyText: 'не задан',
				valueType: 'number',
				modelType: 'single',
			},
			{
				id: 'mls',
				group: 'building',
				order: 2,
				caption: 'метро',
				emptyText: 'не выбрано',
				valueType: 'string',
				modelType: 'single',
				sourceValues: geo.getCurentRegionMetro(),
				multiple: true,
			},
			{
				id: 'bldsqrt',
				group: 'building',
				order: 3,
				caption: 'полная площадь',
				emptyText: 'не задана',
				valueType: 'number',
				modelType: 'range',
				pinned: false
			},
			{
				id: 'bldsqru',
				group: 'building',
				order: 3,
				caption: 'полезная площадь',
				emptyText: 'не задана',
				valueType: 'number',
				modelType: 'range',
				pinned: false
			},
		];
		return arr;
	})();

	var objects = (function() {
		var arr = [
			{
				id: 'status',
				group: 'object',
				order: -1,
				caption: 'статус предложения',
				emptyText: 'не указан',
				values: 'active',
				modelType: 'single',
				valueType: 'string',
				sourceValues: refs.enum('realtyOfferStatuses'),
				flattenValues: true,
				multiple: true,
				pinned: false,
			},
			{
				id: 'purp',
				group: 'object',
				order: 5,
				caption: 'назначение',
				emptyText: 'не задано',
				modelType: 'single',
				valueType: 'string',
				dependency: {
					resetOnFilters: ['mt'],
					filterSourceValues: {
						filters: 'mt',
						allowed: {
							'offices': ['office', 'workSpace'],
							'warehouses': ['warehouse', 'autoService', 'gasStation', 'openGround', 'production', 'safeKeeping'],
							'retail': ['bank', 'cafe', 'canteen', 'fastFood', 'fitnes', 'medicalService', 'pharmacy', 'psn', 'restaurant', 'retail', 'salesOffice', 'showRoom',],
							'apartments': ['apartments'],
							'flats': ['flat', 'room'],
							'countryHouses': ['cottage', 'groundArea', 'townhouse',],
						},
					},
				},
				sourceValues: refs.enum('realtyPurposes'),
				multiple: true,
				flattenValues: true,
			},
			{
				id: 'pln',
				group: 'object',
				order: 2,
				caption: 'планировка',
				emptyText: 'не важна',
				multiple: true,
				sourceValues: refs.enum('spaceLayouts'),
				dependency: {
					resetOnFilters: ['mt'],
					showIfFilter: [{ id: 'mt', value: 'offices' }],
				},
			},
			{
				id: 'objstate',
				group: 'object',
				order: 2,
				caption: 'состояние',
				emptyText: 'не указано',
				multiple: true,
				sourceValues: refs.enum('realtyObjectStates'),
			},
			{
				id: 'flr',
				group: 'object',
				order: 3,
				caption: 'этаж',
				emptyText: 'не важен',
				modelType: 'range',
				valueType: 'number',
			},
			{
				id: 'rms',
				group: 'object',
				order: 4,
				caption: 'количество кабинетов',
				emptyText: 'не важно',
				modelType: 'range',
				valueType: 'number',
				dependency: {
					resetOnFilters: ['mt'],
					showIfFilter: [{ id: 'mt', value: 'offices' }],
				},
			},
			{
				id: 'oopts',
				group: 'object',
				order: 5,
				caption: 'опции помещения',
				emptyText: 'не заданы',
				multiple: true,
				sourceValues: refs.enum('realtyObjectInfoFeatures'),
			},
			{
				id: 'offeat',
				group: 'object',
				order: 5,
				caption: 'опции офисного помещения',
				emptyText: 'не заданы',
				multiple: true,
				sourceValues: refs.enum('officeFeatures'),
				dependency: {
					resetOnFilters: ['mt'],
					showIfFilter: [{ id: 'mt', value: 'offices' }],
				},
			},
			{
				id: 'whfeat',
				group: 'object',
				order: 5,
				caption: 'опции складского помещения',
				emptyText: 'не задано',
				multiple: true,
				sourceValues: refs.enum('warehouseFeatures'),
				dependency: {
					resetOnFilters: ['mt'],
					showIfFilter: [{ id: 'mt', value: 'warehouses' }],
				},
			},     
			{
				id: 'whheat',
				group: 'object',
				order: 5,
				caption: 'отопление',
				emptyText: 'не задано',
				multiple: true,
				sourceValues: refs.enum('realtyObjectHeatings'),
				dependency: {
					resetOnFilters: ['mt'],
					showIfFilter: [{ id: 'mt', value: 'warehouses' }],
				},
			},                
			{
				id: 'whfreeze',
				group: 'object',
				order: 5,
				caption: 'морозильное оборудование',
				emptyText: 'не задано',
				multiple: true,
				sourceValues: refs.enum('warehouseFreezeEquipments'),
				dependency: {
					resetOnFilters: ['mt'],
					showIfFilter: [{ id: 'mt', value: 'warehouses' }],
				},
			},
			{
				id: 'whunload',
				group: 'object',
				order: 5,
				caption: 'особенности разгрузки',
				emptyText: 'не установлены',
				multiple: true,
				sourceValues: refs.enum('warehouseUnloadingFeatures'),
				dependency: {
					resetOnFilters: ['mt'],
					showIfFilter: [{ id: 'mt', value: 'warehouses' }],
				},
			},
			{
				id: 'whequip',
				group: 'object',
				order: 5,
				caption: 'оснащение',
				emptyText: 'не указано',
				multiple: true,
				sourceValues: refs.enum('warehouseUnloadingEquipments'),
				dependency: {
					resetOnFilters: ['mt'],
					showIfFilter: [{ id: 'mt', value: 'warehouses' }],
				},
			},
			{
				id: 'whfloor',
				group: 'object',
				order: 5,
				caption: 'тип пола',
				emptyText: 'не указано',
				multiple: true,
				sourceValues: refs.enum('warehouseFloors'),
				dependency: {
					resetOnFilters: ['mt'],
					showIfFilter: [{ id: 'mt', value: 'warehouses' }],
				},
			},
			{
				id: 'whgates',
				group: 'object',
				order: 5,
				caption: 'количество ворот',
				emptyText: 'не указано',
				multiple: false,
				modelType:'range',
				valueType: 'number',
				//sourceValues: refs.enum('warehouseUnloadingEquipments'),
				dependency: {
					resetOnFilters: ['mt'],
					showIfFilter: [{ id: 'mt', value: 'warehouses' }],
				},
			},
			{
				id: 'whpalletes',
				group: 'object',
				order: 5,
				caption: 'количество паллетомест',
				emptyText: 'не указано',
				multiple: false,
				modelType:'range',
				valueType: 'number',
				//sourceValues: refs.enum('warehouseUnloadingEquipments'),
				dependency: {
					resetOnFilters: ['mt'],
					showIfFilter: [{ id: 'mt', value: 'warehouses' }],
				},
			},  				
		];
		return arr;
	})();

	var others = (function () {

		var arr = [

		];

		return arr;

	})();

	var agents = (function() {
		var arr = [
			{
				id: 'aprcnt',
				group: 'agent',
				order: 3,
				caption: '% комиссии',
				emptyText: 'не важен',
				modelType: 'range',
				valueType: 'number',
			},
			{
				id: 'acontract',
				group: 'agent',
				order: 6,
				caption: 'договорённость с собственником',
				emptyText: 'не выбрано',
				valueType: 'string',
				modelType: 'single',
				sourceValues: refs.enum('ownerToAgentContractTypes'),
				multiple: true,
			},
		];



		return arr;
	})();

	var filters = base.concat(others, objects, buildings, agents);

	applyDealValues(filters, opts.deal);

	var stored; 
	if (opts.deal) {
		stored = store.get('offers:filters:deal:' + opts.deal.id, { checkExpire: false }) || store.get('offers:filters:deal:' + opts.deal.id, { checkExpire: false, local:true });
	} else {
		stored = store.get('offers:filters:default') || store.get('offers:filters:default', { checkExpire: false, local:true });
	}
	if(stored)
		mergeFiltersWithValues(filters, stored);

	//var stored2 = store.get('offers:filters:15min');
	//if (stored2)
	//	mergeFiltersWithValues(filters, stored2);


	

	//console.log('filters -->', filters);

	return filters;
}

var groups = function () {
	return [
		{
			id: 'base',
			order: 100,
			name: 'основные'
		},
		{
			id: 'building',
			order: 10,
			name: 'строение/недвижимость'
		},
		{
			id: 'object',
			order: 50,
			name: 'помещение'
		},
		//{
		//	id: 'office',
		//	dependsOn: { id: 'mt', value: 'offices' },
		//	order: 10,
		//	name: 'офис',
		//	dependency: {
		//		showIfFilter: [{id:'mt',value:'offices'}]
		//	},
		//},
		//{
		//	id: 'warehouse',
		//	dependsOn: { id: 'mt', value: 'warehouses' },
		//	order: 10,
		//	name: 'склад',
		//	dependency: {
		//		showIfFilter: [{ id: 'mt', value: 'warehouses' }]
		//	},
//         },
		{
			id: 'agent',
			order: 0,
			name: 'для агента'
		},

	];
}

ns.filters = filters;
ns.groups = groups;
ns.changeHandler = function (opts, hash, coll, collHash) {
	opts || (opts = {});
	//var vals = getFiltersForStore(collHash);
	//store.set('offers:filters:default', vals, { days: 30 });
	//var vals2 = getFiltersForStore(collHash, 'all');
	//store.set('offers:filters:15min', vals2, { minutes: 15 });
	//console.log('filters change handler', arguments);
	if (opts.deal && opts.deal.id) {
		let all = getFiltersForStore(collHash, 'all');
		
		store.set('offers:filters:deal:' + opts.deal.id, all);
		store.set('offers:filters:deal:' + opts.deal.id, all, {local: true});

		//console.log('store deal >', all);
	} else {
		let all = getFiltersForStore(collHash, 'all');
		var main = getFiltersForStore(collHash, ['mt', 'mo', 'status']);
		store.set('offers:filters:default', main, { days: 30, local:true });
		store.set('offers:filters:default', all, { minutes: 15 });
		//console.log('just store >', main, all);
	}
	//console.log('hello, i am fltrs', );
}

export default ns;
