import { HamburgerView } from "coms/ui/HamburgerView";
import { View } from 'core';
import { HistoryModel } from "./HistoryModel";
import { tryAsync } from "../../../../utils";
import { HistoryData } from "./HistoryData";

import './actualize-history-details.less';

const WaitView = View.extend({
	template: 'Подождите, данные загружаются'
});
const ErrorView = View.extend({
	template: 'Произошла ошибка. Не удалось определить чью историю загружать.'
})

export const HistoryDetails = HamburgerView.extend({
	thisClassName: 'actualize-history-details',
	initialize() {
		const hash = this.getOptions(['offerId', 'objectId', 'realtyId'])
		this.model = new HistoryModel(hash);
		this.prepareData();
	},
	modelEvents: {
		'change:state': 'render'
	},
	async prepareData() {
		if (!this.model.isKnown) { return; }
		const res = await this.model.tryFetch();
		if (res.ok) {
			this.model.set('state', 'ready');
		}
	},
	childrenViews() {
		const state = this.model.get('state');
		console.log('[[state]]', state)
		switch(state) {
			case 'initialized':
			case 'fetching': return [WaitView];

			case 'error':
			case 'unknonwn': return [ErrorView]

			case 'ready': return [HistoryData]
		}
	},
	onRender() {
		console.log('[[ r e n d e r ]]')
	}
})