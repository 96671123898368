import { invokeValue } from "../../utils";

const SECOND_MS = 1000;
const MINUTE_MS = SECOND_MS * 60;
const HOUR_MS = MINUTE_MS * 60;
const DAY_MS = HOUR_MS * 24;

const diffChunks = [
	{ id: 'days', postfix: 'д' },
	{ id: 'hours', postfix: 'ч' },
	{ id: 'minutes', postfix: 'м' },
	{ id: 'seconds', postfix: 'с', allowed: d => d.totalHours === 0 },
	{ id: 'milliseconds', postfix: 'мс', allowed: d => d.totalMinutes === 0 },
]

// const chunksPostfix = {
// 	'days':'д',
// 	'hours': 'ч',
// 	'minutes': 'м',
// 	'seconds': 'с' ,
// 	'milliseconds': 'мс',
// }

class DateInfo {
	constructor(value) {
		const totalMilliseconds = value;
		const totalSeconds = parseInt(value / 1000, 10);
		const totalMinutes = parseInt(totalSeconds / 60, 10);
		const totalHours = parseInt(totalMinutes / 60, 10);
		const totalDays = parseInt(totalHours / 24, 10);

		
		let rest = value;

		let days = 0;
		if (rest >= DAY_MS) {
			days = parseInt(rest / DAY_MS, 10);
			rest = rest - (days * DAY_MS);
		}
		
		let hours = 0;
		if (rest >= HOUR_MS) {
			hours = parseInt(rest / HOUR_MS, 10);
			rest = rest - (hours * HOUR_MS);
		}
		
		let minutes = 0;
		if (rest >= MINUTE_MS) {
			minutes = parseInt(rest / MINUTE_MS, 10);
			rest = rest - (minutes * MINUTE_MS);
		}

		let seconds = 0;
		if (rest >= SECOND_MS) {
			seconds = parseInt(rest / SECOND_MS, 10);
			rest = rest - (seconds * SECOND_MS);
		}

		let milliseconds = rest;

		Object.assign(this, {
			totalDays,
			totalHours,
			totalMinutes,
			totalSeconds,
			totalMilliseconds,
			days,
			hours,
			minutes,
			seconds,
			milliseconds,
		});

		this.ok = totalMilliseconds > 0;
		//console.log(this, this.toString())

	}
	valueOf() {
		return this.totalMilliseconds;
	}

	toString(options) {
		const values = this._getValues(Object.assign({} , options, { display: true }));
		let res = values.join(' ');
		if (this.positive === false) {
			res = '-' + res;
		}
		return res;
	}

	toStringDays() {
		return this.toString({
			days: true,
			hours: false,
			minutes: false,
			seconds: false,
			milliseconds: false,
		});
	}

	_getValues(options = {}) {

		const chunks = diffChunks.reduce((memo, chunk) => {
			let { id, postfix, allowed } = chunk;
			const val = this[id]; 
			const optionsAllowed = options[id];
			const finalyAllowed = invokeValue((optionsAllowed != null ? optionsAllowed : allowed), this, this);
			const notForbidden = finalyAllowed !== false;
			const forceIncluded = finalyAllowed === true;

			let value = (val && notForbidden) || (!val && forceIncluded) ? (val || 0) : undefined;
			if (value == null) { return memo; }
			
			if (options.display) {
				value += postfix;
			}
			memo.push(value);
			return memo;
		}, []);
		return chunks;

	}

}
window.DateInfo = DateInfo;

export const dateApi = {
	_toDate(arg, clone) {
		if (arg == null) { return arg; }
		const type = typeof arg;
		if (arg instanceof Date) {
			return clone === false ? arg : new Date(arg.valueOf());
		} else if (type === 'number') {
			return new Date(arg);
		} else if (type === 'string') {
			const date = new Date(arg);
			return isNaN(date.valueOf()) === false ? date : undefined;
		}
	},
	toDate(arg, options) {
		// todo: need options implementation
		const date = this._toDate(arg, options?.clone);
		if (date == null || !options || typeof options !== 'object') { return date; }
		
		let clearDown;
		for(let chunk of diffChunks) {
			let chunkOption = options[chunk.id];
			if (chunkOption == null && clearDown) {
				chunkOption = 0;
			}
			if (chunkOption === 0 && clearDown == null) {
				clearDown = true;
			}
			const isNumber = typeof chunkOption === 'number';
			if (!isNumber) { continue; }
			const upperKey = chunk.id[0].toUpperCase() + chunk.id.substring(1);
			let methodName = chunkOption === 0 ? 'set' + upperKey : 'add' + upperKey;
			date[methodName].call(date, chunkOption);
			return date;
		}
		
	},
	diff(d1, d2) {
		d1 = this.toDate(d1);
		d2 = this.toDate(d2);
		if (!(d1 && d2)) { return; }
		const rawdelta = d1.valueOf() - d2.valueOf();
		const delta = Math.abs(rawdelta);
		const info = this.info(delta);
		info.positive = rawdelta >= 0;
		return info;
	},
	info(arg) {
		if (arg == null) { return; }
		let value = arg instanceof Date ? arg.valueOf()
							: typeof arg === 'number' ? arg
							: undefined;
		if (value == null) { return; }

		const res = new DateInfo(value);

		return res;

		/*
		const totalMilliseconds = value;
		const totalSeconds = parseInt(value / 1000, 10);
		const totalMinutes = parseInt(totalSeconds / 60, 10);
		const totalHours = parseInt(totalMinutes / 60, 10);
		const totalDays = parseInt(totalHours / 24, 10);

		
		let rest = value;

		let days = 0;
		if (rest >= DAY_MS) {
			days = parseInt(rest / DAY_MS, 10);
			rest = rest - (days * DAY_MS);
		}
		
		let hours = 0;
		if (rest >= HOUR_MS) {
			hours = parseInt(rest / HOUR_MS, 10);
			rest = rest - (hours * HOUR_MS);
		}
		
		let minutes = 0;
		if (rest >= MINUTE_MS) {
			minutes = parseInt(rest / MINUTE_MS, 10);
			rest = rest - (minutes * MINUTE_MS);
		}

		let seconds = 0;
		if (rest >= SECOND_MS) {
			seconds = parseInt(rest / SECOND_MS, 10);
			rest = rest - (seconds * SECOND_MS);
		}

		let milliseconds = rest;

		return {
			totalDays,
			totalHours,
			totalMinutes,
			totalSeconds,
			totalMilliseconds,
			days,
			hours,
			minutes,
			seconds,
			milliseconds,
			valueOf() {
				return this.totalMilliseconds;
			}
		}
		*/

	},
	minMax(options, ...args) {
		if (typeof options === 'boolean') {
			options = {
				min: !!options,
				max: !options
			}
		}
		let min, max;
		for(let arg of args) {
			const date = dateApi.toDate(arg);
			if (date instanceof Date === false) { continue; }
			if (min == null || (date < min)) {
				min = date;
			}
			if (max == null || (date > max)) {
				max = date;
			}
		}
	
		if (options.min && !options.max) {
			return min;
		} else if (options.max && !options.min) {
			return max;
		} else {
			return { min, max };
		}
	},
	dayMilliseconds(arg) {
		const date = this._toDate(arg, false);
		const ms = date.valueOf();
		return parseInt(ms / DAY_MS, 10) * DAY_MS;
	}
}