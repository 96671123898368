

import cfg from 'app-config';
import beh from 'behaviors';
import modals from '_libs/modals';


import hlp from '../helpers';
import EditValue from 'ui/controls/editValue';
import Model from './model';
import { ObjectsLayout } from './objects';



import { history, _ } from 'vendors';
import { View } from 'core';
import { BbeModel, BbeCollection } from 'core/bbe';

import { MneView, MneNextCollectionView } from 'core/mne';
import { innerBus } from './innerBus';
import { ContactsInfoView } from './layout/index.js';
import { schemaEditApi } from 'apis/schema/index.js';

import { HamburgerView } from '../../../../coms/ui/HamburgerView';
import { OwnerInRealtyView } from './OwnerInRealtyView';

import NewLayout from './new-layout';
import { tryAsync } from '../../../../utils/tryAsync.js';






function normalizeTagValue(value){
	if (value == null || value === '') {
		return;
	}
	value = value.trim().toLowerCase();
	value = value.replace(/[^a-zа-яё0-9]/gmi, '');
	return value || undefined;
}







const TagModel = BbeModel.extend({
	isNew() { return true; }
});


const waitPleaseTemplate = _.template('... подождите пожалуйста ...');
const layoutTemplate = _.template(`
	<div class="info">
		<div class="oir">
			<div class="owner-info" title="собственник"></div>
			<div class="realty-info" title="недвижимость"></div>
			<div class="days-to-stale" title="кол-во дней до устаревания"></div>
		</div>
		<div class="contacts-info" title="контакты"></div>
		<div class="tags-info" title="тэги"></div>
		<div class="actualize-info" title="заметки для актуализатора"></div>
	</div>
	<div class="objects"></div>
	<div class="action-waiter"></div>
`);

const DaysToStale = View.extend({
	initialize() 
	{
		//this.listenTo(this.model, 'all', e => console.warn(`mod:[${e}]`))
		// this.listenTo(innerBus, {
		// 	'action:applied': this.render
		// });
	},
	classNames: [
		'days-to-stale_inner'
	],
	template: '<div class="el-label"><%= label %></label><div class="el-value"><%= value %></div>',
	templateContext() {
		const label = this.getLabel();
		const value = this.getValue();
		return {
			label, value
		}
	},
	_getAct(key) {
		const data = this.model.attributes.actualize || {};
		if (Array.isArray(key)) {
			return key.map(k => data[k]);
		}
		return data[key];
	},
	onBeforeRender() {
		delete this._staleState;
	},
	getStaleState() {
		//const [daysToStale, last] = this._getAct(['daysToStale', 'last']);
		if (!this._staleState) {

			const daysToStale = this._getAct('daysToStale');
			const dates = this.model.getLastActualizeDates();
			
			const state = {
				daysToStale,
				dates,
				neverActualized: dates.diff == null,
				staleNotSet: daysToStale == null,
				allNotSet: dates.diff == null && daysToStale == null,
			}
			this._staleState = state;
			console.warn('[stale state]', state);
		}
		return this._staleState;
	},
	getLabel() {
		const state = this.getStaleState();
		if (state.dates.objectsToActualize === 0) {
			return '';
		}
		if (state.allNotSet || state.staleNotSet) {
			return '';
		} else if (state.neverActualized) {
			return `устаревание ${state.daysToStale}д.`;
		} else {
			const diff = this.model.getStaleDiff();
			return diff.positive ? 'актуализировать через' : 'просрочено на';
		}
	},
	getValue() {
		const state = this.getStaleState();
		if (state.dates.objectsToActualize === 0) {
			return 'актуализация не требуется';
		}

		if (state.allNotSet || state.staleNotSet) {
			return 'установите устаревание'
		} else if (state.neverActualized) {
			return `актуализация не делалась`
		} else {
			const diff = this.model.getStaleDiff();
			return diff.toString({ days: d => d.totalDays > 0, hours: d => d.totalDays === 0, minutes: false, seconds: false, milliseconds: false });
		}
	},
	events: {
		async click() {
			const state = this.getStaleState();
			console.log('#', state);
			const res = await schemaEditApi.editValue({
				label: 'количество дней до устаревания',
				valueType: 'number',
				nullable: true,
			}, { header: 'Изменение количества дней до устаревания', value: state.daysToStale });
			let value = res.value > 0 ? parseInt(res.value, 10) : null;
			console.log(res);
			if (res.ok) {
				const attrs = {
					actualize: Object.assign({}, this.model.attributes.actualize, { daysToStale: value }),
				}
				let saver = new BbeModel();
				const saveRes = await tryAsync(() => saver.save(null, { url: this.model.url(), attrs, wait: true, method: 'patch' }));
				if (saveRes.ok) {
					this.model.attributes.actualize.daysToStale = value;
					this.render();
				} else {
					console.error(saveRes.error);
				}
			}
		}
	},
	modelEvents: {
		change:'render'
	}
});

const InfoView = MneView.extend({
	className: 'inner',
	template: _.template(`
	<div class="icon"><i></i></div>
	<div class="entity flex-grow"><a href="<%= url %>" target="_blank"><%= name %></a></div>
	<div class="show-list btn-icon"><i class="glyphicon glyphicon-align-justify"></i></div>
	`),
	behaviors: [beh.DynamicClass],
	dynamicClass() {
		return this.getOption('modelType');
	},
	GetEntity() {
		return this.model.get(this.getOption('modelType'));
	},
	events:{
		'click .show-list':'selectOther'
	},
	isOwner() {
		return this.getOption('modelType') == 'owner';
	},
	selectOther(event) {
		let options;
		if (this.isOwner()) {
			options = {
				header: 'Выберите собственника',
				controlType: 'selectOwners',
			}				
		} else {
			options = {
				header: 'Выберите здание',
				controlType: 'selectBuildings',
			}
		}
		EditValue.modal.single(options).then((type, models = []) => {
			if (type != 'ok') return;
			this.triggerMethod('select:other:done', models[0], event.ctrlKey)
		}, () => {});
	},
	onSelectOtherDone(model, ctrlKey) {
		let url = this.getOiRUrl(model.id, ctrlKey);
		if (ctrlKey) {
			let win = window.open(url, '_blank');
			win.focus();				
		} else {
			history.navigate(url, true);
		}
	},
	_getOiRUrl: hlp.getOiRUrl,
	getOiRUrl(otherId, absolute) {
		let realtyId, ownerId;
		if (this.isOwner()) {
			ownerId = otherId;
			realtyId = this.model.get('realtyId');
		} else {
			realtyId = otherId;
			ownerId = this.model.get('ownerId');
		}
		return this._getOiRUrl(ownerId, realtyId, absolute);
	},			
	templateContext() {

		let entity = this.GetEntity();
		let chunk = this.getOption('modelType') == 'owner' ? 'owners' : 'buildings';
		let id = this.model.get(this.getOption('modelType') + 'Id');
		let url = `http://adm.nrg.plus/rlt/${chunk}/${id}`;
		if (chunk == 'buildings')  {
			url = cfg.appSvcUrl('actualization/realties-list', id, 'edit');
		} else {
			url = cfg.appSvcUrl('actualization/owners-list', id, 'edit');
		}
		return {
			name: entity.name,
			url
			//url: `http://adm.nrg.plus/rlt/${chunk}/${id}`
		}
	}
});


const TagView = MneView.extend({
	tagName: 'label',
	className: 'tag',
	template: _.template('<span><i class="glyphicon glyphicon-tag"></i></span><span><%= id %></span><span class="close-btn" title="удалить тэг"><i class="glyphicon glyphicon-remove"></i></span>'),
	events: {
		'click .close-btn'() {
			let model = this.model;
			modals.confirm('подтвердите удаление тега').then(() => {
				model.destroy();
			});
		}
	},
	// initialize() {
	// 	console.log('>>> ###', this.model);
	// }
});

const TagsListView = MneNextCollectionView.extend({
	childView: TagView,
});

const TagsView = MneView.extend({
	className: 'inner',
	template: _.template(`
		<div class="icon">
			<i class="glyphicon glyphicon-tags"></i>
			<button class="add-tag" title="добавить тег"><i class="glyphicon glyphicon-plus"></i></button>
			<!-- <button class="save-tags"><i class="glyphicon glyphicon-save"></i></button> -->
		</div>
		<div class="tags-list flex-grow"></div>
	`),
	regions: {
		tags:'.tags-list'
	},
	initialize() {
		this.initializeTagsCollection();
	},
	initializeTagsCollection() {
		let tags = this.model.get('tags') || [];
		let models = tags.map(id => ({ id }));
		this.collection = new BbeCollection(models, { model: TagModel });
		//this.collection.url = this.model.url() + '/tags';
		//this.listenTo(this.collection, 'all', n => console.log('# col ev', n));
	},
	onRender() {
		let view = new TagsListView({ model: this.model, collection: this.collection });
		this.showChildView('tags', view);
	},
	collectionEvents: {
		update() {
			let tags = this.collection.pluck('id');
			let saver = new BbeModel();
			saver.save(null, { url: this.model.url() + '/tags', attrs: tags, wait: true, method: 'post' }).then(
				tags => console.log('ok', tags),
				xhr => console.log('err', xhr)
			);
		}
	},
	events: {
		'click .add-tag'(e) {
			var popupSetup = {
				controlType: 'text',
				valueType: 'text',
				header: 'новый тег:',
				//value: this.get('dates').deadline,
			};
			let col = this.collection;
			EditValue.modal.single(popupSetup)
				.done(function (type, newvalue) {

					if (type != 'ok') return;

					if (newvalue instanceof Array)
						newvalue = newvalue[0];

					newvalue = normalizeTagValue(newvalue);

					if (!newvalue) return;
					col.add({ id: newvalue });

				});
				


		}
	}
});


const NotesView = MneView.extend({
	className: 'inner',
	renderOnModelChange: true,
	template: _.template(`
	<div class="icon">
		<i class="glyphicon glyphicon-exclamation-sign"></i>
		<button class="edit-info" title="внести изменение"><i class="glyphicon glyphicon-edit"></i></button>
	</div>
	<div class="notes"><%= actualizeNotes %></div>		
	`),
	events: {
		'click .edit-info'() {
			let options = {
				header: 'Внесение изменений в заметки для актуализатора',
				valueType: 'bigtext',
				initialValue: this.model.getByPath('actualize.notes')
			}				
			EditValue.modal.single(options).then((type, text) => {
				if (type != 'ok') return;

				const attrs = { 'actualize.notes': text };
				const options = { method: 'patch', attrs };
				// options.wait = true;
				// this.model.save(null, { attrs: { 'actualize.notes': text }, wait: true, method: 'patch' });
				this.model.send(options).then(data => {
					this.model.setByPath(data)
				});
				//this.triggerMethod('select:other:done', models[0], event.ctrlKey)


			}, () => {});
		}
	},
	modelEvents: {
		change: 'render'
	},
	templateContext() {
		return {
			actualizeNotes: this.model.getByPath('actualize.notes')
		}

	},		
});





const Waiter = MneView.extend({
	className: 'actualize-actions-waiter',
	template: _.template(`<div class="waiter-box">
		<span><i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i></span>
		<span>Подождите, действие применяется...</span>
		</div>`),
	attach() {
		this.render();
		document.body.append(this.el);
	}
});

let Layout = MneView.extend({
	className: 'ownerInRealty',
	template: layoutTemplate,
	initialize() {
		this.model = new Model({
			ownerId: this.getOption('ownerId'),
			realtyId: this.getOption('realtyId')
		});
		this.model.firstFetch().then(() => this.render());
		this.listenTo(this.model, 'fetched', this.render);
		this.listenTo(innerBus, 'main:re:render', this.reFetch);
		this.listenTo(innerBus, 'before:request', this.showWaiter);
		this.listenTo(innerBus, 'after:request', this.hideWaiter);		
	},
	getTemplate() {
		if (this.model.isFetched()) {
			//console.log('> already fetched');
			return layoutTemplate;
		} else {
			//console.log('> not yet fetched');
			return waitPleaseTemplate;
		}
		// return layoutTemplate;
	},
	onRender() {

		if (!this.model.isFetched()) {
			return;
		} 

		let defs = { model: this.model };

		let owner = new InfoView({ ...defs, modelType: "owner" });
		this.showChildView('owner', owner);

		let realty = new InfoView({ ...defs, modelType: "realty" });
		this.showChildView('realty', realty);

		let daysToStale = new DaysToStale({ ...defs });
		this.showChildView('daysToStale', daysToStale);

		let tagsView = new TagsView(defs);
		this.showChildView('tags', tagsView);

		let objectsView = new ObjectsLayout(defs);
		this.showChildView('objects', objectsView);

		let actualizeInfo = new NotesView(defs);
		this.showChildView('actualizeInfo', actualizeInfo);

		let contacts = new ContactsInfoView(defs);
		this.showChildView('contactsInfo', contacts);

		// this.listenTo(objectsView, 'main:re:render', () => {
		// 	console.log('GOING REFETCH');
		// 	setTimeout(() => {
		// 		this.reFetch();
		// 	}, 2000);
		// });

		//this.showWaiter();

	},
	reFetch() {
		return this.model.fetch().then(
			() => {
				this.fetched = true;
				this.render();
			},
			() => {}
		);
	},
	regions: {
		owner: '.owner-info',
		realty: '.realty-info',
		daysToStale: '.days-to-stale',
		tags: '.tags-info',
		objects: '.objects',
		actualizeInfo: '.actualize-info',
		contactsInfo: '.contacts-info'
	},


	showWaiter() {
		this._destroyWaiter();
		this._waiter = new Waiter();
		this._waiter.attach();

	},
	_destroyWaiter() {
		if (this._waiter && !this._waiter.isDestroyed()) {
			this._waiter.destroy();
			delete this._waiter;
		}
	},
	hideWaiter() {
		this._destroyWaiter();
	},	

});


// const NewLayout = HamburgerView.extend({
// 	className: 'ownerInRealty',
// 	childrenViews: [
// 		OwnerInRealtyView
// 	]
// });


export default Layout;
//export default NewLayout;
