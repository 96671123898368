import { BbeModel } from 'core/bbe';
import { flatObject, ubFlatObject, setValueByPath  } from 'utils';
import { _ } from 'vendors';
import { dateApi } from 'apis';

const offerDateFields = ['offer.actualize.last', 'offer.modified'];
const objectDateFields = ['object.actualize.last', 'object.modified'];
export const ObjectModel = BbeModel.extend({
	merge(data) {
		const flat = flatObject(data);
		_.each(flat, (value, path) => {
			console.warn(path, value, flat);
			setValueByPath(this, path, value);
		});
		this.trigger('refresh:view', this);
		console.log('# this', this);
	},
	getMinMaxDate(options) {
		//let potentialDate;
		const offerDates = offerDateFields.map(k => this.getValueByPath(k));
		let res = dateApi.minMax(options, ...offerDates);
		if (!res) {
			const objectDates = objectDateFields.map(k => this.getValueByPath(k));
			res = dateApi.minMax(options, ...objectDates);
		}
		return res;
		/*
		const m = this;
		let last = m.getValueByPath('offer.actualize.last')
								|| m.getValueByPath('offer.modified')
								|| m.getValueByPath('object.actualize.last')
								|| m.getValueByPath('object.modified');

		const dates = [
				new Date(m.getValueByPath('offer.actualize.last'))
			, new Date(m.getValueByPath('offer.modified'))
			, new Date(m.getValueByPath('object.actualize.last'))
			, new Date(m.getValueByPath('object.modified'))
		];
		dates.sort((a,b) => b.valueOf() - a.valueOf());
		last = dates.filter(f => !isNaN(f.valueOf()))[0];
		if (last) {
			return last;
		}
		*/
	}
});