import htmllib from '_libs/html';
import { View } from 'core';
import { innerBus } from '../innerBus';
import FiltersView from '../filters-view';
import { ObjectListActionsView } from './ObjectListActionsView';
import { ItemsView } from './ItemsView';

const { html, div } = htmllib;

export const ObjectsLayout = View.extend({
	initialize() {
		this.listenTo(innerBus, 'main:re:render', () => this.triggerMethod('main:re:render'));
	},
	template: _.template(
		html(
			div('filter'), div('actions'), div('items')
		)
	),
	regions: {
		filters: '.filter',
		actions: '.actions',
		items: '.items',
	},
	onRender() {
		let filtersView = new FiltersView();
		this.showChildView('filters', filtersView);

		let actionsView = new ObjectListActionsView();
		this.showChildView('actions', actionsView);
		
		this.showItems();
		
	},
	showItems() {
		let items = new ItemsView({ items: this.model.get('objects'), model: this.model });
		this.showChildView('items', items);
	},
	modelEvents: {
		sync() {
			console.log('ZHBAN')
			this.showItems();
		}
	}
});