import { BbModel } from 'core';
import { urls } from 'coms/urls';
import { tryAsync } from 'utils';

export const HistoryModel = BbModel.extend({
		constructor: function() {
			BbModel.apply(this, arguments);
			this.afterInitialize();
		},
		urlRoot() {
			const type = this.get('type');
			return urls.api(`v4/actualization/history/${type}`);
		},
		afterInitialize() {
			const {offerId, objectId, realtyId } = this.attributes;
			if (offerId) {
				this._initializeModel('offer', offerId);
			} else if (objectId) {
				this._initializeModel('object', objectId);
			} else if (realtyId) {
				this._initializeModel('realty', realtyId);
			} else {
				this.state('state','unknown');
			}
		},
		_initializeModel(type, id) {
			this.isKnown = true;
			this.set({
				id,
				type,
				state: 'initialized'
			});

		},
		async tryFetch() {
			this.set('state', 'fetching');
			const res = await tryAsync(() => this.fetch());
			console.log('res', res);
			if (res.ok) {
				this.set('state', 'fetched');
			} else {
				this.set({ state: 'error', error: res.error });
			}
			return res;
		}
});