﻿//define('m-employees-collection', ['bus', 'm-appData','m-abstract', 'm-employees-model', 'bus-svc', 'app-paths', '_libs/identity', 'ui-models-values-collection', 'ui-models-values-single', 'ui-models-values-base'], function (Bus, appData, abstractNs, EmployeesModel, appRadio, paths, identity, VC, SingleValue, ValueBase) {});

import Bus from 'bus';
import appData from 'm/appData';
import abstractNs from 'm/abstract';
import EmployeesModel from './model';
import appRadio from 'bus/svc';
import identity from '_libs/identity';
import SingleValue from 'ui/models/values/single';
import ValueBase from 'ui/models/values/base';

import { templateFuncs } from 'core/mne';

import { BbeCollection } from 'core/bbe';


var EmployeesCollection = BbeCollection.extend(
	_.extend({},
		abstractNs.SelectSingle({
			_getMapperModel: function () {
				if (this.mapperModel) return this.mapperModel;
				this.mapperModel = this.model.extend(ValueBase.prototype).extend(SingleValue.prototype);
				return this.mapperModel;
			},
			forSelectMapper: function (m) {
				var MapperModel = this._getMapperModel();
				var nm = new MapperModel();
				
				var val = { id: m.get('id'), value: m.get('id'), label: m.get('name') };
				var hash = m.toJSON();
				nm.set(_.extend({}, hash, val), { silent: true });
				return nm;
				//return _.extend({}, hash, val);
				//return { id: m.get('id'), value: m.get('id'), label: m.get('name').shortFull || m.get('email') }
			}
		}),
		{
			_typeLabel: 'сотрудники',
			model: EmployeesModel,
			url: function () {
				//return paths.api.employees;
			},
			initialize: function () {
				this.initializeSelectSingle();
				var col = this;
				appData.authData.whenReady().then(function () {
					let employees = appData.authData.get('employees');
					col.reset(employees);
					//console.warn('employees initialized', col.length, employees);
				});
			},
		}
	)
);
export const employees = new EmployeesCollection();
console.log('emps', employees);
appRadio.reply('employeesFilters', function () {
	return {
		'действующие': function (v) { return v.model.isActive(); },
		'виртуальные': function (v) { return v.model.isVirtual() && !v.model.isFired(); },
		'уволенные': function (v) { return v.model.isFired(); },
		'все': undefined
	}
});


appRadio.reply('employees', function () {
	return employees;
});
appRadio.reply('employeesSelect', function (opts) {
	console.log('employeesSelect', employees, opts);
	return employees.getForSelect(opts);
});
appRadio.reply('employee', function(id) {
	//var col = radio.request('employees');
	//if (!col) return;
	return employees.get(id);
});
appRadio.reply('employeeName', function(id) {
	var employee = appRadio.request('employee', id);
	if (!employee) return;
	return employee.get('name') || 'безымянный'; //.shortFull || employee.get('email') || 'неизвестный';
});

var assetsRadio = Bus.assets;

//var templateFuncs = 
	// assetsRadio.request('templateFuncs'/*DEPRECATED*/, templateFuncs);
_.extend(templateFuncs, {
	_empn: function (id) {
		return appRadio.request('employeeName', id);
	},
	_notme: function (id) {
		return identity.get('employeeId') != id;
	}
});

export function getEmployeeName(id) {
	const emp = employees.get(id);
	if (!emp) { return ''; }
	const name = emp.get('name');
	const email = emp.get('email');
	return name || email || '&lt;'+id+'&gt;';
}



export default EmployeesCollection;
