import { View } from 'core';
import { getValueByPath, displayNum } from 'utils';
import { _ } from 'vendors';
import consts from 'vXX/app/constants';
import refs from 'references';
const { NDS_KOEF } = consts;


const dropdownTemplate = _.template(`
<div class="btn-group" style="display: inline-block">
	<button type="button" class="dropdown-toggle<%= buttonCss ? ' ' + buttonCss : '' %>" data-toggle="dropdown">
		<label><%= label %></label>
		<span class="caret"></span>
	</button>
	<ul class="dropdown-menu">
		<% for(let i = 0; i < items.length; i++) {%>
			<li><a><%= items[i] %></a></li>	
		<% } %>
	</ul>
</div>
`)

function isArray(arg) {
	return Array.isArray(arg);
}

function comaSplit(txt) {
	if (!txt) { return txt; }
	return txt.split(/\s*,\s*/);
}

function getInputValue(inp) {
	let parsed;
	switch(inp.type) {
		case 'radio':
			return inp.checked ? inp.value : undefined;
		case 'checkbox':
			return inp.checked;
		case 'number':
			parsed = parseFloat(inp.value, 10);
			if (isNaN(parsed)) {
				return;
			}
			return parsed;
		default:
			return inp.value;
	}
}


const postfixes = {
	priceMeter: ' р. за м<sup>2</sup>',
	priceSquare: {
		true: ' р. в месяц',
		false: ' р.',
		undefined: ' р.',
	},
	square: ' м<sup>2</sup>',
}

const postfix = txt => `<small class="postfix">${txt}</small>`;

function inputToBaseValue(value, { withNds, ndsKoef, withBoma, bomaKoef, isRent } = {}) {
	if (withNds) {
		value = value / ndsKoef;
	}
	if (withBoma) {
		value = value / bomaKoef;
	}
	return value;
}

const api = {
	_getMany(mapper, models, distinct, display, flatten) {
		const hash = {};
		const all = [];

		const add = distinct 
			? v => hash[v] = 1
			: v => all.push(v);

		models.forEach(model => {
			const value = mapper(model, display);
			if (flatten && isArray(value)) {
				value.forEach(add);
			} else {
				add(value);
			}
		});

		let result = distinct ? Object.keys(hash) : all;

		if (result.length === 1) {
			result = result[0];
		} else if (result.length === 0) {
			return;
		}

		return result;
	},
	get(model, key) {
		return getValueByPath(model, key);
	},
	_getPriceIncludeWords(ndsKoef, bomaKoef, display) {
		const words = [];
		if (ndsKoef > 1) {
			words.push('ндс')
		}
		if (bomaKoef > 1) {
			words.push('кор. коэф.')
		}
		if (!display) {
			return words;
		}

		let result = '';
		if (words.length) {
			let last = words.length > 1 ? words.pop() : '';
			if (last) {
				last = ' и ' + last;
			}
			result = ' включая ' + words.join(', ') + last;
		}

		return result;
	},
	getOperation(model, display) {
		let value = this.get(model, 'offer.operation');
		if (display) {
			if (value) {
				value = refs.enum('realtyOperations', value);
			} else {
				value = 'не установлена';
			}
		}
		return value;
	},
	getOperations(models, display) {
		const distinct = true;
		return this._getMany(m => this.getOperation(m, display), models, distinct);
	},
	isRent(model) {
		let op = this.getOperation(model);
		return op === 'rent';
	},
	getNdsKoef(model) {
		const tax = this.get(model, 'offer.forCustomer.taxType') || '';
		if (tax.indexOf('nds') > -1) {
			return NDS_KOEF
		}
		return 1;
	},
	getBomaKoef(model, display) {
		let notApplied = 'не применяется';
		const rent = this.isRent(model);
		if (!rent) { return display ? notApplied : 1; }

		const boma = this.get(model, 'offer.forCustomer.boma');

		if (boma > 1) {
			return boma;
		}

		return display ? 'отсутствует' : 1;

	},
	hasBoma(models) {
		return models.some(model => this.getBomaKoef(model) > 1);
	},
	hasNds(models) {
		return models.some(model => this.getNdsKoef(model) > 1);
	},
	getBomas(models, distinct, display) {
		const mapper = (m,d) => this.getBomaKoef(m, d);
		return this._getMany(mapper, models, distinct, display);
	},
	getTaxTypes(models, display) {
		const distinct = true;
		const flatten = true;
		return this._getMany((m) => this.getTaxType(m, display), models, distinct, display, flatten);
	},
	getTaxType(model, display) {
		let result = this.get(model, 'offer.forCustomer.taxType') || 'none';
		if (display) {
			result = refs.enum('taxTypes', result) || 'не установлено';
		}
		return comaSplit(result);
	},
	getPriceMeters(models, distinct, display) {
		const mapper = (m,d) => this.getPriceMeter(m, d);
		return this._getMany(mapper, models, distinct, display);
	},
	getPriceMeter(model, display, cfg) {
		//console.log('check meter');
		const basePriceMeter = cfg 
			? this.getConfigPriceMeter(model, cfg)
			: getValueByPath(model, 'offer.forCustomer.priceMeter');
			
		let result = basePriceMeter;
		if (display) {
			result = displayNum(basePriceMeter, 2) + postfix(postfixes.priceMeter);
		}
		return result;
	},
	getConfigPriceMeter(model, cfg) {
		// console.error(cfg);
		let { withBoma, withNds } = cfg;
		
		let bomaKoef = this.getBomaKoef(model);
		let isRent = this.isRent(model);
		let ndsKoef = this.getNdsKoef(model);

		if (cfg.priceType === 'meter') {
			return inputToBaseValue(cfg.value, { withNds, ndsKoef });
		} else if (cfg.priceType === 'square') {
			const baseSquarePrice = inputToBaseValue(cfg.value, { withNds, withBoma, bomaKoef, ndsKoef });
			const square = this.getSquare(model);
			return baseSquarePrice / square * (isRent ? 12 : 1);
		}
	},
	getFullPriceMeter(model, display, cfg) {
		const basePriceMeter = this.getPriceMeter(model, false, cfg);
		//const square = this.getSquare(model);
		// const bomaKoef = this.getBomaKoef(model);
		const ndsKoef = this.getNdsKoef(model);
		//const isRent = this.isRent(model);

		const value = basePriceMeter * ndsKoef;

		if (!display) { return value; }



		let result = displayNum(value, 2)
		let add = postfixes.priceMeter;
		let words = this._getPriceIncludeWords(ndsKoef, undefined, true);
		if (words) {
			add += words;
		}
		return result + postfix(add);
	},
	getFullPriceMeters(models, distinct, display, cfg) {
		const mapper = (m,d) => this.getFullPriceMeter(m, d, cfg);
		return this._getMany(mapper, models, distinct, display);
	},
	getPriceSquare(model, display, cfg) {
		const basePriceMeter = this.getPriceMeter(model, false, cfg);
		const square = this.getSquare(model);
		const isRent = this.isRent(model);
		const value = basePriceMeter * square / (isRent ? 12 : 1);
		if (!display) { return value; }
		let result = displayNum(value, 2) + postfix(postfixes.priceSquare[isRent]);
		return result;
	},
	getFullPriceSquare(model, display, cfg) {
		const basePrice = this.getPriceSquare(model, false, cfg);
		const bomaKoef = this.getBomaKoef(model);
		const ndsKoef = this.getNdsKoef(model);
		const isRent = this.isRent(model);
		const value = basePrice * bomaKoef * ndsKoef;

		if (!display) { return value; }

		let result = displayNum(value, 2);
		let postfixText = postfixes.priceSquare[isRent];
		let words = this._getPriceIncludeWords(ndsKoef, bomaKoef, true);
		if (words) {
			postfixText += words;
		}

		return result + postfix(postfixText);
		

	},
	getFullPriceSquares(models, distinct, display, cfg) {
		const mapper = (m,d) => this.getFullPriceSquare(m, d, cfg);
		return this._getMany(mapper, models, distinct, display);
	},
	getSquare(model, display) {
		let value = this.get(model, 'object.info.squareOffer');
		console.log('[object.info.squareOffer]', this.model);
		if (display) {
			value += postfix(postfixes.square);
		}
		return value;
	},
	getSquares(models, distinct, display) {
		const mapper = (m,d) => this.getSquare(m, d);
		return this._getMany(mapper, models, distinct, display);
	}
}

const li = (label, cell) => `<li class="list-group-item">
	<div class="row">
		<span class="col-xs-5">${label}: </span>
		<span class="col-xs-7">${cell}</span>
	</div>
</li>`


const mainTemplate = `<% if (withHeader) {%><h3>изменение цены</h3><% } %>
<ul class="list-group">
	<% if (multiple) { %>
	${li('количество площадей', '<b><%= length %></b>')}
	<% } %>
	${li('площадь', '<%= squares %>')}
	${li('операция', '<%= operations %>')}
	${li('налогообложение', '<%= taxTypes %>')}
	<% if (hasBoma) { %>
	${li('коридорный коэффициэнт', '<%= bomas %>')}
	<% } %>
	<% if (cleanPriceMeters) {%>
	${li('базовая стоимость метра', '<%= baseMeterPrices %>')}
	${li('полная стоимость метра', '<%= meterPrices %>')}
	${li('полная стоимость площади', '<%= squarePrices %>')}
	<% } %>
</ul>
<hr/>
<div class="input-container">
	<label><input type="radio" name="priceType" checked="checked" value="meter"> за метр</label>
	<label><input type="radio" name="priceType" value="square"> за площадь</label>
	<input class="form-control" name="value" type="number" placeholder="введите значение" />
	<% if (hasNds) { %><label><input type="checkbox" checked="checked" name="withNds"/> ввожу с ндс</label><% } %>
	<% if (hasBoma) { %><label><input type="checkbox" checked="checked" name="withBoma"/> ввожу с кор. коэффициентом</label><% } %>
</div>
<hr/>
<ul class="list-group">
	<li class="list-group-item list-group-item-success">установится цена:</li>
	${li('полная стоимость метра', '<b class="new-price-meter-full">&mdash;</b>')}
	${li('полная стоимость площади', '<b class="new-price-square-full">&mdash;</b>')}
</ul>
<hr/>
<% if (applyButton) { %><button class="btn-primary apply">применить</button><% } %>
`;


const reducer1 = (hash, value) => {
	hash[value.id] = value.priceMeter;
	return hash;
}

const reducer2 = (hash, value) => {
	const { priceMeter, priceSquare } = value;
	hash[value.id] = { priceMeter, priceSquare };
	return hash;
}


export const EditOfferPriceBoth = View.extend({
	constructor: function(options) {
		this.valueReducer = reducer1;
		this.editValue = [];
		View.apply(this, arguments);
		let reducer = options.alternateReducer;
		if (reducer) {
			if (reducer === true) {
				reducer = reducer2;
			}
			if (typeof reducer === 'function') {
				this.valueReducer = reducer;
			}
		}
		this.inputConfig = {};
		//this.refreshNumbers = _.debounce(this._refreshNumbers.bind(this), 300);
		this.afterUserInput = _.debounce(this._afterUserInput.bind(this), 300);

		this.initializeModels(options);
		this.initializeInfo();
		
	},
	template: mainTemplate,
	templateContext() {
		return {
			...this.info,
			withHeader: this.getOption('noHeader') !== true,
		}
	},
	ui: {
		meter: '.new-price-meter-full',
		square: '.new-price-square-full',
		apply: 'button.apply'
	},
	_dropdown(value, opts = {}) {
		if (value == null || value === '') {
			value = '&mdash;';
		}
		let { label = 'разные', buttonCss = 'btn-flat-text' } = opts;

		if (isArray(value)) {
			const ctx = {
				...opts,
				label,
				buttonCss,
				items: value,
			}
			let template = dropdownTemplate(ctx);
			return template;
		} else {
			return `<b>${value}</b>`;
		}
	},

	initializeModels(opts) {
		this.mergeOptions(opts, ["models"]);
	},
	getModels() {
		return this.models;
	},
	initializeInfo() {
		
		const models = this.getModels();
		let cleanPriceMeters = models.map(m => getValueByPath(m, 'offer.forCustomer.priceMeter')).filter(f => !!f);
		if (cleanPriceMeters.length === 0) {
			cleanPriceMeters = undefined;
		}

		const operations = api.getOperations(models, true);

		let baseMeterPrices = api.getPriceMeters(models, true, true);
		let meterPrices = api.getFullPriceMeters(models, true, true);
		let taxTypes = api.getTaxTypes(models, true, true);
		let squarePrices = api.getFullPriceSquares(models, true, true);
		let squares = api.getSquares(models, true, true);
		let bomas = api.getBomas(models, true, true);
		console.warn('[control] squares found:', squares)
		this.info = {
			length: models.length,
			single: models.length === 1,
			multiple: models.length > 1,
			isRent: operations === 'rent',

			cleanPriceMeters,

			hasBoma: api.hasBoma(models),
			hasNds: api.hasNds(models),

			operations: this._dropdown(operations),			
			baseMeterPrices: this._dropdown(baseMeterPrices),
			meterPrices: this._dropdown(meterPrices),
			taxTypes: this._dropdown(taxTypes),
			squarePrices: this._dropdown(squarePrices),
			squares: this._dropdown(squares),
			bomas: this._dropdown(bomas),
			applyButton: this.getOption('applyButton', true)
		}
	},

	// getOperation() {
	// 	return this.getValue('offer.operation', true);
	// },
	// getSquare() {
	// 	return this.getValue('object.info.square');
	// },
	// getMeterPrice() {
	// 	return this.getValue('offer.forCustomer.priceMeter', true);
	// },
	// getValue(key, distinct) {
	// 	const models = this.getModels();
	// 	let values = models.map(m => getValueByPath(m, key));
	// 	if (this.manyObjects && !distinct) {
	// 		return values;
	// 	}
	// 	let hash = _.reduce(values, (memo, val) => {
	// 		memo[val] = val;
	// 		return memo;
	// 	}, {});
	// 	values = _.map(hash, (val, key) => val);
	// 	if (values.length == 1) {
	// 		return values[0];
	// 	}
	// 	return values;
	// },
	// multipleOffers: true,
	events: {
		'input input'(event) {
			const hash = this._getInputHash(event.target);
			console.log('hash', hash)
			this.changeConfig(hash);
			this.afterUserInput();
		},
		'click button.apply'(event) {
			const value = this.getValue();
			this.triggerMethod('done', value);
		}
	},
	_afterUserInput() {
		this.refreshNumbers();
		this.updateValue();
		console.warn('editValue', this.editValue);
		console.warn('value', this.getValue());
		this.triggerChange();
		this.toggleApply();
	},
	// valueReducer(hash, value) {
	// 	hash[value.id] = value.priceMeter;
	// 	return hash;
	// },
	
	getValue() {
		const multiple = this.getOption('multipleOffers', true);

		const editValue = this.editValue || [];
		const valueReducerDisabled = this.getOption('valueReducerDisabled');
		if (multiple) {
			if (valueReducerDisabled) {
				return editValue;
			}
			return editValue.length ? editValue.reduce(this.valueReducer, {}) : undefined;
		} else {
			if (editValue[0]) {
				if (valueReducerDisabled) {
					return editValue[0];
				}
				return editValue[0].priceMeter;
			}
		}
	},
	updateValue() {
		const models = this.getModels();
		if (isNaN(this.inputConfig.value)) {
			this.editValue = [];
			return;
		}
		this.editValue = models.map(model => {
			const id = getValueByPath(model, 'offer.id');
			return {
				id,
				priceMeter: api.getPriceMeter(model, false, this.inputConfig),
				priceSquare: api.getPriceSquare(model, false, this.inputConfig),
			}
		});

	},
	_getInputHash(inp) {
		const key = inp.name;
		let value = getInputValue(inp);
		if (value == null) {
			return key === 'value' ? { value: undefined } : {};
		}
		const hash = { [key]: value };
		return hash;
	},
	onRender() {
		const inputConfig = this.$('input')
			.map((i, ar) => ar)
			.toArray()
			.reduce((hash, inp) => Object.assign(hash, this._getInputHash(inp)), {});
		this.changeConfig(inputConfig);
		
		this.toggleApply();

		setTimeout(() => {
			this.$('input[name=value]').focus();
		}, 50);

	},
	changeConfig(newhash) {
		Object.assign(this.inputConfig, newhash);
	},
	refreshNumbers() {
		
		let models = this.inputConfig.value > 0 ? this.getModels() : [];
		const fullMeterPrices = api.getFullPriceMeters(models, true, true, this.inputConfig);
		const meterHtml = this._dropdown(fullMeterPrices);
		this.ui.meter.html(meterHtml);
		const fullSquarePrices = api.getFullPriceSquares(models, true, true, this.inputConfig);
		const squareHtml = this._dropdown(fullSquarePrices);
		this.ui.square.html(squareHtml);
	},
	triggerChange() {
		const value = this.getValue();
		this.triggerMethod('value:changed', value);
	},
	toggleApply() {
		const enabled = this.editValue.length > 0;
		this.ui.apply.prop('disabled', !enabled);
	}
});