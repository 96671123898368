import { Schema } from 'apis/schema';
import { getValueByPath, displayDistance , displayNum, addPostfix, addPrefix } from "utils";
import { propertySchemaApi } from "apis/schema";

import { getFullPriceSquare, getFullPriceMeter } from 'utils/price';
import { toFirstLetters } from 'utils/string';

import { extractPriceOptions, getEngClass, importantFunc, prefixFunc, postfixFunc, postfixMeters, postfixRuble } from '../common';
import { moment } from 'vendors';
import refs from 'references';
import { addresses, shortRealties } from '../realties/realties';

// postfixFunc
// postfixMeters
// importantFunc
// postfixRuble
// extractPriceOptions
// getEngClass


export const modelSchema = {
	offerFullIdentifier: {
		editable: false,
		label: 'предложение',
		displayValue: (v,m,s) => {

			// let market = propertySchemaApi.displayValue('market', m, s);
			let operation = propertySchemaApi.displayValue('operation', m, s);
			let floor = propertySchemaApi.getValue('object.info.floor', m, s);
			let square = propertySchemaApi.getValue('object.info.squareOffer', m, s);
			let room = propertySchemaApi.getValue('object.info.roomNumber', m, s);
			//console.warn('# >>', propertySchemaApi.getValue('object.info', m, s));
			//console.log('===>', operation, market, floor, square, modelSchema, model.attributes);
			//return [operation, market, addPostfix(floor, postfixFunc('эт.')), addPostfix(displayNum(square, 1), postfixMeters)].join(' ');

			
			return [
				operation,
				addPostfix(displayNum(square, 1), postfixMeters),
				addPostfix(floor, postfixFunc('эт.')),
				addPrefix(room, prefixFunc('каб.: '))
			].join(' ');
		},
		displaySmallValue: (v,m,s) => {
			const market = propertySchemaApi.displayValue('market', m, s);
			return  [m.id, market].join(', ');
		},
	},
	id: {
		label: 'ID',
		editable: false,
	},
	'market': {
			valueType: 'enum',
			enumType: 'realtyMarkets',
			label: 'рынок',
			editable: false,
	},
	'operation': {
			valueType: 'enum',
			enumType: 'realtyOperations',
			label: 'операция',
			editable: false,
	},
	'operationMarket': {
			editable: false,
			displayValue(value, model, modelSchema) {
					let market = propertySchemaApi.displayValue('market', model, modelSchema);
					let operation = propertySchemaApi.displayValue('operation', model, modelSchema);
					return [operation, market].join(' ');
			}
	},
	'status': { 
			valueType: 'enum',
			enumType: 'realtyOfferStatuses',
			label: 'статус предложения',
	},
	'squarePostfix': {
		editable: false,
		displayValue(v, m, s) {
			const value = propertySchemaApi.getValue('obj.info.squareOffer', m, s);
			return `<span>${displayNum(value)}</span><small>м<sup>2</sup></small>`;
		}
	},
	'floorPostfix': {
		editable: false,
		displayValue(v, m, s) {
			const value = propertySchemaApi.getValue('obj.info.floor', m, s);
			return `<span>${displayNum(value)}</span><small>Эт.</small>`;
		}
	},	
	'isRent': {
			editable: false,
			getValue(m, s) {
					const op = propertySchemaApi.getValue('operation', m, s);
					return op === 'rent';
			}
	},
	'isNds': {
			editable: false,
			getValue(m, s) {
					const tax = propertySchemaApi.getValue('forCustomer.taxType', m, s);
					return !!(tax && tax.indexOf('nds') > -1);
			}
	},
	'isBoma': {
			editable: false,
			getValue(m, s) {
					const op = propertySchemaApi.getValue('operation', m, s);
					const boma = propertySchemaApi.getValue('forCustomer.boma', m, s);
					return op === 'rent' && boma > 1;
			}
	},
	'priceMeterPostfix': {
			editable: false,
			displayValue(v, m, s) {
					const price = propertySchemaApi.displayValue('forCustomer.priceMeter', m, s);
					if (!price) { return; }
					const postfixValue = 'р. за м<sup>2</sup>';
					const postfix = postfixFunc(postfixValue);
					const priceText = importantFunc(price, 'price meter');
					return priceText + postfix;
			}
	},
	'priceSquarePostfix': {
			editable: false,
			displayValue(v, m, s) {
					const price = propertySchemaApi.displayValue('forCustomer.priceSquare', m, s);

					if (!price) { return; }
					const isRent = propertySchemaApi.getValue('isRent', m, s);
					const postfixValue = 'р.' + (isRent ? ' в месяц' : '');
					const postfix = postfixFunc(postfixValue);
					const priceText = importantFunc(price, 'price square');
					return priceText + postfix;
			}
	},
	'priceDetailsWords': {
			editable: false,
			getValue(m,s) {
					const words = [];

					const isNds = propertySchemaApi.getValue('isNds', m, s);
					if (isNds) {
							words.push('НДС');
					}

					const isBoma = propertySchemaApi.getValue('isBoma', m, s);
					if (isBoma) {
							words.push('КОР. КОЭФ.');
					}
					return words;
			},
			displayValue(v, m, s) {
					if (v == null) {
							return;
					}
					if (!Array.isArray(v)) {
							return v;
					}
					return v.join(', ');
			}
	},
	'shortContract': {
			editable: false,
			getValue(m,s) {
					const txt = propertySchemaApi.displayValue('forAgent.contractType', m, s);
					return toFirstLetters(txt);
			}
	},
	'forAgent.contractType': {
			label: 'агентский договор',
			valueType: 'enum',
			enumType: 'ownerToAgentContractTypes',
			displayIfEmpty: '',
	}, 
	'forAgent.income': {
			label: '% агенту',
			valueType: 'number',
			displayDigits: 2,
			postfix: '%'
	},
	'agentPercent': {
			editable: false,
			displayValue(v, m, s) {
					const ctFull = propertySchemaApi.displayValue('forAgent.contractType', m, s);
					const ct = propertySchemaApi.getValue('shortContract', m, s);
					let percent = propertySchemaApi.getValue('forAgent.income', m, s);
					if (percent == null) {
						percent = '';
					} else {
						percent += '%';
					}
					return `<span class="agent-percent" title="${ctFull}"><span class="percent">${percent}</span><span class="badge">${ct}</span></span>`;
			}
	},
	'forCustomer.priceMeter': {
			label: 'цена за метр',
			valueType: 'number',
			displayDigits: 2,
			postfix: 'р.',
			controlType: 'editOfferPriceMeter',
			displayValue: (v, model, schema) => {
					const options = extractPriceOptions(model);
					const fullPrice = getFullPriceMeter(options);
					const d = displayNum(fullPrice, 2);
					return d;
			},
			getControlValue: (value, model) => {
					let attrs = model.attributes || model;
					
					const square = getValueByPath(attrs, 'object.info.squareOffer');
					const nds = attrs.forCustomer.taxType.indexOf('nds') > -1;
					const boma = attrs.forCustomer.boma;
					const operation = attrs.operation;
					return {
							value,
							square,
							nds,
							boma,
							operation
					}
			},
			applyNewValue: (forCustomer) => {
					return { forCustomer };
			}
			// editable: false,
	}, 
	'forCustomer.priceSquare': {
			label: 'цена за площадь',
			valueType: 'number',
			displayDigits: 2,
			postfix: 'р.',
			controlType:'editOfferPriceSquare',
			refreshOnChange: ['forCustomer.priceMeter'],
			getValue: (m,s) => {
				const options = extractPriceOptions(m);
				const fullPrice = getFullPriceSquare(options);
				return fullPrice;
			},
			displayValue: (fullPrice, model, schema) => {
					const d = displayNum(fullPrice, 2);
					return d;
			},
			getControlValue: (value, model) => {
					let attrs = model.attributes || model;
					const square = getValueByPath(attrs, 'object.info.squareOffer');
					const nds = attrs.forCustomer.taxType.indexOf('nds') > -1;
					const boma = attrs.forCustomer.boma;
					const operation = attrs.operation;
					const priceMeter = attrs.forCustomer.priceMeter;
					return {
							value,
							square,
							nds,
							boma,
							operation,
							priceMeter,
					}
			},
			applyNewValue: (forCustomer) => {
					return { forCustomer };
			}
			// editable: false,
	},
	'forCustomer.offerContractType': {
			label: 'тип договора',
			valueType: 'enum',        
			enumType: 'offerContractTypes',
	},

	'forCustomer.taxType': {
			label: 'налогообложение',
			valueType: 'enum',
			enumType: 'taxTypes',
			editable: false,
	}, 
	'forCustomer.boma': {
			label: 'boma (кор. коэф.)',
			displayDigits: 2,
			valueType: 'number',
			editDescription: 'Коридорный коэффициент - это множитель на который умножается стоимость аренды в месяц: стоимость * кор.коэф.'
	},
	'forCustomer.priceIncludes': {
		label: 'в цену включено',
		valueType: 'enum',
		multiple: true,
		enumType: 'priceAdditionals',
		displayValue(v, m, s) {
			const w = propertySchemaApi.displayValue('priceDetailsWords', m, s);
			if (v) {
				v = refs.enum('priceAdditionals', v);
			}
			return [w,v].filter(f => !!f).join(', ') || '&mdash;';
		}
	},
	'forCustomer.priceExcludes': {
			label: 'в цену НЕ входит',
			valueType: 'enum',
			multiple: true,
			enumType: 'priceAdditionals'
	},

	'forCustomer.depositeInfo': {
			editable: false,
			label: 'депозит'
	},

	'forCustomer.rentDurationInfo': {
			editable: false,
			label: 'длительность аренды'
	},

	'actualize.last': {
			editable: false,
			label: 'актуализация',
			valueType: 'datetime',
			displayValue: (v,m,s) => {
				if (!v) { return '&mdash;'; }
				return moment(v).fromNow();
			},
			displaySmallValue: (v,m,s) => {
				return propertySchemaApi.getValue('actualize.lastEmployeeName', m, s);
			}
	},


	
	'obj.info.purposes': {
			valueType: 'enum',
			enumType: 'realtyPurposes',
			label: 'возможные назначения',
	},
	'obj.info.state': {
			valueType: 'enum',
			enumType: 'realtyObjectStates',
			label: 'статус площади',
	}
	,'obj.info.spaceLayout': {
			valueType: 'enum',
			enumType: 'spaceLayouts',
			label: 'планировка'
	}
	,'obj.info.roomsCount': {
			valueType: 'number',
			displayDigits: 0,
			label: 'количество кабинетов'
	}
	,'obj.info.floor': {
			valueType: 'number',
			displayDigits: 0,
			label: 'этаж'
	}
	,'obj.info.floorsCount': {
			valueType: 'number',
			displayDigits: 0,
			label: 'кол. этажей'
	}
	,'bld.taxNum': {
			valueType: 'number',
			label: 'номер налоговой',
			getValue(m, s) {
				const bld = propertySchemaApi.getValue('realty', m, s);
				return getValueByPath(bld, 'taxSvcNumber');
			}
	}
	,'bld.address.fullAddress': {
			valueType: 'string',
			label: 'адрес'
	}
	,'bld.address.metro.station': {
			valueType: 'string',
			label: 'станция метро'
	}
	,'bld.address.metro.line': {
			valueType: 'string',
			label: 'линия метро'
	}
	,'bld.address.metro.distance': {
			valueType: 'number',
			label: 'удаленность от метро'
	}
	,'modified': {
		valueType: 'datetime',
		label: 'последнее изменение',
		editable: false,
	}
	,'addressAndMetro': {
			label: 'адрес',
			getValue(m, s) {
				const address = propertySchemaApi.getValue('addressModel',m,s); 
				if (!address) { return; }
				let fullAddress = address.get('fullAddress');
				const subwayDto = address.getNearestSubwayDto();
				if (subwayDto) {
					fullAddress += '<br/>';
					fullAddress += 'ст. м. ' + subwayDto.station;
					if (subwayDto.distance != null) {
						fullAddress += ' ~' + displayDistance(subwayDto.distance) + 'м';
					}
					if(subwayDto.lineName) {
						fullAddress += '<br/><small>' + subwayDto.lineName + '</small>';
					}
				}
				return fullAddress;
				//debugger;
				// 	let address = getValueByPath(model, 'bld.address.fullAddress') || '';
				// 	let metro = getValueByPath(model, 'bld.address.metro');
				// 	if (metro && metro.station) {
				// 			address += '<br/>';
				// 			address += 'ст. м. ' + metro.station;
				// 			if (metro.distance != null) {
				// 					address += ' ~' + displayDistance(metro.distance) + 'м';
				// 			}
				// 			if(metro.line) {
				// 					address += '<br/><small>' + metro.line + '</small>';
				// 			}
				// 	}
				// 	return address;
			}
	},

	'name': {
			editable: false,
			getValue(model, modelSchema) {
					let market = propertySchemaApi.displayValue('market', model, modelSchema);
					let operation = propertySchemaApi.displayValue('operation', model, modelSchema);
					let floor = propertySchemaApi.displayValue('obj.info.floor', model, modelSchema);
					let square = propertySchemaApi.displayValue('obj.info.squareOffer', model, modelSchema);
					//console.log('===>', operation, market, floor, square, modelSchema, model.attributes);
					return [operation, market, addPostfix(floor, postfixFunc('эт.')), addPostfix(displayNum(square, 1), postfixMeters)].join(' ');
			}
	},
	'nameNoMarket': {
		label: 'предложение',
			editable: false,
			getValue(model, modelSchema) {
					// let market = propertySchemaApi.displayValue('market', model, modelSchema);
					let operation = propertySchemaApi.displayValue('operation', model, modelSchema);
					let floor = propertySchemaApi.displayValue('obj.info.floor', model, modelSchema);
					let square = propertySchemaApi.displayValue('obj.info.squareOffer', model, modelSchema);
					//console.log('===>', operation, market, floor, square, modelSchema, model.attributes);
					return [operation, addPostfix(floor, postfixFunc('эт.')), addPostfix(displayNum(square, 1), postfixMeters)].join(' ');
			}
	},
	'addressModel': {
		editable: false,
		getValue(m, s) {
			if (m.address) { return m.address; }
			const addressId = getValueByPath(m, 'addressId');
			const address = addresses.get(addressId);
			if (!address) { return console.warn('address not found'); }
			m.address = address;
			return address;
		}

	},
	'address': {
			editable: false,
			displayValue(v, m, s) {
				const address = propertySchemaApi.getValue('addressModel', m, s);
				return address?.get('fullAddress') ?? '';
					// let address = getValueByPath(m, 'bld.address.fullAddress') || '';
					// return address;
			}
	},
	'metro': {
			editable: false,
			displayValue(v, m, s) {
				const address = propertySchemaApi.getValue('addressModel', m, s);
				const nearestSubway = address?.getNearestSubwayDto();
				if (!nearestSubway) { return ''; }

				const { line, lineNumber, distance, station } = nearestSubway;
				const distanceText = displayDistance(distance);
				const lbl = `<span class="metro-holder line-${lineNumber}" title="${line}">
					<span class="metro-prefix">м.</span> <span class="metro-name clr-metro-${lineNumber} txt">${station}</span>, <span class="metro-distance">${distanceText}м</span>
				</span>`;
					return lbl;
			}
	},
	'metroOrAddress': {
			editable: false,
			displayValue(v, m, s) {
				// const address = propertySchemaApi.getValue('addressModel', m, s);
				// if (!address) { return; }
				// if (address.hasNearestSubway()) {
				// 	return propertySchemaApi.displayValue('metro', m, s);
				// }
				const metro = propertySchemaApi.displayValue('metro', m, s);
				if (metro) {
						return metro;
				}
				return propertySchemaApi.displayValue('address', m, s);
			}
	},
	'shortName': {
			editable: false,
			getValue(model, modelSchema) {
					// let market = propertySchemaApi.displayValue('market', model, modelSchema);
					// let operation = propertySchemaApi.displayValue('operation', model, modelSchema);
					let floor = propertySchemaApi.displayValue('obj.info.floor', model, modelSchema);
					let square = propertySchemaApi.displayValue('obj.info.squareOffer', model, modelSchema);
					//console.log('===>', operation, market, floor, square, modelSchema, model.attributes);
					return [addPostfix(floor, postfixFunc('эт.')), addPostfix(displayNum(square, 1), postfixMeters)].join(' ');
			}
	},
	'square': {
			editable: false,
			displayValue(v,m,s) {
					let square = propertySchemaApi.displayValue('obj.info.squareOffer', m, s);
					let squareText = displayNum(square, 1);
					let important = importantFunc(squareText, 'area');
					return addPostfix(important, postfixMeters);
			}
	},
	'floor': {
			editable: false,
			getValue(m, s) {
					return propertySchemaApi.getValue('obj.info.floor', m, s);
			},
			displayValue(v, m ,s) {
					if (v != null) {
							return v + postfixFunc('эт.');
					}
			}
	},
	'realty': {
		getValue(m, s) {
			let bld = getValueByPath(m, 'bld');
			if (!bld) {
				const realtyId = propertySchemaApi.getValue('realtyId', m, s);
				bld = shortRealties.get(realtyId);
			}
			return bld;			
		}
	},
	'realtyName': {
			editable: false,
			getValue(m, s) {
					const bld = propertySchemaApi.getValue('realty', m, s);
					const cls = getValueByPath(bld, 'cls'); //getValueByPath(m, 'bld.cls');
					const name = getValueByPath(bld, 'name'); //getValueByPath(m, 'bld.name');
					return `<span class="realty-name-with-badge"><span class="realty-name">${name}</span><span class="realty-class-badge badge">${cls}</span></span>`;
			},
			// displayValue(v, model, schema) {
			//     // console.log('# model #', model.attributes.bld);
			//     return v;
			// }
	},
	'realtyClass': {
			editable: false,
			getValue(model, schema) {
				const bld = propertySchemaApi.getValue('realty', model, schema);
				return getValueByPath(bld, 'cls');
			},
			displayValue(v, model, schema) {
					return 'класс ' + v;
					// console.log('# model #', model.attributes);
			}
	},
	'realtyClassEng': {
			editable: false,
			getValue(m,s) {
					const classRu = propertySchemaApi.getValue('realtyClass', m, s);
					return getEngClass(classRu);
			}
	},
	'realtyPrefix': {
			editable: false,
			getValue(model, schema) {
					return getValueByPath(model, 'bld.prefix');
			},
			// displayValue(v, model, schema) {
			//     return v;
			//     // console.log('# model #', model.attributes);
			// }
	},
	'agentIncomPostfix': {
		editable: false,
		getValue(model, schema) {
			var price = propertySchemaApi.getValue('forCustomer.priceSquare', model, schema);
			var percent = (propertySchemaApi.getValue('forAgent.income', model, schema) || 0) / 100;
			var val = price * percent;
			if (isNaN(val)) { return; }
			return val;
		},
		displayValue(value, model, schema) {
			if (value) {
				const val = displayNum(value, 2);
				return `<span>${val}</span>${postfixRuble}`;
			}
		}
	},
	'actualizedOrModified': {
		label: 'последнее изменение',
		editable: false,
		getValue: (m,s) => {
			const last = propertySchemaApi.getValue('actualize.last', m, s)
									|| propertySchemaApi.getValue('modified', m, s);

			if (!last) { return; }

			const date = new Date(last);
			if (isNaN(date.valueOf())) { return; }

			return date;
		},
		displayValue: (v, m, s) => {
			if (!v) { return '&mdash;'; }
			return moment(v).fromNow();
		},
		displaySmallValue: (v, m, s) => {
			return propertySchemaApi.getValue('actualize.lastEmployeeName', m, s);
		}
	}
}

export const schema = new Schema({ 
    name: 'realtyOffer',
    //schemaUrl: () => appCfg.urls.apiV('realties/objects/schema'),
    modelSchema,
    // isForbiddenProperty(model) {
    //     if (model.get('isGroup')) {
    //         return true;
    //     }
    //     let parentId = model.get('parentId');
    //     let res = parentId && (/^realty\./gi.test(parentId) || /^owner\./gi.test(parentId));
    //     return res;
    // }
});