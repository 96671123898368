import { BaseObject, BbModel } from 'core';
import modals from '_libs/modals';
import { getValueByPath } from 'utils';
import { ActionModel } from './ActionModel';
import { EditPriceView } from './EditPriceView';
import refs from 'references';
import SchemaEdit from 'ui/controls/schemaEdit';
import EditValue from 'ui/controls/editValue';
import { OwnersCollection } from 'm/realties/owners';
// import { required } from './common';
import { offerProperties } from './offerProperties';
import helpers from '../helpers';
import { ObjectModel } from './objects/ObjectModel';
import { BbeCollection } from 'core/bbe';

const InnerBus = BaseObject.extend({
	actionConfirmLabels: {
		//'offer:create': 'Добавление предложения',
		'offer:publish': 'Публикацию',
		'offer:unpublish': 'Снятие с публикации',
		'offer:price': 'Изменение ставки',
		'offer:refresh': 'Обновление даты актуализации',
		//'object:newowner': 'Смену собственника'
	},
	onAction(action, context, ...rest) {
		let confirmLabel = this.actionConfirmLabels[action];
		this.takeAction(action, confirmLabel, [context.model], ...rest);
	},
	onMultiaction(action, store, ...rest) {
		let confirmLabel = this.actionConfirmLabels[action];
		this.takeAction(action, confirmLabel, store.items.map(v => v.model), ...rest);
	},
	//('set:objects:new:owner', 'смену собственника на ' + newvalue.get('name'), models, newvalue)
	takeAction(action, label, models, ...rest)
	{			
		if (label) {
			let text = label;
			if (models.length > 1) {
				text += ' (' + models.length + ')';
			}
			return modals.confirm("Подтвердите " + text).then(() => {
				console.log('after confirm:, triggering', 'action:' + action, ', rest', rest);
				this.triggerMethod('action:' + action, models, ...rest);
				return this.sendAction(action, models, ...rest);
			}, () => {})
		} else {
			//this.triggerPreAction(action, models, ...rest);
			this.triggerMethod('action:' + action, models, ...rest);
			return Promise.resolve();
		}
	},
	sendAction(action, models, ...rest) {
		let model = this.buildActionModel(action, models, ...rest);
		innerBus.triggerMethod('before:request');
		let res;
		try {
			res = model.save(null, { wait: true }).then((data) => {
				innerBus.triggerMethod('after:request');
				innerBus.triggerMethod('action:applied', data, model, models, action);
				//this.triggerMethod('action:applied', data, model, models, action);
			}, xhr => {
				innerBus.triggerMethod('after:request');
				modals.xhrError(xhr);
			});
		} catch(err) {
			console.error(err);
			innerBus.triggerMethod('after:request');
		}
		return res;
	},

	
	buildActionModel(action, models, ...rest) {
		let data;
		let ownerInRealtyId = this.model.id;
		let offers = [];
		let objects = [];
		models = models || [];
		_.each(models, m => {
			let id = getValueByPath(m, 'object.id');
			if (id != null) {
				objects.push(id);
			}
			id = getValueByPath(m, 'offer.id');
			if (id != null) {
				offers.push(id);
			}
		});

		switch(action) {
			case 'offer:publish': 
				action = 'publish'; break;
			case 'offer:unpublish': 
				action = 'unpublish'; break;
			case 'offer:refresh': 
				action = 'refresh'; break;
			case 'offer:create': 
				action = 'createOffer'; break;
			case 'offer:price': 
				action = 'newprice'; 
				data = rest[0];
				break;
			case 'set:objects:new:owner':
				action = 'changeOwner';
				data = rest[0].id;
				break;
			case 'add:offer':
				action = 'addOffer';
				data = rest[0];
				break;
			case 'create:object':
				action = 'createObject';
				data = rest[0];
				break;
			case 'commercial:update':
				action = 'offerCommercialEdit';
				data = rest[0];
				break;
			case 'update:object:massinfo':
				action = 'objectMassInfoUpdate';
				data = rest[0];
				break;
		}
		let model = new ActionModel({
			ownerInRealtyId,
			action,
			offers,
			objects,
			data
		});
		return model;
	},


	// triggerAction(action, models, ...rest) {
	// 	this.triggerPreAction(action, models, ...rest);
	// 	return this.sendAction(action, models, ...rest);
	// 		//this.triggerMethod('takedaction', action, models, ...rest);
	// },
	// triggerPreAction(action, models, ...rest) {
	// 	this.triggerMethod('action:' + action, models, ...rest);
	// 	return Promise.resolve();
	// },		
	onActionOfferPriceEdit(models) {
		let editPrice = new EditPriceView({ models });
		this.listenTo(editPrice, 'done', (price) => {
			//this.takeAction('offer:price', 'установку новой цены', models, price, editView);
			this.takeAction('offer:price', 'установку новой цены', models, price).then(() => {
				editPrice.destroy();
			});
		});
		modals.modal(editPrice);
	},
	onActionCommercialEdit(models) {
		let single = models.length == 1;
		let model = single ? models[0] : null;

		let properties = {
			//...objectProperties,
			//...offerProperties
			'forCustomer.taxType': {
				caption: 'налогообложение',
				emptyText: 'не установлено',
				display: v => v ? refs.enum('taxTypes',v) : undefined,
				sourceValues: refs.enum('taxTypes'),
				_value: model && getValueByPath(model, 'offer.forCustomer.taxType') || void 0,
				modelType: 'single',
				valueType: 'string',
				flattenValues: true,
				multiple: false,
				// validate: required,	
			},
			'forAgent.contractType': {
				caption: 'договор',
				emptyText: 'не установлен',
				display: v => v ? refs.enum('ownerToAgentContractTypes',v) : undefined,
				sourceValues: refs.enum('ownerToAgentContractTypes'),
				_value: model && getValueByPath(model, 'offer.forAgent.contractType') || void 0,
				modelType: 'single',
				valueType: 'string',
				flattenValues: true,
				multiple: false,
				// validate: required,	
			},
			'forAgent.income': {
				caption: 'процент коммиссии',
				modelType: 'single',
				valueType: 'number',
				display: v => v != null ? v + '%' : '',
				// validate: required,
				_value: model ? getValueByPath(model, 'offer.forAgent.income') : void 0,
			},
			'forCustomer.boma': {
				caption: 'коридорный коэффициент (boma, число от 1 до 2)',
				modelType: 'single',
				valueType: 'number',
				display: v => v != null && v > 1 ? v : 'отсутствует',
				// validate: required,
				_value: model ? getValueByPath(model, 'offer.forCustomer.boma') : void 0,
			},
		}

		let options = {
			header: 'Изменение коммерческих условий',
			applyText: 'Изменить',
			beforeApply: (value) => {
				console.log('!!! before Apply', value);
				return this.takeAction('commercial:update', 'изменение условий', models, value);
			},
		}
		SchemaEdit.inModal(properties, options);			
		
	},
	// onActionOfferPrice(models, newPrice, editView) {			
	// 	editView.destroy();
	// },
	onActionObjectNewowner(models) {
		// var setup = {
		// 	header: 'изменение собственника',
		// 	valueType: 'string',
		// 	//value: [owner],
		// 	//multiple: true,
		// 	controlType: 'selectOwners',
		// 	sourceValues: svcRadio.request('ownersSelect'),
		// 	// viewFilter: function (v) {
		// 	// 	return v.model.get('value') != owner && v.model.isActive();
		// 	// }
		// };
		let sourceValues = new OwnersCollection.Rest();
		let setup = {
			header: 'изменение собственника',
			valueType: 'string',
			modelType: 'single',
			controlType: 'selectRest',
			sourceValues,
		};

		//console.log('## setup', setup, sourceValues);

		EditValue.modal.single(setup).done((type, newvalue) => {
			if (type != 'ok') return; // || newvalue[0] == owner) return;
			if (Array.isArray(newvalue)) {
				newvalue = newvalue[0];
			}
			this.takeAction('set:objects:new:owner', 'смену собственника на ' + newvalue.get('label'), models, newvalue).then(() => this.refetch());

			// var change = new BbeModel({
			// 	ownerId: newvalue[0],
			// 	removePreviousFromTeam: false
			// });
			// change.url = model.url() + '/owner';
			// change.loudSave().then(function () {
			// 	model.set(change.toJSON())
			// });
		});			
		//let selectOwner = 
	},

	// onActionSetObjectsNewOwner(models, owner) {
	// 	console.log('## confirmed', models, owner);
	// },
	onActionObjectMassinfo(models) {
		let many = models.length > 1;
		let properties = {
			'status': {
				caption: 'статус помещения',
				valueType: 'enum',
				sourceValues: refs.enum('realtyObjectStatuses'),
			},
			'info.floor': {
				caption: 'этаж',
				emptyText: 'не установлен',
				modelType: 'single',
				valueType: 'number',
				display: v => v != null ? v + ' эт.' : '',
				// validate: required,	
			},
			'info.purposes': {
				caption: 'возможные назначения',
				emptyText: 'не выбраны',
				// display: v => {
				// 	console.log('v::', v);
				// 	let gg = (v || []).map(mv => refs.enum('realtyPurposes', mv));
				// 	return gg.join(', ');
				// },
				sourceValues: refs.enum('realtyPurposes'),
				modelType: 'single',
				valueType: 'enum',
				//flattenValues: true,
				multiple: true,
				// validate: required,	
			},		
			'info.currentPurpose': {
				caption: 'текущее назначение',
				emptyText: 'не выбрано',
				//display: v => v ? refs.enum('realtyPurposes',v) : undefined,
				sourceValues: refs.enum('realtyPurposes'),
				modelType: 'single',
				valueType: 'enum',
				//flattenValues: true,
				multiple: false,
			},
	
		}
		const valuesHash = {}; //view.model.toJSON().object;
		const header = 'изменение характеристик помещени' + (many ? 'й' : 'я');
		let options = {
			header,
			applyText: 'сохранить',
			// beforeApply: (data) => {
			// 	return patchRealtyObject(valuesHash.id, data).then((data) => {
			// 		innerBus.trigger('main:re:render');
			// 	});
			// },
			valuesHash,
			beforeApply: (data, ...rest) => {
				data = removeUndefineds(data);
				if (data == null) { return; }
				console.log('~~~', data)
				return this.takeAction('update:object:massinfo', header, models, data).then(() => this.model.fetch());
			},
		}
		//console.error(options.valuesHash);
		const { modal, view} = SchemaEdit.inModal(properties, options);
		//console.log('~~~', modal, view)
		//.then(arg => console.log('succ', arg), arg => console.log('err', arg));

		console.log('chpok')
	},
	onActionOfferCreate(models) {

		let square;
		let many = models.length > 1;
		if (models && models.length == 1) {
			if (!Array.isArray(models)) {
				console.warn('unable to detect square, unexpected models type');
			} else if (models.length === 1) {
				square = getValueByPath(models[0], 'object.info.squareOffer');
				console.warn('square was detected:', square)
			} else {
				console.warn('unable to detect square, there are several different models');
			}
		} 
		let properties = {
			//...objectProperties,
			...offerProperties()
		}

		let options = {
			header: 'Добавление предложени' + (many ? 'й':'я'),
			applyText: 'Добавить предложени' + (many ? 'я':'е'),
			valuesHash: { square },
			beforeApply: (value) => {
				return this.takeAction('add:offer', 'добавление предложени' + (many ? 'й' : 'я'), models, value).then(() => this.refetch());
			},
		}
		console.error({properties, options})
		SchemaEdit.inModal(properties, options);
		// .then(
		// 	(data) => { 
		// 		this.trigger('main:re:render');
		// 	},
		// 	(err) => { console.log('#eerr', err) },
		// );
	},
	refetch() {
		return this.model.fetch().then(() => this.model.trigger('fetched'));
	},
	onCreateObject() {
		let square;
		let many = false; //models.length > 1;
		// if (models && models.length == 1) {
		// 	square = models[0].getValueByPath('object.info.squareOffer');
		// }
		let properties = {
			...helpers.objectProperties({ many }),
			...offerProperties()
		}

		let options = {
			header: 'Добавление помещени' + (many ? 'й':'я'),
			applyText: 'Добавить помещени' + (many ? 'я':'е'),
			valuesHash: { square },
			beforeApply: (value) => {
				return this.takeAction('create:object', 'добавление помещени' + (many ? 'й' : 'я'), [], value).then(() => this.refetch());
			},
		}
		SchemaEdit.inModal(properties, options);
		// .then(
		// 	(data) => { 
		// 		this.trigger('main:re:render');
		// 	},
		// 	(err) => { console.log('#eerr', err) },
		// );
	},
	createNewObject(data) {

	},


	pageStarted() {
		if (this.objectOffers) {
			delete this.objectOffers;
		}
	},
	buildRawObjectOffers(raw, oirId) {		
		console.warn('== building raw models ==');
		if (raw instanceof BbModel) {
			oirId = raw.id;
			raw = raw.get('objects') || [];
		}
		//const raw = model.get('objects') || [];
		const items = [];
		for(let obj of raw) {
			const offs = obj.offers || [];
			if (!offs.length) {
				items.push({ id: obj.id, oirId, object: obj });
			} else {
				for (let off of offs) {
					items.push({
						id: off.id,
						oirId,
						object: obj,
						offer: off
					})
				}
			}
		}
		return items;
	},
	getObjectOffersCollection(model) {
		/*
		raw.reduce((memo, item) => {
			if (!item.offers || !item.offers.length) {
				memo.push({
					object: item,
				});
			} else {
				_.each(item.offers, offer => {
					memo.push({
						object: item,
						offer
					});
				});
			}
			return memo;
		}, []);		
		*/
		if (this.objectOffers == null) {
			//const items = this.buildRawObjectOffers(model);
			const col = new BbeCollection([], { 
				model: ObjectModel,
			});
			col.parse = rawdata => {
				return this.buildRawObjectOffers(rawdata, model.id);
			}
			col.on('sync', (col, data) => {
				console.error('settling data', data);
				model.set('objects', data)
			});
			col.url = model.url() + '/objects';
			this.objectOffers = col;
		}
		return this.objectOffers;
	}

});

function removeUndefineds(obj) {
	if (!obj || typeof obj !== 'object') { return; }
	let keys = 0;
	const res = {};
	for(let key in obj) {
		let value = obj[key];
		if (value && !Array.isArray(value) && typeof value === 'object') {
			value = removeUndefineds(value);
		}
		if (value !== undefined) {
			keys++;
			res[key] = value;
		}
	}
	return keys ? res : undefined;
}

export const innerBus = new InnerBus();