// define('svc/actualization/subject', [
// 	'app-config',
// 	'bus',
// 	'svc/actualization/helpers',
// 	'ui-controls-selectBuilding',
// 	'ui-controls-editValue'
// ], 
// function(
// 	cfg, bus, hlp, SelectBuilding, EditValue
// ) {});



import hlp from './helpers';
import EditValue from 'ui/controls/editValue';

import './actualize-subjects.less';

import { BbeCollection } from 'core/bbe';
import { history } from 'vendors';

import { MneView, MneCollectionView } from 'core/mne';
import { moment } from 'vendors';
import { dateApi } from 'apis';
import { View } from 'core/view';

const getLastActualizedMixin = {
	getDisplayLastActualized() {
		let lastAct = '';
		let la = this.model.get('lastActualized');
		if (la) {
			la = new Date(la);
			lastAct = !isNaN(la.valueOf()) && moment(la).fromNow() || '';
		}
		return lastAct;
	}
}


const ChildView = View.extend({
	classNames: [
		'child',
		(v) => {
			const diff = v._getStaledDiff();
			if (!diff) {return;}
			return diff.positive === false ? 'staled-negative' : 'staled-positive'
		}
	],
	template: _.template(`
		<div class="child-row first">
			<div>&rarr; <a href="<%= url %>" target="_blank"><%= name %></a> <big><b>(<%= childrenCount %>)</b></big></div>
			<div><%= lastAct %></div>
			<div class="staled"><span><%= staled %></span></div>
			<div class="tags"><%= tagsHtml %></div>
		</div>
		<div class="child-info">
			<%= info %>
		</div>
	`),
	initialize(opts) {
		this.mergeOptions(opts, ['parent']);
		//const diff = this._getStaledDiff();
		this.updateClassName();
	},
	// _getUrl(ownerId, realtyId) {
	// 	return cfg.urls.url('actualization/ownerInRealty?r=' + realtyId + '&o=' + ownerId);
	// },
	_getUrl: hlp.getOiRUrl,
	getUrl() {
		let isOwner = this.getOption("modelType") == "owner";
		let ownerId = isOwner ? this.model.id : this.parent.id;
		let realtyId = isOwner ? this.parent.id : this.model.id;
		return this._getUrl(ownerId, realtyId);
	},

	...getLastActualizedMixin,
	events: {
		'click a'(event) {
			let antiCtrl = !event.ctrlKey;
			if (antiCtrl) {
				return;
			} else {
				event.preventDefault();
				event.stopPropagation();
				let $a = $(event.target).closest('a');
				let a = $a.get(0).href;
				a = a.replace(/^https*:\/\//gi, '');
				a = a.split('/');
				a.shift();
				a = a.join('/');
				console.log('## a', a)
				history.navigate(a, true);
			}
		}
	},
	templateContext() {
		return {
			url: this.getUrl(),
			lastAct: this.getDisplayLastActualized(),
			tagsHtml: (this.model.get('tags') || []).map(tag => `<label class="tag"><i></i>${tag}</label>`).join(''),
			info: this.model.get('info'),
			staled: this.getStaledDisplay()
		}

	},
	getStaledDisplay() {
		const diff = this._getStaledDiff()
		const res = diff ? diff.toStringDays() : '';
		return res;
	},
	_getStaledDiff() {
		if (this.staledDiff || !this.model) { return this.staledDiff; }
		const staled = this.model.get('staledDate');
		if (!staled) { return; }
		const diff = this.staledDiff = dateApi.diff(staled, Date.now());
		return diff;
	}
});

const ChildrenView = MneCollectionView.extend({
	initialize() {
		this.listenTo(this.model, 'change', this.updateCollection);
		if (!this.collection) {
			let children = this.model.get('children');
			this.collection = new BbeCollection(children)
		} else {
			this.updateCollection();
		}
	},
	updateCollection() {
		let children = this.model.get('children');
		this.collection.reset(children);
	},
	childView: ChildView,
	childViewOptions() {
		return {
			parent: this.model,
			modelType: this.getOption("modelType")
		}
	}
});


const template = `<div class="subject-name"><label><%= header %></label><span><%= lastAct %></span></div>
<div class="subject-info"><%= info %></div>
<div data-role="children"></div>
<div class="bottom-actions">
<span class="action-link select-other"><%= selectOther %></span>
</div>`;

const ItemView = MneView.extend({
	className: 'actualize-subject',
	template: _.template(template),
	regions: {
		children: '[data-role="children"]'
	},
	isOwner() {
		return this.getOption("modelType") == 'owner';
	},
	isRealty() {
		return !this.isOwner();
	},
	getChildModelType() {
		return this.isOwner() ? 'realty' : 'owner';
	},
	onRender() {
		let view = new ChildrenView({ 
			model: this.model,
			modelType: this.getChildModelType(),
		});
		this.showChildView('children', view);
	},

	...getLastActualizedMixin,

	events: {
		'click .select-other':'selectOther'
	},
	selectOther(event) {
		console.log('Select Other', ...arguments);
		let options;
		if (this.isOwner()) {
			options = {
				header: 'Выберите здание',
				controlType: 'selectBuildings',
			}
		} else {
			options = {
				header: 'Выберите собственника',
				controlType: 'selectOwners',
			}				
		}

		EditValue.modal.single(options).then((type, models = []) => {
			if (type != 'ok') return;
			this.triggerMethod('select:other:done', models[0], event.ctrlKey)
		}, () => {});

	},
	onSelectOtherDone(model, ctrlKey) {
		let url = this.getOiRUrl(model.id, ctrlKey);
		//console.log('navigate url',model.id, url, ctrlKey);
		if (ctrlKey) {
			let win = window.open(url, '_blank');
			win.focus();				
		} else {
			history.navigate(url, true);
		}
	},

	templateContext() {
		let editLink = this.getOption('useEditLink') == true;
		let header = this.model.get('name');
		if (editLink) {
			let editUrl = hlp.getEditUrl(this.getOption("modelType"), this.model.id);
			header = `<a href="${editUrl}" target="_blank">${header}</a>`;
		}
		return {
			header,
			useEditLink: this.getOption('useEditLink') == true,
			lastAct: this.getDisplayLastActualized(),
			selectOther: this.isOwner() ? 'выбрать другое здание' : 'выбрать другого собственника',
			info: this.model.get('info')
		}
	},
	_getOiRUrl: hlp.getOiRUrl,
	getOiRUrl(otherId, absolute) {
		let thisId = this.model.id;
		let realtyId, ownerId;
		if (this.isOwner()) {
			ownerId = thisId;
			realtyId = otherId;
		} else {
			ownerId = otherId;
			realtyId = thisId;
		}
		return this._getOiRUrl(ownerId, realtyId, absolute);
	}

});

export default ItemView;


