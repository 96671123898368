import { View, CollectionView, ApiCollection } from 'core';
import { UiAtomView } from '../../../../coms/ui/UiAtomView';
import { UiMoleculeView } from '../../../../coms/ui/UiMoleculeView';
import { moment } from 'vendors';
import refs from 'references';
import { getEmployeeName } from '../../../m/employees';

const ItemChunk = UiAtomView.extend({});
const Item = UiMoleculeView.extend({
	thisClassName: 'history-data-item',
	childView: ItemChunk,
	leftItems: [
		{ 
			thisClassName: 'fixed date',
			text: v => moment(v.model.get('date')).format('DD.MM.YYYY hh:mm:ss') ,
			bottomText: v => moment(v.model.get('date')).fromNow(),
		},
		{
			thisClassName: 'event',
			text: v => refs.enum('extendedLogTypes', v.model.get('type')),
			bottomText: v => getEmployeeName(v.model.get('actorId'))
		},
		// {
		// 	thisClassName: 'fixed actor',
		// 	text: v => getEmployeeName(v.model.get('actorId'))
		// }
	]
});


export const HistoryData = CollectionView.extend({
	className: 'history-data',
	childView: Item,
	initialize() {
		this.collection = new ApiCollection();
		this.collection.url = this.model.url() + '/data';
		this.collection.fetch();
	}
})