import refs from 'references';
import { View } from 'core';
import { CollectionView } from 'core/collection-view';

import { getValueByPath } from 'utils';
import { innerBus } from '../innerBus';
import consts from 'vXX/app/constants';
import { moment } from 'vendors';
import { getActionsCollection, ActionView } from '../multiActions';
const { NDS_KOEF } = consts;


const BasePropView = View.extend({
	initialize(opts) {
		this.mergeOptions(opts, ["parentView"]);
		this.initializeModel();
		this.initializeNesteds();
		// this.model = this.parentView.model;
		// this.offer = this.model.get('offer');
		// this.object = this.model.get('object');
		// if (!this.offer) {
		// 	//console.warn('NO OFF', this.model.attributes);
		// }
	},
	initializeModel() {
		if (!this.model && this.parentView) {
			this.model = this.parentView.model;
		}
	},
	initializeNesteds() {
		if (!this.model) { return; }
		this.offer = this.model.get('offer');
		this.object = this.model.get('object');
	},
	hasOffer() {
		return this.offer != null;
	},
	isRent() {
		return this.hasOffer() && getValueByPath(this.offer, 'operation') == 'rent';
	},
	isSell() {
		return this.hasOffer() && getValueByPath(this.offer, 'operation') == 'sell';
	},	
	isPublished() {
		return this.hasOffer() && getValueByPath(this.offer, 'status') == 'active';
	},
	isNotPublished() {
		if (!this.hasOffer()) return false;
		let status = getValueByPath(this.offer, 'status');
		return status && status != 'active';
	},
	getObjectUrl() {
		return `http://adm.nrg.plus/rlt/objects/${this.object.id}`;
	},
	events: {
		'click'(e) {
			console.log('# ura #');
			let action = this.getOption('clickAction');
			if (!action) return;
			this.triggerAction(action);
		}
	},
	getContext() {
		return {
			view: this.parentView,
			model: this.model,
			propView: this,
			object: this.object,
			offer: this.offer,
		}
	},
	triggerAction(action, event) {
		if (action != 'select') {
			let context = this.getContext();
			innerBus.triggerMethod('action', action, context);
		} else {
			this.triggerToggleSelection(event);
		}
	},
	triggerToggleSelection(event) {
		let context = this.getContext();
		context.event = event;
		innerBus.triggerMethod('selection', context);
	}
});


export const IdView = BasePropView.extend({
	template: _.template(`
		<small><a href="<%= url %>" target="_blank"><%= id %></a></small>
		<% if (hasoffer) {%>
		<small><%= operation %></small>
		<small><%= market %></small>
		<% } %>
	`),
	templateContext() {
		let id, operation, market, hasoffer;
		let url = this.getObjectUrl();
		hasoffer = this.hasOffer();
		if (this.hasOffer()) {
			let off = this.offer;
			id = off.id;
			operation = getValueByPath(off, 'operation');
			operation = refs.enum('realtyOperations', operation);
			market = getValueByPath(off, 'market');
			market = refs.enum('realtyMarkets', market);
		} else {
			id = "нет предложений"
		}
		return {
			id, operation, market, hasoffer, url
		}
	}
});

export const LastModifiedView = BasePropView.extend({
	template: _.template('<span class="<%= howLongAgo %>" title="последнее изменение: <%= modified %>"><%= date %></span>'),
	getModifiedDate() {
		if ('_modifiedDate' in this) {
			return this._modifiedDate;
		}
		let potentialDate;
		if (this.hasOffer()) {
			potentialDate = this.model.getValueByPath('offer.modified');
		} else {
			potentialDate = this.model.getValueByPath('object.actualize.last') || this.model.getValueByPath('object.modified');
		}
		let date;
		if (_.isDate(potentialDate)) {
			// OK
		} else if (potentialDate && _.isString(potentialDate)) {
			date = new Date(potentialDate);
			if (isNaN(date.valueOf())) {
				date = undefined;
			}
		}

		// let entity = this.hasOffer() ? 'offer' : 'object';
		// let path = entity + '.modified';
		// let ts = this.model.getValueByPath(path);
		// if (_.isDate(ts)) {
		// 	// OK
		// } else if (ts && _.isString(ts)) {
		// 	ts = new Date(ts);
		// 	if (isNaN(ts.valueOf())) {
		// 		ts = undefined;
		// 	}
		// } else {
		// 	ts = undefined;
		// }
		this._modifiedDate = date;
		return this._modifiedDate;
	},
	getDisplayDate() {
		let ts = this.getModifiedDate();
		if (!ts) {
			return "&mdash;";
		}
		return moment(ts).fromNow();
	},
	templateContext() {
		let howLongAgo = 'never';
		let modified = this.getModifiedDate();
		let date = this.getDisplayDate();
		if (_.isDate(modified)) {
			let ts = modified;
			modified = modified.toLocaleString('ru');
			let today = (new Date()).toDay();
			let twoweeks = (new Date()).toDay();
			twoweeks.addDays(-14);

			if (ts.valueOf() >= today.valueOf()) {
				howLongAgo = 'today';
			} else if (ts.valueOf() >= twoweeks.valueOf()) {
				howLongAgo = 'twoweeks';
			} else {
				howLongAgo = 'longago';
			}				
		} else {

			modified = '';
		}
		
		return {
			modified,
			date,
			howLongAgo
		}
	}
})


export const PurposeView = BasePropView.extend({
	template: _.template('<span class="current" title="Текущее назначение помещения"><%= current %></span><span class="possible" title="Возможные назначения помещения"><%= possible %></span>'),
	templateContext() {
		let possible = refs.enum('realtyPurposes', this.object.info.purposes);
		let current = refs.enum('realtyPurposes', this.object.info.currentPurpose);
		return {
			possible,
			current
		}
	}
});

export const PriceView = BasePropView.extend({
	template: _.template(`
	
	<span class="per-square"><big><%= square %></big><%= squarePostfix %></span>
	<span class="per-meter"><big><%= meter %></big><%= meterPostfix %></span>
	`),
	linkedAction: 'changePrice',
	//clickAction: 'offer:price:edit',
	getNdsKoef() {
		if (!this.hasOffer()) return 1;
		var nds = refs.enumHasFlag(getValueByPath(this.offer, 'forCustomer.taxType'), 'nds');
		return nds ? NDS_KOEF : 1;

	},
	getBomaKoef() {
		if (!this.hasOffer() || !this.isRent()) return 1;
		let boma = parseFloat(getValueByPath(this.offer, 'forCustomer.boma') , 10);
		if (isNaN(boma)) return 1;
		return boma;
	},
	getSquarePrice() {
		if (!this.hasOffer()) return;
		let nds = this.getNdsKoef();
		let off = this.offer;
		let newPriceMeter = off.newPriceMeter;
		if (newPriceMeter != null) {
			let qs = getValueByPath(this.object, 'info.squareOffer');
			let persq = newPriceMeter * qs;
			if (this.isRent()) {
				let boma = this.getBomaKoef();
				persq = persq * boma / 12;
			}
			off.forCustomer.priceSquare = persq;
			delete off.newPriceMeter;
		}
		let price = parseFloat(getValueByPath(this.offer, 'forCustomer.priceSquare'), 10);
		return price * nds;
	},
	getMeterPrice() {
		if (!this.hasOffer()) return;
		let price = parseFloat(getValueByPath(this.offer, 'forCustomer.priceMeter'), 10);
		let boma = this.getBomaKoef();
		let nds = this.getNdsKoef();
		return price * boma * nds;
	},
	getPostFix(type) {
		if (!this.hasOffer()) return '';
		let rent = this.isRent();
		let fix = '';
		if (type == 'meter') {
			fix = 'р./м<sup>2</sup>';
			if (rent) {
				fix += "/год";
			}
		} else if (type == "square") {
			fix = "р."
			if (rent) {
				fix += "/мес"
			}
		}
		return `<i class="postfix">${fix}</i>`;
	},
	templateContext() {
		if (!this.hasOffer()) {
			return {
				square: '',
				squarePostfix: '',
				meter: '',
				meterPostfix: '',
				taxType: ''
			}
		}
		let squareNum = this.getSquarePrice();
		let meterNum = this.getMeterPrice();
		let squarePostfix = this.getPostFix('square');
		let meterPostfix = this.getPostFix('meter');
		return {
			square: !isNaN(squareNum) && _.displayNum(squareNum) || '',
			squarePostfix,
			meter: !isNaN(meterNum) && _.displayNum(meterNum) || '',
			meterPostfix,
			taxType: refs.enum('taxTypes', getValueByPath(this.offer, 'forCustomer.taxType')) || '&ndash;'
		}
	},
});

export const AgentView = BasePropView.extend({
	clickAction: 'commercial:edit',
	template: _.template(`
		<span class="percent"><%= percent %></span>
		<span class="contract-type"><%= contractType %></span>
		<span class="tax-type"><%= taxType %></span>
	`),		
	templateContext() {
		let percent = '';
		let contractType = '';
		let taxType = '';
		if (this.hasOffer()) {
			percent = getValueByPath(this.offer, 'forAgent.income');
			if (percent) {
				percent += '%';
			}
			contractType = getValueByPath(this.offer, 'forAgent.contractType');
			if (contractType) {
				contractType = refs.enum('ownerToAgentContractTypes', contractType);
			}
			taxType = refs.enum('taxTypes', getValueByPath(this.offer, 'forCustomer.taxType')) || '&ndash;'
		}
		return {
			percent,
			contractType,
			taxType,
		}
	}
});

export const __ActionsView = BasePropView.extend({
	template: _.template(`
		<button class="action createoffer<%= (hasoffer ? ' no-accent' : '') %>" data-action="offer:create" title="добавить предложение по этой площади"><i class="glyphicon glyphicon-plus-sign"></i></button>
		<button class="action newowner" data-action="object:newowner" title="поменять собственника"><i class="glyphicon glyphicon-transfer"></i></button>
		<% if (hasoffer) { %>
		<% if (pub) {%><button class="action publish" data-action="offer:publish" title="опубликовать"><i class="glyphicon glyphicon-eye-open"></i></button><% } %>
		<% if (unpub) {%><button class="action unpublish" data-action="offer:unpublish" title="снять с публикации"><i class="glyphicon glyphicon-eye-close"></i></button><% } %>
		<button class="action refresh" data-action="offer:refresh" title="без изменений"><i class="glyphicon glyphicon-refresh"></i></button>
		<% } %>
		<button class="action select" data-action="select" title="выделить/отменить выделение для массовых действий"><i></i></button>
	`),
	templateContext() {
		return {
			hasoffer: this.hasOffer(),
			pub: this.isNotPublished(),
			unpub: this.isPublished(),
		}
	},
	events: {
		'click button.action'(e) {
			let $b = $(e.target).closest('button');
			let action = $b.data('action');
			if (!action) return;
			console.log('#trigger action:', action);
			this.triggerAction(action, e);
		},
	},
});



export const ActionsView = CollectionView.extend({
	childView: ActionView,
	childViewOptions() {
		/*
		const objects = [];
		const objectId = this.model.getValueByPath('object.id');
		if (objectId) {
			objects.push(objectId)
		}
		const offers = [];
		const offerId = this.model.getValueByPath('offer.id');
		if (offerId) {
			offers.push(offerId);
		}

		const itemView = this.getOption('parentView');
		const ownerInRealty = itemView.getOption('ownerInRealty');
		const actionContext = {
			ownerInRealtyId: this.model.get('oirId'),
			ownerInRealty,
			objects,
			offers,
			collection: this.model.collection,
			itemView,
			models: [this.model]
		}
		*/
		return {
			itemView: this.getOption('parentView'),
			objectOffer: this.model,
			actionContext: this.getOption('actionContext')
		}
	},
	viewFilter(v) {
		const m = v.model;
		if (m.id === 'unselectall') { return false; }
		const target = m.get('target');
		const hasOffer = !!this.model.get('offer');
		if (target === 'offer' && !hasOffer) { return false; }
		const predicate = m.isPredicateOk({
			distinct: {
				'offer.status': this.model.getValueByPath('offer.status'),
				//'offer.status': this.model.getValueByPath('offer.status'),
			}
		});
		if (!predicate) {
			return false;
		}
		return true;
	},
	initialize() {
		this.collection = getActionsCollection();
		//console.log('check view', this);
	}
});