﻿// define('svc-deals-deal-layout', [
// 	'bus', 'store-service', 'bus-svc', 'ui-layouts-dataroleActions', 'ui-layouts-logsBoardList', 'ui-layouts-commentBoardList', 
// 	'ui-controls-taskBoardList', 'link', 'behaviors-datarole', 'ui-controls-selectEmployees', 'svc-deals-deal-entry', 
// 	'svc-deals-deal-offers', 'm-clients', 'ui-layouts-contactsList', 'm-contacts', 'svc-deals-deal-infoInBlock'
// ], function (
// 	Bus, store, radio, DataroleActions, LogsBoardList, CommentBoardList, 
// 	TaskBoardList, link, Datarole, SelectEmployees, EntryView, 
// 	DealOffers, clientsNs, ContactsList, contactsNs, DealInfoInBlock) {});


import Bus from 'bus';
//import store from '';
import radio from 'bus/svc';
import DataroleActions from 'ui/layouts/dataroleActions';
//import LogsBoardList from '';
import CommentBoardList from 'ui/layouts/commentBoardList';
import TaskBoardList from 'ui/controls/taskBoardList';
import link from 'link';
import Datarole from 'behaviors/datarole';
//import SelectEmployees from '';
import EntryView from './entry';
import DealOffers from './offers';
//import clientsNs from '';
import ContactsList from 'ui/layouts/contactsList';
//import contactsNs from '';
import DealInfoInBlock from './infoInBlock';

import teamtemplate from './team.html';
import template from './layout.html';

import { isValue, getValueByPath } from 'utils';
import {bbeViewComparator} from 'utils';

import { BbeCollection } from 'core/bbe';
import { MneView, MneNextCollectionView } from 'core/mne';
import { PropertiesBoxView } from 'coms/ui/PropertiesBox';
import { dealSearchSchema, dealEntrySchema } from 'm/deals/schemas';
import { handleAddTagClick, showTagsList } from '../../../../coms/ui/Tags';
import { ClientContactsBox } from '../../../../mods/entity-contacts';
import { urls } from 'coms/urls';
import { backendApi } from 'apis';
function getDateText(obj, path)
{
	var _date = getValueByPath(obj, path);
	var date = new Date(_date);
	if (!Date.valid(date)) return '';

	return date.toLocaleTimeString('ru') + ' ' + date.toLocaleDateString('ru');

}

var UlTeamListItem = MneView.extend({
	tagName: 'li',
	className: 'list-lbl',
	template: _.template('<span><%=_empn(id)%><small><%- _enum("processJoinTypes", _m.joinedAs) %></small></span>'),
});

var _empn = function (id) { return radio.request('employeeName', id); };

var TeamView = MneView.extend({
	className:'block',
	//template: '#tmpl-service-deals-deal-team',
	template: teamtemplate,
	regions: {
		'data': { el: 'ul', replaceElement: true }
	},
	onRender: function () {
		var view = new MneNextCollectionView({
			tagName: 'ul',
			collection: this.getOption('collection'),
			childView: UlTeamListItem,
			viewComparator: function (v1, v2) {
				return bbeViewComparator([[v2, v1, function (m) { return m.get('joinedAs') == 'teamLead' }], [v1, v2, function (m) { return _empn(m.id); }]]);
			},
		});
		this.showChildView('data', view);
	}
});

var View = MneView.extend({
	className: function () {
		return 'deal-content deal-' + this.model.get('status');
	},
	//template: '#tmpl-service-deals-deal-layout',
	template,
	regions: {
		'tasks': { el: '.tasks-region', replaceElement: true },
		'actions': { el: '.task-actions', replaceElement: true },
		'entry': { el: '.entry-region', replaceElement: true },
		'team': { el: '.team-region', replaceElement: true },
		'offers': { el: '.offers-region', replaceElement: true },
		'info': { el: '.info-region', replaceElement: true },
		'comments':'.board-list-region.comments',
		'logs': '.board-list-region.logs',
		//'clientAndContacts':{ el: '.clientcontacts-region', replaceElement: true },
		'clientAndContacts':{ el: '.clientcontacts-placeholder', replaceElement: true },
		'search': { el: '.search-region', replaceElement: true },
		'newentry': { el: '.entry-new-region', replaceElement: true },
		'tags': '.tags-container'
	},
	events: {
		'click .add-new-tag'(event) {
			handleAddTagClick(this, event)
		}
	},
	behaviors: [Datarole],
	showTags() {
		showTagsList(this, { url: 'v4/tags/deals/' + this.model.id });
	},
	onRender: function () {
		//var view = new CommentBoardList({entity:'deal',entityId: this.model.get('id')});
		//this.showChildView('comments', view);


		this.showInfo();
		this.showCommentsLayout();
		this.showActionsLayout();
		this.showTaskView();
		this.showLogsLayout();
		this.showTeam();
		this.showEntry();
		this.showSearch();
		this.showOffers();
		this.showClient();
		this.showTags();
	},
	showInfo: function () {
		var view = new DealInfoInBlock({ model: this.model });
		this.showChildView('info', view);
	},
	showOffers: function () {
		var view = DealOffers.getView({ deal: this.model });
		if (view)
			this.showChildView('offers', view);
	},
	showEntry: function () {
		// var view = new EntryView({ model: this.model });
		// this.showChildView('entry', view);

		const box = new PropertiesBoxView({
			thisClassName: 'with-borders bg-white colored-values  blue-values',
			header: 'вход',
			modelSchema: dealEntrySchema,
			modelValue: this.model.get('lead'),
			properties: ['sourceName', 'realtyId', ['operation', 'market'], ['squareFrom','squareTo'], ['priceFrom','priceTo']],
		})
		this.showChildView('newentry', box);

	},
	showSearch() {
		const model = this.model;
		const parent = this;
		const box = new PropertiesBoxView({
			thisClassName: 'with-borders bg-white colored-values blue-values',
			header: 'детали',
			modelSchema: dealSearchSchema,
			modelValue: this.model.get('searchConfig'),
			properties: [['operation', 'market'], ['squareFrom','squareTo'], ['priceFrom','priceTo']],
			editEnabled: this.model.canChange(),
			editAction(hash) {
				model.updateSearchAsync(hash).then(() => {
					parent.showSearch();
				})
			}
		});
		this.showChildView('search', box);
	},
	showTeam: function () {
		var team = this.model.get('team') || [];
		if (!team.length) return;
		var col = new BbeCollection(team);
		var teamview = new TeamView({ collection: col });
		this.showChildView('team', teamview);
	},
	onAction: function (context, modal) {
		if (_.isFunction(this.model.executeAction))
			this.model.executeAction({ action: context.type, modal: modal, view: this });
	},
	showTaskView: function () {
		var tasksview = new TaskBoardList({ entity: 'deal', entityId: this.model.get('id'), entityModel: this.model });
		this.showChildView('tasks', tasksview);
	},
	showCommentsLayout: function () {
		var dealId = this.model.get('id');
		var view = new CommentBoardList({ entity: 'deal', entityId: dealId, entityModel: this.model });
		this.showChildView('comments', view);
	},
	showLogsLayout: function () {
		//var view = new LogsBoardList({ entity: 'deal', entityId: this.model.get('id') });
		//this.showChildView('logs', view);
	},
	showActionsLayout: function () {
		var skipActions = ['openModal', 'openPage'];
		var filter = function (act) {
			return skipActions.indexOf(act.type) == -1;
		}

		var model = this.model;

		var view = DataroleActions.create({ model: model, layoutType: 'board-list-block', actionsFilter: filter });
		if (view)
			this.showChildView('actions', view);
	},
	getContactsBox() {
		const client = this.model.get('client');
		if (!client) { return; }
		const contacts = client.contacts;
		const entity = {
			typeText: 'Клиент',
			type: 'client',
			name: client.name
		}
		const clientId = client.id;
		
		let contactsUrl = urls.api(`v4/clients/${clientId}/contacts?view=view`)

		const opts = {
			//class: ClientContactsBox,
			deal: this.model,
			entity,
			contacts,
			client,
			clientId,
			async refreshData() {
				const rawContactsRes = await backendApi.getAsync(contactsUrl);
				if (rawContactsRes.ok) {
					this.collection.reset(rawContactsRes.value);
					this.collection.trigger('after:edit');
				}
			}

		}
		console.warn('--- opts', opts);
		return new ClientContactsBox(opts);
	},
	showClient() {
		const view = this.getContactsBox();
		if (view) {
			this.showChildView('clientAndContacts', view);
		}

	},
	__x__showClient: function (refresh) {
		var client = this.model.getClient();
		if (!refresh) {
			let view = this.clientView = ContactsList.ForClient(client);
			if(view)
				this.showChildView('clientAndContacts', view);
			//var entries = new BbeCollection(raw);
			//var view = this.clientView = new ContactsList({ collection: entries, parent: client });
		} else {
			if (!client) return;
			var contacts = client.getContacts();
			var raw = [client].concat(contacts.models);
			let view = this.clientView;
			if (!view) return;
			view.collection.set(raw);
			this.clientView.render();
		}
	},
	modelEvents: {
		'fetched': function () {
			this.showClient();
			//this.showTaskView();
			this.showTeam();
			this.showEntry();
			//this.showOffers();
			//this.showCommentsLayout();
		},
		'client:change:contacts': function () {
			this.showClient(true);
		},
		'change:stageId': function () {
			this.showOffers();
		},
		'change:status': function () {
			console.log('CHANGED STATUS');
			this.$el.removeClass();
			this.$el.addClass(this.className());
		},
		'team:changed': function () {
			var _this = this;
			this.model.fetch().then(function () {
				_this.showTeam();
			})
			
		},
		'change:team': function () {
			var _this = this;
			_this.showTeam();
		},

	},
	templateContextExtend: function () {
		var _hasClient = isValue(this.model.get('client'));
		var _clientName = _hasClient ? this.model.get('client').name : "не установлен";
		var _clientUrl = _hasClient ? link.url('clientCard:' + this.model.get('client').wId) : 'javascript:';
		var _ownerName = radio.request('employeeName', this.model.get('ownerId'));
		var _creatorName = radio.request('employeeName', this.model.get('creatorId'));

		var _modifiedText = getDateText(this.model, 'modified');
		var _createdText = getDateText(this.model, 'created');
		var _closedText = '';
		

		return {
			_clientName: _clientName,
			_clientUrl: _clientUrl,
			_hasClient: _hasClient,
			_ownerName: _ownerName,
			_creatorName: _creatorName,
			_modifiedText: _modifiedText,
			_createdText: _createdText,
			_closedText: _closedText,
		}
	}
	
});


Bus.Views.reply('deal:page', function () {
	return View;
});



export default View;