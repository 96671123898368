import { CollectionView } from 'core';
import { innerBus } from '../innerBus';
import { BbeCollection } from 'core/bbe';
import { bbeViewComparator } from 'utils';
import { flatObject, unFlatObject } from 'utils';
import { _ } from 'vendors';
import { MultiActionsView } from './MultiActionsView';
import filters from '../filters';
import { ObjectModel } from './ObjectModel';
import { BaseObjectView } from './BaseObjectView';
import { ObjectItemView } from './ObjectItemView';
import { Selector } from './Selector';
import { createCompare } from 'utils/comparators';
import { dateApi } from '../../../../../apis';

export const ItemsView = CollectionView.extend({
	initialize(opts) {
		innerBus.model = this.model;
		this.mergeOptions(opts,["filters"]);
		this.initCollection();
		this.listenTo(filters, 'change', this.sort);


		let sel = new Selector({ 
			parent: this, 
		});
		this.selector = sel;

		this.selector.listenTo(innerBus, {
			'selection': context => sel.click(context),
			'select:all': () => sel.select(this.availableViews),
			'unselect:all': () => sel.clear(),
			'toggle:all': () => sel.toggle(this.availableViews),
			'action:applied': this.onActionApplied.bind(this)
			//'takedaction': (action, models, ...rest) => this.takeAction(action, models, ...rest)
		});
		this.selector.on('change', () => innerBus.triggerMethod('selector:change', sel));

		//this.listenTo(innerBus, 'selection', context => this.selector.do(context));
		this.listenTo(innerBus, 'selector:change', this.onSelectorChange);
		this.on('destroy', () => this.hideMultiActions());
	},
	initCollection() {
		/*
		let items = this.getOption('items') || [];
		items = _.reduce(items, (memo, item) => {
			if (!item.offers || !item.offers.length) {
				memo.push({
					object: item,
				});
			} else {
				_.each(item.offers, offer => {
					memo.push({
						object: item,
						offer
					});
				});
			}
			return memo;
		}, []);
		this.collection = new BbeCollection(items, { model: ObjectModel });
		*/
		this.collection = innerBus.getObjectOffersCollection(this.model);
		this.collection.add(this.model.get('objects'), { parse: true });
		this.listenTo(innerBus, 'refresh:objects', async () => {
			this.collection.reset();
			innerBus.trigger('before:request');
			await this.collection.fetch({ remove: true });
			innerBus.trigger('after:request');
		})
	},
	// _renderChildren(views) {
	// 	console.log('--- _renderChildren ---', views, arguments);
	// 	this.triggerMethod('begin:render:children', this, views);
	// 	return MneNextCollectionView.prototype._renderChildren.call(this, views);
	// },
	childView: BaseObjectView,
	childViewOptions() {
		let parent = this;
		return {			
			filters: this.filters,
			ownerInRealty: this.model,
			getIndex() {
				return parent.getChildIndex(this);
			},
			isSelected() {
				return parent.isChildSelected(this);
			}
		}
	},
	viewFilter(v) {
		let raw = v.model.attributes;
		return filters.check(raw.object, raw.offer);
	},
	childViewEvents: {
		'action:toggle:select'(actionContext) {
			const { event, itemView: view } = actionContext;
			const context = { event, view };
			this.selector.click(context);
		}
	},
	// viewComparator(v1, v2) {
	// 	return bbeViewComparator([
	// 		[ v1, v2, m => m.getValueByPath('object.info.floor') || -10000],
	// 		[ v1, v2, m => m.getValueByPath('object.info.squareOffer') || -10000]
	// 	]);
	// },

	viewComparator: createCompare([
		v => {
			const status = v.model.getValueByPath('object.status');
			if (status === 'actual') { return 0; }
			if (status === 'notVerified') { return 1; }
			return 2;
		},
				
		v => {
			const date = v.model.getMinMaxDate(false);
			if (date == null) {
				return -Infinity;
			}
			const id  = v.model.attributes.offer?.id || v.model.attributes?.object?.webId?.toString();
			const ms = dateApi.dayMilliseconds(date);
			//console.log('---',id, ms)
			return ms;
			// const diff = dateApi.diff(Date.now(), date);
			// return diff.totalDays * -1;
		},
		v => {
			const off = v.model.getValueByPath('offer');
			return off ? -1 : 1;
		},
		// v => {
		// 	const status = v.model.getValueByPath('object.status');
		// 	if (status === 'actual') { return 0; }
		// 	if (status === 'notVerified') { return 1; }
		// 	return 2;
		// },
		v => v.model.getValueByPath('object.info.floor') || -Infinity,
		v => v.model.getValueByPath('object.info.squareOffer') || -Infinity
	]),

	onBeforeRenderChildren(self, views) {
		this.availableViews = views;
		this.availableIndexesByCid = {};
		_.each(views, (view, index) => {
			this.availableIndexesByCid[view.cid] = index;
		});
		innerBus.trigger('items:change', views);
	},

	onBeginRenderChildren(self, views) {
		this.availableViews = views;
		this.availableIndexesByCid = {};
		_.each(views, (view, index) => {
			this.availableIndexesByCid[view.cid] = index;
		});
		innerBus.trigger('items:change', views);
	},
	getChildIndex(view) {
		//console.log('###', view.cid, this.availableIndexesByCid);
		if (!view || !this.availableIndexesByCid) return;
		return this.availableIndexesByCid[view.cid];
	},
	getChildViewByIndex(index) {
		if (!this.availableViews) return;
		return this.availableViews[index];
	},
	isChildSelected(child) {
		return this.selector.isSelected(child);
	},
	onSelectorChange(selector) {
		let store = selector.store;
		if (store.length > 1) {
			this.showMultiActions(store);
		} else {
			this.hideMultiActions();
		}
	},
	showMultiActions(store) {
		if (store.length < 2) return;
		if (!this.multiActions) {
			this.multiActions = new MultiActionsView({
				ownerInRealty: this.model,
				objectOfferCollection: this.collection,
				selector: this.selector,
				//onActionUnselectAll: () => this.selector.clear()
			});
		}
		this.multiActions.refresh(store);
	},
	hideMultiActions() {
		this.multiActions && this.multiActions.destroy();
		delete this.multiActions;
	},
	
	onActionApplied(data, model, models, action) {
		this.selector.clear();

		if (action == 'set:objects:new:owner') {
			_.each(models, m => {
				m.trigger('not:exists', m);
			});
			return;
		}

		if (data == null) { return; }

		let res = data.response;
		let offersChanges = data.offersChanges;
		_.each(models, m => {

			if (res) {
				this._mergeModel(m, res);
			}
			const offerId = m.get('offer')?.id;
			const offerChange = (offersChanges || {})[offerId];
			if (offerChange) {
				this._mergeModel(m, { offer: offerChange });
			}
			m.trigger('refresh', m);
		});
		this.sort();
	},
	_mergeModel(model, hash) {
		let update = {};

		_.each(hash, (val, key) => {
			if (val && _.isObject(val)) {
				let mval = model.get(key);
				if (mval && _.isObject(mval)) {

					let flatRes = flatObject(mval);
					let flatNew = flatObject(val);

					_.each(flatNew, (value, key) => flatRes[key] = value);
					val = unFlatObject(flatRes);

					update[key] = val;
				} else {
					update[key] = val;
				}
			} else {
				update[key] = val;
			}
		});

		model.set(update);
	}
});