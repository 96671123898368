import { RecordsetView } from 'ui/layouts/recordset-view';
import ResultCollection from './result-collection';
import filtersConfig from './filters';
import ItemView from '../actualize-subject';
import AcPan from '../actions-panel';

const actions = [
	{
		id: 'create:owner',
		text: 'Добавить собственника',			
	},
	{
		id: 'create:building',
		text: 'Добавить здание',			
	},		
];

export default RecordsetView.extend({
	className: 'recordset-view actualization actualization-realties',
	instantApplyFilters: true,
	initialize() {			
		this.searchResult = new ResultCollection();
		this.registerActionsPanel();
	},
	listResultOptions: {
		childView: ItemView,
		childViewOptions: {
			modelType: "realty",
			useEditLink: true
		},
		//modelType: "realty",
	},
	filtersConfig,
	getFiltersOptions() {
		return {
			storeId: 'actualize.realties.filters'
		}
	},
	...AcPan.resultViewMixin({ actions }),
});
