import { MneObject } from 'core';
import { stores } from './stores';
const defaultStoreId = 'default';

const Store = MneObject.extend({
	initialize(options = {}) {
		this.id = options.storeId || defaultStoreId;
		this._store = options.session ? sessionStorage : localStorage;
	},
	_key(key) {
		return this.id + ":" + key;
	},
	get(key) {
		key = this._key(key);
		let raw = this._store.getItem(key);
		try {
			const value = JSON.parse(raw);
			return value;
		} catch(exc) {
			console.error('failed to parse json');
			console.error('raw value:', raw);
			console.error(exc);
		}
	},
	set(key, value) {
		key = this._key(key);
		if (value === undefined) {
			this._store.removeItem(key);
			return;
		}
		const json = JSON.stringify(value);
		this._store.setItem(key, json);
	}
});

export function getStore(id, options) {
	id = id || defaultStoreId;
	const found = stores.get(id);
	if (found) { return found; }
	options = Object.assign({ storeId: id }, options);
	const store = new Store(options);
	stores.set(store.id, store);
	return store;
}