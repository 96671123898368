import Bus from 'bus/core';


export function getNewInitializeMethod(WS, channel, identity, hubUrl) {
	
	function onModelAdd (name, model, options) {
		Bus.trigger(channel, name + ':add', model, options);
		Bus.trigger(channel, name, 'add', model, options);
	}
	function onModelChange(name, model, options) {
		if (model.id) {
			Bus.trigger(channel, name + ':' + model.id + ':change', model, options);
			Bus.trigger(channel, name + ':' + model.id, 'change', model, options);
		}
		Bus.trigger(channel, name + ':change', model, options);
		Bus.trigger(channel, name, 'change', model, options);
	}
	function onModelRemove(name, model, options) {
		if (model.id) {
			Bus.trigger(channel, name + ':' + model.id + ':remove', model, options);
			Bus.trigger(channel, name + ':' + model.id, 'remove', model, options);
		}
		Bus.trigger(channel, name + ':remove', model, options);
		Bus.trigger(channel, name, 'remove', model, options);
	}
	


	return () => {
		
		console.warn('sockets:','using <NEW> sockets');

		if (WS._initialized)
		return WS;

		WS._initialized = true;

		identity.loggedSvcPromise.then(() => {
			var connection = new signalR.HubConnectionBuilder().withUrl(hubUrl).build();

			connection.on("ReceiveMessage", function (user, message) {
				console.log('socket message:', arguments);
			});
			
			connection.on('ConnectionId', function(connId){
				console.log('socket connection:', connId);
			});

			connection.on('modelAdd', onModelAdd);
			connection.on('modelChange', onModelChange);
			connection.on('modelRemove', onModelRemove);

			connection.on('testMessage', (...args) => console.log('socket LC', ...args));
			connection.on('TestMessage', (...args) => console.log('socket UC', ...args));

			connection.start().then(function(){
				console.warn('new socket started');
				// document.getElementById("sendButton").disabled = false;
			}).catch(function (err) {
				return console.error(err.toString());
			});
		});


		return WS;

	}
}