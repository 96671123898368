// import { invokeValue } from 'utils/invoke';
// import { CustomsView } from 'base/views';
// import refs from 'references';
// import beh from 'behaviors';
// import { displayOfferPrice } from '_helpers/offer-price';
// import { BbCollection } from 'vendors';

import { _ } from 'vendors';
import { OffersView } from '../../../entity-list-common/edit/nested-objects/offer-in-object';
// import { MneView } from 'core/mne';
import { MnView } from 'vendors';

// const en = (name, v) => refs.enum(name, v);


// function showWithPostfix(value, postfix, ifEmpty) {
//     if (value == null)
//         return ifEmpty;
//     return value + postfix;
// }

// const defaultOfferRowCells = [
//     {
//         topValue: v => en('realtyOfferStatuses', v.model.get('status')),
//         value: v => en('realtyMarkets', v.model.get('market')) + ' ' + en('realtyOperations', v.model.get('operation')),
//         bottomValue: v => en('entityVisibilities', v.model.get('visibleFor'))
//     },
//     {
//         value: v => displayOfferPrice(v.model),
//         bottomValue: 'за метер'
//     },
//     {
//         value: v => displayOfferPrice(v.model, v.objectModel),
//         bottomValue: v => v.ifRent('за месяц','за площадь')
//     },
//     {
//         value: v => en('taxTypes', v.model.get('forCustomer').taxType),
//         bottomValue: 'налог'
//     },
//     {
//         value: v => showWithPostfix(v.model.get('forAgent').income, '%', 'не утасновлен'),
//         bottomValue: v => en('ownerToAgentContractTypes', v.model.get('forAgent').contractType)
//     }
// ];

// const OfferCellView = MneView.extend({
//     template: _.template('<%= topSign%><%= html %><%= bottomSign %>'),
//     behaviors: [beh.DynamicClass],
//     initialize() {
//         this.objectModel = this.getOption('objectModel')
//     },
//     addElClass:'as-ui-atom cell',
//     _buildHtml(key, tag) {
//         let val = this.getOption(key, { args: [this] });
//         let htmlVal = invokeValue(val, this, this) || '';
//         return `<${tag}>${htmlVal || ''}</${tag}>`;
//     },
//     buildHtml() {
//         return this._buildHtml('value', 'div');
//     },
//     buildTopSign() {
//         return this._buildHtml('topValue', 'i');
//     },
//     buildBottomSign() {
//         return this._buildHtml('bottomValue', 'b');
//     },
//     isRent() {
//         return this.model.get('operation') == 'rent';
//     },
//     ifRent(ifYes, ifNo) {
//         return this.isRent() ? ifYes : ifNo;
//     },
//     templateContextExtend() {
//         return {
//             topSign: this.buildTopSign() || '',
//             bottomSign: this.buildBottomSign() || '',
//             html: this.buildHtml() || ''
//         }
//     }
// });

// const OfferItemRow = CustomsView.extend({
//     className: 'offer-item',
//     getCustomViews() {
//         let cells = this.getOption('cells');
//         return cells.map(cell => {
//             let options = _.extend({ objectModel: this.getOption('objectModel'), model: this.model }, cell);
//             return new OfferCellView(options);
//         });
//     },
// });

// const Items = MneNextCollectionView.extend({
//     className: 'offers-list as-table',
//     initialize() {
//         this.initCollection();
//     },
//     initCollection() {
//         let models = this.model.get('offers') || [];
//         this.collection = new BbCollection(models);
//     },
//     childView: OfferItemRow,
//     childViewOptions() {
//         return {
//             cells: defaultOfferRowCells,
//             objectModel: this.model
//         }
//     }
// });


const InfoView = MnView.extend({
	className: 'wrapper',
	template: _.template(`<button class="add-offer">создать предложение</button>`),
	events:{
		'click'(e) {
			this.model.addOffer();
		}
	}
});

export default MnView.extend({
	className: 'object-offers-layout',
	template: _.template('<div class="info-container"></div><div class="filters"></div><div class="items-container"></div>'),
	regions: {
		info: '.info-container',
		filters: '.filters',
		items: '.items-container'
	},
	onRender() {
		this.showFilters();
		this.showInfo();
		this.showItems();
	},
	childOptions(){
		return { model: this.model }
	},
	
	showInfo() {
		let view = new InfoView(this.childOptions());
		this.showChildView('info', view);
	},
	showFilters() {},
	showItems() {
		let view = new OffersView(this.childOptions());
		this.showChildView('items', view, { replaceElement: true });
	},
	modelEvents: {
		'refresh':'render'
	}
});