import { View, CollectionView } from 'core';
import { _, $ } from 'vendors';
import { innerBus } from '../innerBus';
import { HamburgerView } from 'coms/ui/HamburgerView';
import { ActionView, getActionsCollection } from '../multiActions';

export const __MultiActionsView = View.extend({
	template: _.template(`
		<div class="state"></div>
		<div class="actions">
			<span class="count">Выделено: <span class="value"><%= count %></span></span>
			<button class="action select" data-action="select" title="очистить выбранное"><i></i></button>
			<% if (offers) { %>
			<div class="buttons">
				<label>действия с предложениями</label>
				<% if (sameStatus && status && status != "active") {%><button class="action publish" data-action="offer:publish" title="опубликовать выбранные"><i class="glyphicon glyphicon-eye-open"></i></button><% } %>
				<% if (sameStatus && status && status == "active" ) {%><button class="action unpublish" data-action="offer:unpublish" title="снять с публикации выбранные"><i class="glyphicon glyphicon-eye-close"></i></button><% } %>
				<% if (sameOperation) {%><button class="action price" data-action="offer:price:edit" title="изменить ставку в выбранном"><i class="glyphicon glyphicon-ruble"></i></button><% } %>
				<% if (sameOperation) {%><button class="action commercial" data-action="commercial:edit" title="изменить коммерческие условия"><i class="glyphicon glyphicon-piggy-bank"></i></button><% } %>
				<button class="action refresh" data-action="offer:refresh" title="в выбранном всё без изменений"><i class="glyphicon glyphicon-refresh"></i></button>
			</div>
			<% } %>
			<div class="buttons">
				<label>действия с помещениями</label>
				<button class="action createoffer no-accent" data-action="offer:create" title="добавить предложение по выбранным площадям"><i class="glyphicon glyphicon-plus-sign"></i></button>
				<button class="action massinfo" data-action="object:massinfo" title="поменять информацию и статус"><i class="glyphicon glyphicon-info-sign"></i></button>
				<button class="action newowner" data-action="object:newowner" title="поменять собственника"><i class="glyphicon glyphicon-transfer"></i></button>
			</div>
		</div>
	`),
	initialize() {
		$(document.body).append(this.el);
	},
	refresh(store)  {
		this.store = store;
		let distinct = {
			'offer.status': {},
			'offer.operation': {},
		}
		let offersCount = 0;
		store.items.forEach(view => {
			offersCount += view.model.getValueByPath('offer') ? 1 : 0;
			for(let key in distinct) {
				let data = distinct[key];
				let value = view.model.getValueByPath(key);
				if (value !== undefined) {
					data[value] = true;
				}
			}
		});

		for(let key in distinct) {
			let data = distinct[key];
			let count = Object.keys(data).length;
			let value = count === 1 ? this._firstKey(data) : undefined;
			distinct[key] = { 
				count,
				value
			};
		}

		distinct.offers = offersCount;

		/*
		_.each(store.items, view => {
			_.each(distinct, (data, key) => {
				let value = view.model.getValueByPath(key);
				data[value] = true;
				
			});
		});

		_.each(distinct, (data, key) => {				
			let count = _.size(data);
			let value = count == 1 ? this._firstKey(data) : undefined;
			distinct[key] = { 
				count,
				value
			};
		});
		*/
		this.distinct = distinct;
		console.log('check', this.store, this.distinct)
		this.render();
	},
	onRender() {
		this.$el.addClass('multi-actions-box');
	},
	templateContext() {
		let res = {
			offers: this.distinct.offers > 0,
			count: this.store.length,
			sameStatus: this.distinct['offer.status'].count === 1,
			status: this.distinct['offer.status'].value,
			sameOperation: this.distinct['offer.operation'].count === 1,
			operation: this.distinct['offer.operation'].value,
		}
		return res;
	},
	events: {
		'click button.action'(e) {
			let $b = $(e.target).closest('button');
			let action = $b.data('action');
			if (!action) return;
			this.triggerAction(action, e);
		},
	},
	triggerAction(action, event) {
		if (action != 'select') {
			console.log('triggering', 'multiaction', action)
			innerBus.triggerMethod('multiaction', action, this.store);
		} else {
			innerBus.trigger('unselect:all');
		}
	},
	_firstKey(hash) {
		for(let k in hash) {
			return k;
		}
	}
});


const SelectionInfoText = View.extend({
	tagName: 'span',
	baseClassName: 'count',
	mergeOptionsKeys: ['store'],
	template: 'выделено: <%= count %>',
	templateContext() {
		return {
			count: this.store.length
		}
	},
	initialize() {
		console.log('p[p[', this);
	}
});

const SelectionInfo = HamburgerView.extend({
	baseClassName: 'info',
	mergeOptionsKeys: ['store', 'actionContext'],
	childrenViews() {
		
		const col = getActionsCollection();
		const unselectAll = col.get('unselectall');
		return [
			{ class: SelectionInfoText, store: this.store },
			{ class: ActionView, model: unselectAll, actionContext: this.actionContext }
		]
	},
});


const Header = View.extend({
	tagName: 'label',
	template: '<%= header %>',
	templateContext() {
		return {
			header: this.getOption('header')
		}
	}
});

const ActionsView = CollectionView.extend({
	childView: ActionView,
	initialize() {
		this.collection = getActionsCollection();
		this.predicate = this.getOption('predicate');
	},
	childViewOptions() {
		return {
			actionContext: this.getOption('actionContext'),
		}
	},
	viewFilter(v) {
		if (!this.predicate) { return; }
		if (v.model.get('multiple') === false) {
			return false;
		}
		const ok = v.model.isPredicateOk(this.predicate);
		return ok !== false;
	}
});

const Views = HamburgerView.extend({
	childViewOptionsKeys: ['header', 'predicate', 'actionContext'],
	childrenViews: [
		Header,
		ActionsView
	]
})

export const MultiActionsView = HamburgerView.extend({
	baseClassName: 'multi-actions-box',

	initialize() {
		console.log('store', this);
		$(document.body).append(this.el);
	},

	onRender() {
		console.log('render')
	},

	childViewOptions() {
		return {
			store: this.store,
			distinct: this.stats.distinct,
			actionContext: this.stats.actionContext
			// ownerInRealty: this.getOption('ownerInRealty'),
			// multiactionsView: this,
		}
	},

	childrenViews() {
		if (!this.store) { return; }
		console.log('childrenViews', this.distinct)
		const offersExists = !!this.stats.offersCount;
		return [
			SelectionInfo,
			offersExists ? { class: Views, header: 'действия с предложениями', predicate: { target: 'offer', clientSide: false, distinct: this.stats.distinct } } : undefined,
			{ class: Views, header: 'действия с помещениями', predicate: { target: 'object', clientSide: false, } },
		];
	},
	refresh(store) {
		this.store = store;
		this.stats = this.buildStats(store);
		console.log('refresh')
		this.render();
	},
	buildStats(store) {
		const ownerInRealtyId = this.getOption('ownerInRealty').id;

		let distinct = {
			'offer.status': {},
			'offer.operation': {},
		}

		let offersCount = 0;
		const offers = [];
		const objects = [];
		const models = [];
		store.items.forEach(view => {
			models.push(view.model);
			objects.push(view.model.getValueByPath('object.id'));
			const offerId = view.model.getValueByPath('offer.id'); 
			offersCount += offerId ? 1 : 0;
			if (offerId) {
				offers.push(offerId);
			}
			for(let key in distinct) {
				let data = distinct[key];
				let value = view.model.getValueByPath(key);
				if (value !== undefined) {
					data[value] = true;
				}
			}
		});

		for(let key in distinct) {
			let data = distinct[key];
			let count = Object.keys(data).length;
			let value = count === 1 ? firstKey(data) : undefined;
			distinct[key] = value != null ? value : false;
			// { 
			// 	count,
			// 	value
			// };
		}
		//distinct.offers = offersCount;
		const actionContext = { 
			ownerInRealty: this.getOption('ownerInRealty'),
			ownerInRealtyId, 
			objects, offers, models,
			collection: this.getOption('objectOfferCollection'),
			selector: this.getOption('selector')
		};
		return { distinct, offersCount, actionContext };
	}
});

function firstKey(hash) {
	for(let k in hash) {
		return k;
	}
}
