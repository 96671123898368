import { attachView, isView } from 'utils';
import { stack } from './stack.js';
import { ModalView } from './ModalView.js';
import { TextView } from 'core';
import './modalsApi.less';
// import { textDialogueModalViewOptions } from './utils.js';

// const L = localizeApi.get;





export const modalsApi = {

	'config.message.resolveText': 'Закрыть',

	'config.confirm.resolveText': 'Подтвердить',
	'config.confirm.resolveText': 'Отмена',

	ModalView: ModalView,
	modalClassName: 'default',

	attachTo: () => document.body,

	showView (view, showOptions = {}) {

		if (!isView(view)) {

			throw new Error('modals: unable to show modal view, provided view is not a view instance');

		}

		const el = showOptions.attachTo || this.attachTo();
		if (!el) {
			throw new Error('modals: unable to attach modal view, attachTo element is undefined');
		}

		const result = stack.create(view, showOptions, () => {
			attachView(view, el, true);
		});
		return result;

	},

	// message(...args) {
	// 	const destroyOnOutsideClick = true;

	// 	const modalViewOptions = {
	// 		thisClassName: 'message small',
	// 		destroyOnOutsideClick,
	// 		modalBoxOptions: textDialogueModalViewOptions(args, {
	// 			footerButtons: ['resolve'],
	// 			resolveText: this['config.message.resolveText'],
	// 		})
	// 	};
		

	// 	const view = new this.ModalView(modalViewOptions);

	// 	const modalOptions = {
	// 		destroyOnEsc: true, 
	// 		destroyOnSettle: true,
	// 		destroyOnOutsideClick, 
	// 	}
	// 	return this.showView(view, modalOptions);
	// },
	
	// confirm(...args) {
	// 	const destroyOnOutsideClick = true;

	// 	const modalViewOptions = {
	// 		thisClassName: 'message small confirm',
	// 		destroyOnOutsideClick,
	// 		modalBoxOptions: textDialogueModalViewOptions(args, {
	// 			footerButtons: ['resolve', Separator, 'reject'],
	// 			resolveText: this['config.confirm.resolveText'],
	// 			rejectText: this['config.confirm.rejectText']
	// 		})
	// 	};

	// 	const modalOptions = {
	// 		destroyOnEsc: true, 
	// 		destroyOnSettle: true,
	// 		destroyOnOutsideClick, 
	// 	}

	// 	const view = new ModalView(modalViewOptions);
	// 	return this.showView(view, modalOptions);
	// },

	show(modalContent, modalViewOptions, modalOptions) {
		if (typeof modalViewOptions === 'string') {
			modalViewOptions = { thisClassName: modalViewOptions };
		}

		modalOptions = Object.assign({
			destroyOnSettle: true,
			destroyOnEsc: true, 
			destroyOnOutsideClick: true, 
		}, modalOptions);

		modalViewOptions = Object.assign({ 
			thisClassName: this.modalClassName, 
			destroyOnOutsideClick: modalOptions.destroyOnOutsideClick,
		}, modalViewOptions, { modalContent });
		
		
		// console.log('=== modal ===', modalOptions);
		const view = new this.ModalView(modalViewOptions);
		// view.on('all', c => console.log('[m]',c));
		return this.showView(view, modalOptions);
	},

	message(arg) {
		if (typeof arg === 'string') {
			arg = { message: arg }
		}
		const { message, header, modalClassName = 'message', resolveButton = 'Ok' } = arg;
		const view = {
			baseClassName: 'message-text',
			class: TextView,
			text: message
		}
		const modal = {
			thisClassName: modalClassName,
			resolveButton,
			header
		}
		return this.show(view, modal);
	},

	error(arg) {
		const modalClassName = 'message error-mesage';
		const resolveButton = 'Понятно';
		if (typeof arg === 'string') {
			return this.message({ message: arg, header: 'Ошибка', modalClassName, resolveButton });
		} else if (arg && typeof arg === 'object') {
			const { header, message } = extractErrorTexts(arg);
			return this.message({ header, message, modalClassName, resolveButton })
		}
	},

	confirm(message, header = 'Требуется подтверждение') {
		const view = {
			baseClassName: 'message-text confirm',
			class: TextView,
			text: message
		}
		const modal = {
			thisClassName: 'message',
			header,
			resolveButton: 'Подтверждаю',
			rejectButton: 'Отказываюсь'
		}		
		return this.show(view, modal);
	}

};


function extractErrorTexts(err, metaData = {}) {
	if (err.responseJSON) {
		return extractErrorTexts(err.responseJSON, {
			state: err.state(),
			status: err.status,
			statusText: err.statusText
		});
	}

	let { messsage: message, Message } = err;
	message = message || Message;

	const header = metaData.header || (metaData.statusText ? "Ошибка: " + metaData.statusText : undefined) || 'Ошибка';

	console.log(err);
	return { message, header }
	
}
