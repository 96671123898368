import bus from 'bus/app'; //'bus-app'
import identity from '_libs/identity'; //'_libs/identity'
import app from './app/app'; //'app'

import registerModules from './register-modules';
import registerSvcModules from './register-svcmodules';

function createPromiseContext()
{
	const context = {};
	context.promise = new Promise((res, rej) => {
		context.resolve = res;
		context.reject = rej;
	});
	return context;
}

export function afterStartPromise() {


	app._rootModulesPromise = createPromiseContext();
	app._svcModulesPromise = createPromiseContext();


	registerModules();

	if (identity.get('employeeId')) {
		registerSvcModules();
	} else {
		console.error('no employeeId', identity.attributes);
		app._svcModulesPromise.resolve();
	}

	Promise.all([app._rootModulesPromise.promise, app._svcModulesPromise.promise]).then(() => {
		bus.trigger('allmodules:registered');
		console.log('#allmodules:registered');
	});

}