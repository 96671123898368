// define('svc/actualization/ownerInRealty/model',
// [
// 	'app-config'
// ],
// function(
// 	cfg
// ){});

import cfg from 'app-config';
import { BbeModel } from 'core/bbe';
import { invokeValue } from 'utils';
import { Model, Collection } from 'core';
import { ObjectModel } from './new-layout/objects/ObjectModel';
import { ObjectsCollection } from './new-layout/objects/ObjectsCollection';
import { dateApi } from '../../../../apis';


const infoActions = [
	m => ({ label: 'На страницу Здания &laquo;' + m.getByPath('realty.name') + '&raquo;' }),
	m => ({ label: 'На страницу Собственника &laquo;' + m.getByPath('owner.name') + '&raquo;' }),
	{ label: 'Создать новое помещение' },
	{ label: 'Добавить тэг' },
	{ label: 'Редактировать информацию' },
	m => ({ label: 'свернуть / развернуть', action: () => m.toggleInfo() })
];
const disabledStatuses = {
	deleted: 1,
	archived: 1,
}
let OwnerInRealtyModel = Model.extend({
	initialize() {
		// this.on('request', () => {
		// 	this.once('change:objects', () => {
		// 		const objs = this.get('objects') || [];
		// 		const col = this.getObjects();
		// 		col.set(objs, { remove: true, merge: true, add: true });
		// 	})
		// });
	},
	urlRoot() {
		return cfg.urls.apiV('actualization/ownerInRealty/');
	},

	firstFetch() {
		let url = this.urlRoot() + this.get('ownerId') + '_' + this.get('realtyId');
		return this.fetch({ url });
	},

	getInfoActions() {
		return infoActions.map(action => invokeValue(action, this, this));
	},

	toggleInfo() {
		this._infoCollapsed = !this._infoCollapsed;
		this.trigger('info:toggled');
	},

	getObjects({ models } = {}) {
		if (!this.objectsCollection) {
			const models = this.get('objects') || [];
			this.objectsCollection = new ObjectsCollection(models, { parent: this });
			// this.objectsCollection.parent = this;
		}
		return models ? this.objectsCollection.models : this.objectsCollection;
	},

	syncObjects() {
		const col = this.getObjects();
		const models = this.get('objects');
		col.set(models, { remove: true, merge: true, add: true });
		col.invoke('trigger', 'ownerInRealty:sync');
	},

	massActionAsync(type, post) {
		return this.send({ addUrl: 'mass-action/' + type, method: 'post', attrs: post });
	},

	massObjectsActionAsync(post) {
		return this.massActionAsync('objects', post);
	},

	massOffersActionAsync(post) {
		return this.massActionAsync('offers', post);
	},

	async massPatchAsync(type, ids, attrs, differentValues) {

		// const ids = models.map(m => m.id);
		let ownValues = differentValues ? attrs : undefined;
		attrs = differentValues ? undefined : attrs;
		const post = {
			action: 'patch',
			ids,
			attrs,
			ownValues
		}
		const data = await this.massActionAsync(type, post);
		console.error('--- data ---', data);
		 // await this.send({ addUrl: 'mass-patch/' + type, method: 'post', attrs: context });
		this.applyMassActionResult(data);
	},

	applyMassActionResult(data) {
		const hasOffers = !!data.offers;
		const hasObjects = !!data.objects;
		
		const objs = this.getObjects();

		if (hasObjects) {
			Object.keys(data.objects).forEach(objId => {
				const flatObj = data.objects[objId];
				const obj = objs.get(objId);
				if (obj) {
					obj.setByPath(flatObj);
				}
			});
			objs.trigger('sort', objs, {});
		}

		if (hasOffers) {
			const parents = new Set();
			const offsColls = new Set();
			const allOffers = objs.getAllOffersHash();
			console.log('syncing offers')
			console.log(allOffers);

			Object.keys(data.offers).forEach(offId => {
				const flatOff = data.offers[offId];
				const off = allOffers[offId];
				console.log(' patch: ->', flatOff, off);
				if (off) {
					off.setByPath(flatOff);
					parents.add(off.collection.parent);
				}
			});
			//offsColls.forEach(col => col.trigger('sort', col, {}));
			parents.forEach(model => model.trigger('change', model));
		}

	},

	getLastActualizeDates() {
		const objects = this.get('objects') || [];

		const getlast = obj => {
			let dates = [];
			let offers = obj.offers || [];
			for(let off of offers) {
				let offDate = dateApi.minMax(false, off.modified, off.actualize?.last);
				//console.log(`	[${off.id}]`, offDate)
				dates.push(offDate);
			}
			if (dates.length) {
				return dateApi.minMax(true, ...dates);
			}
			return dateApi.toDate(obj.actualize?.last);
		}
		const objDates = [];
		let objectsToActualize = 0;
		let totalObjects = 0;
		for(let obj of objects) {
			totalObjects++;
			if (obj.status in disabledStatuses) { continue; }
			objectsToActualize++;
			const lastDate = getlast(obj);
			//console.log(`[${obj.webId}]`, lastDate);
			objDates.push(lastDate);
		}
		let { min, max } = dateApi.minMax({min:1, max:1}, ...objDates);

		
		const diff = (max && min) ? dateApi.diff(max, min) : undefined;
		return {
			totalObjects,
			objectsToActualize,
			diff,
			min, 
			max
		}
	},

	getStaleDiff() {
		const daysToStale = this.attributes?.actualize?.daysToStale;
		if (daysToStale == null || daysToStale <= 0) { return; }
		const dates = this.getLastActualizeDates();
		if (dates.diff == null)  { return; }
		const min = dates.min;
		const staleDate = dateApi.toDate(min, { days: daysToStale });
		const now = new Date()
		return dateApi.diff(staleDate, now);
	}

});



export default OwnerInRealtyModel;
