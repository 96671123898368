import { BbCollection, BbModel, View } from 'core';
import { innerBus } from './innerBus';
import { modernOfferProperties, offerProperties } from './offerProperties';
import schemaEdit from 'ui/controls/schemaEdit';
import { errResult, flatObject, getValueByPath, okResult, tryAsync, unFlatObject } from '../../../../utils';
import { objectMainPropertiesForMassAction } from './objectProperties';
import EditValue from 'ui/controls/editValue';
import { OwnersCollection } from 'm/realties/owners';
import { EditPriceView } from './EditPriceView';
import refs from 'references';
// import modals from '_libs/modals';
import { modalsApi } from 'apis/modalsApi2';

const ActionModel = BbModel.extend({
	backendPath: '/new-mass-action',
	preinitialize(data) {
		const methods = data.methods || {};
		if (data.action) {
			const action = data.action;
			if (typeof action === 'function') {
				methods.action = action;
			} else if (typeof action === 'string') {
				methods.action = context => this._triggerOnTarget(action, context);
			}
		} else if (data.backend !== false) {
			methods.action = this._backendAction;
		}
		if (typeof data.getData === 'function') {
			methods.getData = data.getData;
		}
		for(let methodName in methods) {
			this[methodName] = methods[methodName];
		}
	},
	isPredicateOk(predicate) {
		if (!predicate) { return false; }
		const { target, clientSide, distinct } = predicate;
		if (target && this.get('target') !== target) {
			return false;
		}
		if (clientSide && this.get('backend') === false) {
			return false;
		}
		if (distinct && typeof distinct === 'object') {
			for(let key in distinct) {
				if (typeof this[key] === 'function' && !this[key](distinct[key])) {
					return false;
				}
			}
		}
		return true;
	},
	_triggerOnTarget(eventName, context) {
		const targetName = this.get('actionTarget');
		const target = context[targetName];
		target.triggerMethod(eventName, context);
		console.log('>>', eventName, context )
	},
	async _backendAction(context) {
		//innerBus.triggerMethod('new:action:clicked', this, context);
		let data;
		if (typeof this.getData === 'function') {
			const res = await tryAsync(() => this.getData(context));
			if (!res.ok) {
				console.error('getData exception:')
				console.error(res.error)
				return;
			}
			console.warn('returned data ->', res.value);
			data = res.value;
			if (this.get('sendFlat')) {
				data = flatObject(data);
			}
			console.warn('normalized data ->', data);
		}

		const multiple = context.models.length > 0;
		const needConfirm = multiple && this.get('confirmMultiple');
		if (needConfirm) {

			const res = await modalsApi.confirm(this.getLabel(multiple));
			
			//const res = await tryAsync(() => modals.confirm('Подтвердите своё действие', `<br><h4>${this.getLabel(multiple)}</h4>`));
			if (!res.ok) {
				return;
			}
		}

		innerBus.triggerMethod('before:request');

		const url = context.ownerInRealty.url();
		const attrs = this._contextToData(context);
		attrs.data = data;
		const result = await this.send(url, attrs);		
		innerBus.triggerMethod('after:request');
		if (!result.ok) {
			this.showError(result.error);
		} else {
			innerBus.triggerMethod('refresh:objects');
		}
		console.error('>>>', result);
	},
	_contextToData(context, data = {}) {
		const target = this.get('target');
		const objects = target === 'object' ? context.objects : undefined;
		const offers = target === 'offer' ? context.offers : undefined;
		const action = this.id;
		return {
			action,
			objects,
			offers,
			data
		}
	},
	send(baseUrl, attrs) {
		var backend = new BbModel();
		const path = this.get('backendPath') || this.backendPath;
		backend.url = baseUrl + path;
		return tryAsync(() => backend.save(null, { wait: true, method: 'post', attrs }));
	},
	parse(data) {
		delete data.methods;
		delete data.getData;
		delete data.action;
		return data;
	},
	takeAction(context) {
		console.log('action', this.id, context);
		if (this.action) {
			return this.action(context);
		}
	},
	getLabel(many) {
		const labels = this.get('labels') || [];
		return labels[many ? 1 : 0];
	},
	showError(error) {
		modalsApi.error(error);
	},
	buildIcon(ifEmpty, tagName = 'i', cssClass = '') {
		const fontIcon = this.get('iconClassName');
		if (fontIcon) {
			const css = [cssClass, fontIcon].filter(f => !!f).join(' ');
			return `<${tagName} class="${css}"></${tagName}>`;
		} else {
			return ifEmpty || '';
		}
	},
});

export const ActionView = View.extend({
	classNames: [
		'objectoffer-action-button',
		v => v.model.id,
	],
	tagTitle() {
		const index = this.getOption('isMulti') ? 1 : 0;
		const labels = this.model.get('labels') || [];
		return labels[index] || '';
	},
	tagName: 'button',
	template: '<%= icon %>',
	templateContext() {
		const icon = this.buildIcon()
		return {
			icon
		}
	},
	buildIcon() {
		const fontIcon = this.model.get('iconClassName');
		if (fontIcon) {
			return `<i class="${fontIcon}"></i>`;
		} else {
			return '-';
		}
	},
	buildContext(event) {
		const actionContext = Object.assign({ event }, this.getOption('actionContext'));
		return actionContext;
		// let { itemView, multiactionsView, objectOffer, ownerInRealty, actionContext } = this.getOptions(['itemView', 'multiactionsView', 'objectOffer', 'ownerInRealty', 'actionContext']);
		// if (!ownerInRealty && itemView) {
		// 	ownerInRealty = itemView.getOption('ownerInRealty');
		// }
		// if (objectOffer) {
		// 	const objectId = objectOffer.getValueByPath('object.id');
		// }
		// return {
		// 	view: itemView || multiactionsView,
		// 	model: objectOffer,
		// 	ownerInRealty,
		// 	event
		// }
	},
	events:{
		click(event) {
			const context = this.buildContext(event);
			this.model.takeAction(context);
		}
	}
});



const rawMultiActions = {
	// backends
	addOffer: {
		target: 'object',		
		labels: ['добавить предложение', 'добавить предложение к выбранным помещениям'],
		iconClassName: 'glyphicon glyphicon-plus-sign',
		multiple: false,
		sendFlat: true,
		getData (ctx) {

			let square;
			let many = ctx.objects.length > 1;
			square = getObjectProperty(ctx, ctx.objects[0], 'info.squareOffer');
			if (square == null) {
				console.error('square not found');
				return errResult('square not found');
			}
			console.warn('ctx', ctx)

			let properties = modernOfferProperties();
			let options = {
				header: this.getLabel(many),
				applyText: 'Добавить',
				valuesHash: { square },
				normalizePostData: { removeNulls: true },
			}
			return editSchemaPropertiesAsync(properties, options);
		}
	},
	patchObject: {
		target: 'object',
		labels: ['изменить информацию и статус', 'изменить информацию и статус у выбранных помещений'],
		iconClassName: 'glyphicon glyphicon-info-sign',
		sendFlat: true,
		getData(ctx) {
			
			const model = ctx.models.length === 1 ? ctx.models[0] : undefined;

			let many = ctx.objects.length > 1;
			let properties = objectMainPropertiesForMassAction(ctx.models.length);
			let options = {
				header: this.getLabel(many),
				applyText: 'изменить информацию',
				normalizePostData: { removeNulls: true },
				validateModel: json => {
					const value = normalizePostData(json, { removeNulls: true });
					if (Object.keys(value).length === 0) {
						return 'empty'
					}
				}
			}
			return editSchemaPropertiesAsync(properties, options, model?.get('object'));
		}
	},

	changeOwner: {
		target: 'object',
		labels: ['сменить собственника','сменить собственника у выбранных помещений'],
		iconClassName: 'glyphicon glyphicon-transfer',
		async getData() {
			let sourceValues = new OwnersCollection.Rest();
			let setup = {
				header: 'изменение собственника',
				valueType: 'string',
				modelType: 'single',
				controlType: 'selectRest',
				sourceValues,
			};
	
			//console.log('## setup', setup, sourceValues);
	
			const value = await EditValue.modal.single(setup).then((type, values = []) => {
				if (values.length > 0 && !!values[0]?.id) {
					return okResult({ newOwnerId: values[0].id });
				}
				return errResult('not selected');
			}, (type) => {
				
				return errResult(type);
			});
			console.warn('GETDATA', value);
			return value;
		}
	},

	publish: {
		target: 'offer',
		labels: ['опубликовать','опубликовать выбранные предложения'],
		iconClassName: 'glyphicon glyphicon-eye-open',
		methods: {
			'offer.status': value => value && value !== 'active'
		},
		confirmMultiple: true,
	},

	unpublish: {
		target: 'offer',
		labels: ['снять с публикации','снять с публикации выбранные предложения'],
		iconClassName: 'glyphicon glyphicon-eye-close',
		methods: {
			'offer.status': value => value === 'active'
		},
		confirmMultiple: true,
	},

	changePrice: {
		target: 'offer',
		labels: ['изменить цену','изменить цену у выбранных предложений'],
		iconClassName: 'glyphicon glyphicon-ruble',
		methods: {
			'offer.operation': value => !!value
		},
		getData({ models } = {}) {
			let editPrice = new EditPriceView({ 
				models, multipleOffers: true, alternateReducer: true, noHeader: true, className: 'edit-price-control', 
				onDone(priceObject) {
					if (priceObject == null) {
						console.warn('price not set', priceObject)
						return;
					}
					this.triggerMethod('modal:resolve', priceObject);
				}
			});


			return modalsApi.show(editPrice, { header: 'изменение цены' });

			/*
			const promise = new Promise((resolve, reject) => {
				let editPrice = new EditPriceView({ models, multipleOffers: true, alternateReducer: true, noHeader: true, className: 'edit-price-control' });
				editPrice.on({
					done: priceObject => {
						if (priceObject == null) {
							console.warn('price not set', priceObject)
							return;
						}
						resolve(priceObject)
						editPrice.destroy();
					},
					destroy: () => reject('cancel')
				})
				//modals.modal(editPrice);
				modalsApi.show(editPrice, { header: 'изменение цены' });
			});
			return promise
			*/
		}
	},

	changeCommercial: {
		target: 'offer',
		labels: ['изменить коммерческие условия','изменить коммерческие условия у выбранных предложений'],
		iconClassName: 'glyphicon glyphicon-piggy-bank',
		methods: {
			'offer.operation': value => !!value
		},
		sendFlat: true,
		getData(ctx) {
			
			const model = ctx.models.length === 1 ? ctx.models[0] : undefined;
			//const valuesHash = model ? model.get('offer') : undefined;
			let properties = {
				'forCustomer.taxType': {
					caption: 'налогообложение',
					emptyText: 'не установлено',
					display: v => v ? refs.enum('taxTypes',v) : undefined,
					sourceValues: refs.enum('taxTypes'),
					//_value: model && getValueByPath(model, 'offer.forCustomer.taxType') || void 0,
					modelType: 'single',
					valueType: 'string',
					flattenValues: true,
					multiple: false,
					// validate: required,	
				},
				'forAgent.contractType': {
					caption: 'договор',
					emptyText: 'не установлен',
					display: v => v ? refs.enum('ownerToAgentContractTypes',v) : undefined,
					sourceValues: refs.enum('ownerToAgentContractTypes'),
					//_value: model && getValueByPath(model, 'offer.forAgent.contractType') || void 0,
					modelType: 'single',
					valueType: 'string',
					flattenValues: true,
					multiple: false,
					// validate: required,	
				},
				'forAgent.income': {
					caption: 'процент коммиссии',
					modelType: 'single',
					valueType: 'number',
					display: v => v != null ? v + '%' : '',
					// validate: required,
					//_value: model ? getValueByPath(model, 'offer.forAgent.income') : void 0,
				},
				'forCustomer.boma': {
					caption: 'коридорный коэффициент (boma, число от 1 до 2)',
					modelType: 'single',
					valueType: 'number',
					display: v => v != null && v > 1 ? v : 'отсутствует',
					// validate: required,
					//_value: model ? getValueByPath(model, 'offer.forCustomer.boma') : void 0,
				},
				'forCustomer.priceIncludes' : {
					caption: 'В цену включено',
					modelType: 'single',
					valueType: 'enum',
					sourceValues: refs.enum('priceAdditionals'),
					multiple: true,
				},
				'forCustomer.priceExcludes' : {
					caption: 'В цену НЕ включено',
					modelType: 'single',
					valueType: 'enum',
					sourceValues: refs.enum('priceAdditionals'),
					multiple: true,
				},
			}

			let options = {
				header: 'Изменение коммерческих условий',
				applyText: 'Изменить',
				normalizePostData: { removeNulls: true },
				validateModel: json => {
					const value = normalizePostData(json, { removeNulls: true });
					if (Object.keys(value).length === 0) {
						return 'empty'
					}
				}				
				//valuesHash,
				// beforeApply: (value) => {
				// 	console.log('!!! before Apply', value);
				// 	return this.takeAction('commercial:update', 'изменение условий', models, value);
				// },
			}
			return editSchemaPropertiesAsync(properties, options, model?.get('offer'));
			//SchemaEdit.inModal(properties, options);

		}
	},

	refreshDate: {
		target: 'offer',
		labels: ['без изменений', 'в выбранном все без изменений'],
		iconClassName: 'glyphicon glyphicon-refresh',
		confirmMultiple: true,
	},

	// client side only
	select: {
		backend: false,
		labels: ['выделить/отменить выделение для массовых действий'],
		iconClassName: 'icon',
		action: 'action:toggle:select',
		actionTarget: 'itemView'
	},
	unselectall: {
		backend: false,
		labels: ['снять выделение'],
		iconClassName: 'icon',
		action: ctx => ctx.selector.clear()
	}
}

let multiactions;
let actionsCollection;

function buildMultiactions() {
	const actions = [];
	for(let id in rawMultiActions) {
		const action = Object.assign(rawMultiActions[id], { id });
		actions.push(action);
	}
	multiactions = actions;
}


export function getActionsCollection() {

	if (!multiactions) {
		buildMultiactions()
	}

	if (!actionsCollection) {
		actionsCollection = new BbCollection([...multiactions], { model: ActionModel, parse: true });
	}

	// const collection = new BbCollection(multiactions);

	// for (let action of multiactions) {
	// 	if (actionIsOk(action, model)) {
	// 		collection.add(action);
	// 	}
	// }

	return actionsCollection;
}

export function getMultiAction(actionId) {
	const col = getActionsCollection();
	return col.get(actionId);
}


function findEntity(entities, id) {
	for(let entity of entities) {
		if (entity.id === id) {
			return entity;
		}
	}
}

function getObjectProperty(ctx, ids, property) {
	const oir = ctx.ownerInRealty;
	const objects = oir.get('objects');
	const isArray = Array.isArray(ids);
	ids = isArray ? ids : [ids];
	const values = [];
	for(let id of ids) {
		const obj = findEntity(objects, id);
		if (obj == null) { continue; }
		const value = getValueByPath(obj, property);
		values.push(value);
	}
	return isArray ? values : values[0];
}

function isEmptyObject(obj) {
	return typeof value === 'object' && (
		(Array.isArray(obj) && obj.length === 0) 
		|| (!Array.isArray(obj) && Object.keys(obj).length === 0)
	);
}

function normalizePostData(obj, options = {}) {
	if (!obj || typeof obj !== 'object') { return obj; }

	obj = flatObject(obj);
	for(let k in obj) {
		const value = obj[k];

		if (
			value === undefined 
			|| (value === null && options.removeNulls === true ) 
			|| isEmptyObject(value)
		) {
			delete obj[k];
		}

	}
	obj = unFlatObject(obj);
	console.warn('normalized object', obj);
	return obj;
}

function editSchemaPropertiesAsync(properties, options, model) {
	let valuesHash = buildValuesHash(properties, model);
	console.warn('before schema edit')
	const promise = new Promise((res, rej) => {
		options.beforeApply = value => res(normalizePostData(value, options.normalizePostData));
		if (valuesHash && !options.valuesHash) {
			options.valuesHash = valuesHash;
		}
		console.warn('schemaEdit', properties)
		const result = schemaEdit.inModal(properties, options);
		result.modal.once('destroy', () => rej());
	});

	return promise;
}

function buildValuesHash(props, model) {
	const hash = {};
	for(let key in props) {
		const value = getValueByPath(model, key);
		hash[key] = value;
	}
	const builded = unFlatObject(hash);
	console.warn('buildedValuesHash', builded);
	return builded;
}