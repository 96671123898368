import { editModalOptions } from './editModalOptions';
import { propertySchemaApi } from 'apis/schema/propertySchemaApi';
import { modelSchemaApi } from 'apis/schema/modelSchemaApi';
import { controlsApi } from 'apis/controls';
import { modalsApi } from 'apis/modalsApi2';

// import { PropertiesView } from './PropertiesView';

function normalizeOptions(valueSchema, options = {}) {
	if (!valueSchema) {
		throw new Error('необходима схема свойства');
	}
	
	let { model, modelSchema } = options;
	if (!modelSchema) {
		modelSchema = modelSchemaApi.getModelSchema(model);
	}
	valueSchema = propertySchemaApi.getSchema(valueSchema, modelSchema);
	return Object.assign({}, options, { valueSchema, modelSchema });
}

export function editValue(valueSchema, options = {}) {
	
	options = normalizeOptions(valueSchema, options);
	valueSchema = options.valueSchema;
	const { model, modelSchema, value } = options;

	let contentOptions = controlsApi.getEditValueViewOptions({
		model,
		valueSchema,
		value,
		onUserInput(value, done) {
			const modalView = this.getOption('modalView');
			value = this.getValue();
			if (done) {
				modalView.trigger('modal:resolve', value);
				return;
			}
			modalView.setSettleWith('resolve', value);
		}
	});
	console.log(contentOptions)
	const header = propertySchemaApi.getEditHeader(valueSchema, model, modelSchema);
	let modalOptions = editValueModalOptions(value, header);
	
	let context = modalsApi.show(contentOptions, modalOptions);

	return context;
}


function editValueModalOptions(value, header) {
	return editModalOptions({
		header: header,
		thisClassName: 'edit-property',
		resolveWith: value,
		rejectHardButton: 'Сбросить значение',
	});
}