import { _ } from 'vendors';
import { View } from 'core/views';
import './ui2-atom.less';

export const UiAtomView = View.extend({

	constructor: function(options) {
		View.apply(this, arguments);
		if (this.hasOption('clickAction')) {
			this.addClassName('clickable');
			this._updateElClassName();
			
			const clickAction = this.getOption('clickAction', false);
			const callback = typeof clickAction === 'function' 
				? clickAction.bind(this, this)
				: event => this.simpleTriggerAction(clickAction, event); //triggerMethod('click:action', clickAction, event);
			this.delegate('click', callback);
		}
	},

	simpleTriggerAction(clickAction, event) {
		this.triggerMethod('click:action', clickAction, event);
	},

	baseClassName: 'ui2-atom',
	template: _.template(`<% if (showTop) {%><i><%= topText %></i><% } %>
<% if (showBottom) {%><b><%= bottomText %></b><% } %>
<span><%= text %></span>
<% if (showSmall) {%><small><%= smallText %></small><% } %>
`),
	templateContext () {
		const showTop = !!this.getOption('showTop');
		const showBottom = !!this.getOption('showBottom');
		const showSmall = !!this.getOption('showSmall');
		return {
			showTop,
			showBottom,
			showSmall,
			text: this.getTextHtml(this.getOption('ifEmptyText') || ''),
			topText: showTop ? this.getTopTextHtml(this.getOption('ifEmptyTopText') || '') : '',
			bottomText: showBottom ? this.getBottomTextHtml(this.getOption('ifEmptyBottomText') || '') : '',
			smallText: showSmall ? this.getSmallTextHtml(this.getOption('ifEmptySmallText') || '') : ''
		};
	},
	showTop: true,
	showBottom: true,
	showSmall: false,
	getTextValue (key, ifEmpty) {
		let value = this.getOption(key, { force: false });
		if (typeof value === 'function') {
			value = value.call(this, this);
		}
		if (value == null || value === '') { return ifEmpty; }
		return value;
	},
	getHtml (value) { return value; },
	getTopText (ifEmpty) { return this.getTextValue('topText', ifEmpty); },
	getTopTextHtml (ifEmpty) { return this.getHtml(this.getTopText(ifEmpty)); },
	getBottomText (ifEmpty) { return this.getTextValue('bottomText', ifEmpty); },
	getBottomTextHtml (ifEmpty) { return this.getHtml(this.getBottomText(ifEmpty)); },
	getText (ifEmpty) { return this.getTextValue('text', ifEmpty); },
	getTextHtml (ifEmpty) { return this.getHtml(this.getText(ifEmpty)); },
	getSmallText (ifEmpty) { return this.getTextValue('smallText', ifEmpty); },
	getSmallTextHtml (ifEmpty) { return this.getHtml(this.getSmallText(ifEmpty)); },

	_updateText (selector, html) {
		this.$(selector).html(html);
	},
	updateTopText (html) {
		if (!arguments.length) {
			html = this.getTopTextHtml();
		}
		this._updateText('> i', html);
	},
	updateBottomText (html) {
		if (!arguments.length) {
			html = this.getBottomTextHtml();
		}
		this._updateText('> b', html);
	},
	updateText (html) {
		if (!arguments.length) {
			html = this.getTextHtml();
		}
		this._updateText('> span', html);
	}
});
