import refs from 'references';
import { required } from './common';
export function objectMainPropertiesForMassAction(modelsCount) {
	let properties = {
		'status': {
			caption: 'статус помещения',
			valueType: 'enum',
			sourceValues: refs.enum('realtyObjectStatuses'),
			required: modelsCount === 1,
			//validate: required,
		},
		'info.floor': {
			caption: 'этаж',
			emptyText: 'не установлен',
			modelType: 'single',
			valueType: 'number',
			display: v => v != null ? v + ' эт.' : '',
			required: modelsCount === 1,
			// validate: required,	
		},
		'info.roomNumber': {
			caption: 'номер кабинета(идентификатор площади)',
			emptyText: 'не указан',
			modelType: 'single',
			valueType: 'string',
		},		
		'info.purposes': {
			caption: 'возможные назначения',
			emptyText: 'не выбраны',
			// display: v => {
			// 	console.log('v::', v);
			// 	let gg = (v || []).map(mv => refs.enum('realtyPurposes', mv));
			// 	return gg.join(', ');
			// },
			sourceValues: refs.enum('realtyPurposes'),
			modelType: 'single',
			valueType: 'enum',
			//flattenValues: true,
			multiple: true,
			required: modelsCount === 1,
			// validate: required,	
		},		
		'info.currentPurpose': {
			caption: 'текущее назначение',
			emptyText: 'не выбрано',
			//display: v => v ? refs.enum('realtyPurposes',v) : undefined,
			sourceValues: refs.enum('realtyPurposes'),
			modelType: 'single',
			valueType: 'enum',
			//flattenValues: true,
			multiple: false,
		},
		'info.state': {
			caption: 'состояние',
			emptyText: 'не указано',
			modelType: 'single',
			valueType: 'enum',
			sourceValues: refs.enum('realtyObjectStates'),
			required: modelsCount === 1,
		},
		'info.spaceLayout': {
			caption: 'планировка',
			emptyText: 'не указана',
			modelType: 'single',
			valueType: 'enum',
			sourceValues: refs.enum('spaceLayouts'),
			required: modelsCount === 1,
		},
		'info.roomsCount': {
			caption: 'количество кабинетов',
			emptyText: 'не указано',
			modelType: 'single',
			valueType: 'number',
		},
	}
	if (modelsCount > 1) {
		delete properties['info.roomNumber'];
	}
	return properties;
}