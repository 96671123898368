import { Model } from 'core';
import { propertySchemaApi } from 'apis/schema';
import { getFullPriceMeter, getFullPriceSquare } from 'utils/price';
import { actionsMixin, actualizeMixin, isNotActualized, plateActionsMixin } from './common';
import { takeFirst } from 'utils';
import { editMainProperties, editCommercialProperties, offerPriceEdit } from './offer-actions';
import { unFlatObject } from 'utils';
import { getFullPriceInfo } from "utils/price";

function isEmpty(arg) {
	return arg == null || arg === '';
}

function getRawRealtyObject(off) {
	const flat = off?.flat;

	if (off.attributes) {
		off = off.attributes;
	}

	if (flat) {
		off = unFlatObject(off);
	}


	return off.object || off.obj;

}

export const offerActions = [
	{ id:'nochanges', label: 'Без изменений', 
		places: ['actualize', 'plate'], multyAction: true, 
		getAttrs: () => ({ 'actualize.last': new Date() }) 
	},
	{ id:'publicate', label: 'Опубликовать', 
		places: ['actualize', 'plate'], multyAction: true, filter: (m) => m.get('status') !== 'active', 
		getAttrs: () => ({ 'status': 'active' })
	},
	{ 
		id:'unpublicate', label: 'Снять с публикации', 
		places: ['actualize', 'plate'],
		multyAction: true, filter: (m) => m.get('status') === 'active', 
		getAttrs: () => ({ 'status': 'off' })
	},
	{ id:'editprice', label: 'Изменить цену', places: ['plate'],  multyAction: true, action: offerPriceEdit },
	{ 
		id:'editcommercial', label: 'Изменить коммерческие условия', multyAction: true, places: ['plate'],
		getAttrs: (defValue = {}) => {
			return editCommercialProperties(defValue);
		}
	},
	{ 
		id:'editmain', label: 'Изменить основные характеристики', multyAction: true, places: ['plate'],
		getAttrs: (defValue = {}) => {
			return editMainProperties(defValue);
		}
	},
	// { id:'edit', label: 'Редактировать все характеристики', places: ['plate'] },	
]

export const OfferModel = Model.extend({
	// ...plateActionsMixin,
	...actionsMixin,
	...actualizeMixin,
	plateActionsHeader: m => 'Предложение ' + m.id,
	actions() {
		let actions = [...offerActions];
		return actions;
	},
	getRealtyObject() {
		if (this.collection.parent) {
			return this.collection.parent;
		}
		return getRawRealtyObject(this);
	},
	isNotActualized(passedDaysToStale) {
		let last = this.getByPath('actualize.last');
		let daysToStale = this.getByPath('actualize.daysToStale');
		return isNotActualized(last, daysToStale, passedDaysToStale);
	}
});

OfferModel.defineSchema({
	market: {
		label: 'рынок',
		valueType: 'enum',
		valueSubType: 'realtyMarkets',
		dependsOn: ['operation'],
		valuesFilter(valueModel, model) {
			const operation = propertySchemaApi.getValue('operation', model);
			const hash = propertySchemaApi.getValue('allowedMarketOperations', model);
			const allowed = hash[operation];
			if (Array.isArray(allowed)) {
				return allowed.indexOf(valueModel.id) > -1;
			}
			return false;
		},
		required: true,
		inlineEdit: true,
		controlOptions: {
			thisClassName: 'inline-buttons',
		}
	},
	operation: {
		label: 'операция',
		valueType: 'enum',
		valueSubType: 'realtyOperations',
		required: true,
		inlineEdit: true,
		controlOptions: {
			thisClassName: 'inline-buttons',
		}
	},
	status: {
		label: 'Статус',
		valueType: 'enum',
		valueSubType: 'realtyOfferStatuses',
		inlineEdit: true,
		required: true,
		controlOptions: {
			thisClassName: 'inline-buttons'
		}
	},
	visibleFor: {
		label: 'Видимость',
		valueType: 'enum',
		valueSubType: 'entityVisibilities',
		multiple: true,
		inlineEdit: true,
		controlOptions: {
			thisClassName: 'inline-buttons'
		}
	},
	'forAgent.income': {
		label: 'Комиссия',
		wrapDisplayValue: true,
		postfix: '%',
		valueType: 'number',
		
	}, 
	'forAgent.contractType': {
		label: 'Договор',
		wrapDisplayValue: true,
		valueType: 'enum',
		valueSubType: 'ownerToAgentContractTypes',
		required: true,
		// inlineEdit: true,
	},
	'forCustomer.taxType': {
		label: 'Налогообложение',
		wrapDisplayValue: true,
		valueType: 'enum',
		valueSubType: 'taxTypes',
		required: true,
		inlineEdit: true,
		controlOptions: {
			thisClassName: 'inline-buttons'
		}
	},
	'forCustomer.boma': {
		label: 'Коридорный коэф.',
		wrapDisplayValue: true,
		valueType: 'number',
		dependsOn: ['operation'],
		isExistForEdit(model, schema) {
			const op = propertySchemaApi.getValue('operation', model, schema);
			return op === 'rent';
		},
		getValue (model, schema, getter) {
			const op = propertySchemaApi.getValue('operation', model, schema);
			const v = getter();
			if (op !== 'rent' || v === 1) { return; }
			return v;
		}
	},
	'forCustomer.priceMeter': {
		label: 'Стоимость',
		inlineEdit: false,
		valueType: 'number',
		required: true,
		controlName: 'priceMeter',
		controlOptions(model) {
			const square = model.smartGet('object.info.squareOffer');
			const priceMeter = model.smartGet('forCustomer.priceMeter');
			const boma = model.smartGet('forCustomer.boma');
			const operation = model.smartGet('operation');
			const taxType = model.smartGet('forCustomer.taxType');
			
			const opts = {
				thisClassName: 'offer-price-edit',
				noHeader: true,
				multipleOffers: false,
				models: [
					{
						object: {
							info: {
								squareOffer: square,
							}
						},
						offer: {
							operation,
							forCustomer: {
								boma,
								priceMeter,
								taxType
							}
						}
					}
				]
			}
			console.warn('#', opts);
			return opts;
		},
		dependsOn: ['operation', 'forCustomer.taxType'],
		isEditNotAllowed(model) {
			if (isEmpty(model.smartGet('operation'))
					|| isEmpty(model.smartGet('forCustomer.taxType'))
				) {
				return 'Перед изменением цены необходимо установить <em>тип операции</em> и <em>налогообложение</em>';
			}
		},
		displayValue(value, model, modelSchema, options) {
			if (value == null) {
				return '&mdash;'
			}
			const realtyObject = propertySchemaApi.getValue('realtyObject', model);
			const info = getFullPriceInfo(model, { realtyObject, objectSquarePath: 'object.info.squareOffer' });
			const meter = `${info.meter.priceString} р. ${info.meter.postfix}`;
			const square = `${info.square.priceString} р. ${info.square.postfix}`;
			console.log(info.square);
			let words = '';
			if (info.square.priceIncludesWords?.length) {
				words = '<br><em>включая ' + info.square.priceIncludesWords.join(', ') + '</em>';
			}
			return `<span>${meter}</span><br><span>${square}</span>` + words;
			// console.log('info:', info);
			// const txt = propertySchemaApi.displayValue('fullPriceMeter', model, modelSchema, options);
			// return txt;
		}
	},
	'isRent': {
		valueType: 'boolean',
		getValue(model, schema) {
			const operation = propertySchemaApi.getValue('operation', model, schema);
			return operation === 'rent';
		}
	},
	'isNds': {
		valueType: 'boolean',
		getValue(model, schema) {
			const tax = propertySchemaApi.getValue('forCustomer.taxType', model, schema);
			if (!tax) { return false; }
			return tax.toString().toLowerCase().indexOf('nds') > -1;
		}
	},
	'boma': {
		valueType: 'number',
		getValue(model, schema) {
			const isRent = propertySchemaApi.getValue('isRent', model, schema);
			if (!isRent) { return 1; }
			const boma = propertySchemaApi.getValue('forCustomer.boma', model, schema);
			return boma;
		}
	},
	'fullPriceMeter': {
		valueType: 'number',
		getValue(model, schema) {
			
			const priceMeter = propertySchemaApi.getValue('forCustomer.priceMeter', model, schema);
			const isNds = propertySchemaApi.getValue('isNds', model, schema);
			console.log('getting fullPriceMeter', priceMeter, isNds);
			return getFullPriceMeter({ isNds, priceMeter });
		}
	},
	'realtyObject': {
		valueType: 'realtyObject',
		getValue(model, schema) {
			//debugger;
			if (!model) { return; }
			if (model.getRealtyObject) {
				return model.getRealtyObject();
			}
			return getRawRealtyObject(model);
		}
	},
	'fullPriceSquare': {
		valueType: 'number',
		getValue(model, schema) {
			const priceMeter = propertySchemaApi.getValue('forCustomer.priceMeter', model, schema);
			const isNds = propertySchemaApi.getValue('isNds', model, schema);
			const isRent = propertySchemaApi.getValue('isRent', model, schema);
			const boma = propertySchemaApi.getValue('boma', model, schema);
			const objectModel = propertySchemaApi.getValue('realtyObject', model, schema);			
			const square = propertySchemaApi.getValue('info.squareOffer', objectModel, 'realtyObject');
			const options = { priceMeter, isNds, isRent, boma, square };
			// console.log('square price', options);
			const value = getFullPriceSquare({ priceMeter, isNds, isRent, boma, square });
			return value;
		}
	}
}, 'realtyOffer');
